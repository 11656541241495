var dataDiscs = {
  number: [
    { value: "3537" },
    { value: "3573" },
    { value: "3575" },
    { value: "3577" },
    { value: "3735" },
    { value: "3753" },
    { value: "3755" },
    { value: "3757" },
    { value: "5337" },
    { value: "5357" },
    { value: "5373" },
    { value: "5375" },
    { value: "5377" },
    { value: "5537" },
    { value: "5733" },
    { value: "5737" },
    { value: "5773" },
    { value: "7335" },
    { value: "7353" },
    { value: "7355" },
    { value: "7357" },
    { value: "7375" },
    { value: "7533" },
    { value: "7535" },
    { value: "7537" },
    { value: "7553" },
    { value: "7573" },
    { value: "7735" },
    { value: "3357" },
    { value: "3375" },
    { value: "3557" },
    { value: "3775" },
    { value: "5573" },
    { value: "5735" },
    { value: "5753" },
    { value: "7753" },
    { value: "3335" },
    { value: "5553" }
  ],
  startingAffirmations: [
    [[1, 1, 2], [1, 4, 0], [1, 2, 0], [2, 1, 3], [2, 3, 3], 6.05],
    [[2, 4, 1], [1, 4, 0], [1, 2, 4], [1, 4, 2], [2, 3, 3], 6.76],
    [[1, 4, 4], [1, 0, 0], [1, 0, 2], [1, 2, 0], [2, 3, 1], 6.88],
    [[1, 2, 1], [1, 3, 2], [1, 0, 4], [2, 3, 3], [2, 1, 3], 7.65],
    [[2, 4, 0], [1, 4, 4], [1, 0, 4], [1, 2, 0], [2, 1, 3], 7.85],
    [[2, 2, 3], [2, 1, 2], [2, 2, 0], [1, 3, 2], [2, 3, 1], 7.96],
    [[1, 4, 1], [2, 2, 3], [2, 4, 0], [1, 0, 0], [2, 1, 1], 8.22],
    [[2, 1, 2], [1, 4, 2], [1, 2, 4], [2, 3, 1], [1, 2, 2], 8.33],
    [[2, 2, 2], [1, 2, 3], [1, 4, 4], [2, 3, 3], [2, 1, 1], 8.62],
    [[2, 0, 2], [1, 2, 1], [1, 0, 4], [1, 0, 0], [2, 1, 1], 8.74],
    [[2, 2, 1], [2, 4, 4], [1, 2, 3], [1, 0, 2], [2, 3, 3], 8.78],
    [[2, 2, 4], [1, 1, 2], [1, 4, 0], [1, 2, 0], [2, 1, 3], 8.94],
    [[1, 0, 0], [1, 0, 4], [1, 4, 4], [2, 3, 3], [1, 2, 2], 8.98],
    [[1, 1, 2], [1, 4, 0], [1, 2, 4], [2, 1, 1], [2, 3, 1], 9.14],
    [[1, 4, 4], [1, 0, 0], [1, 0, 2], [2, 3, 1], [2, 3, 3], 9.26],
    [[2, 4, 0], [2, 4, 4], [1, 0, 4], [2, 1, 1], [1, 2, 2], 9.28],
    [[2, 2, 0], [1, 2, 3], [1, 2, 4], [1, 0, 2], [2, 1, 3], 9.34],
    [[1, 1, 4], [2, 2, 3], [2, 2, 1], [2, 0, 4], [1, 4, 2], 9.44],
    [[2, 0, 4], [2, 0, 0], [1, 1, 2], [1, 2, 0], [1, 2, 2], 9.8],
    [[2, 0, 0], [2, 0, 4], [1, 2, 3], [1, 4, 4], [1, 4, 2], 10],
    [[2, 0, 4], [1, 1, 2], [1, 4, 2], [1, 2, 4], [2, 3, 3], 10.03],
    [[2, 0, 4], [1, 2, 3], [1, 4, 2], [1, 2, 0], [2, 3, 3], 10.21],
    [[1, 3, 4], [2, 1, 0], [2, 4, 0], [2, 4, 2], [1, 0, 4], 10.59],
    [[2, 0, 3], [1, 1, 2], [1, 2, 4], [2, 1, 3], [1, 2, 2], 10.64],
    [[2, 4, 2], [1, 4, 0], [1, 0, 2], [1, 2, 4], [2, 1, 1], 10.67],
    [[1, 2, 1], [1, 3, 2], [1, 0, 0], [1, 0, 2], [2, 1, 1], 10.79],
    [[2, 2, 4], [1, 2, 3], [1, 2, 0], [2, 3, 1], [2, 1, 1], 10.96],
    [[2, 2, 0], [1, 4, 0], [1, 2, 4], [2, 3, 1], [2, 1, 3], 11.16],
    [[2, 2, 1], [2, 1, 2], [2, 0, 0], [1, 2, 3], [1, 4, 2], 6.77],
    [[2, 0, 0], [1, 2, 3], [1, 4, 4], [1, 0, 2], [2, 3, 3], 8.62],
    [[2, 3, 2], [2, 0, 0], [2, 0, 4], [2, 2, 4], [1, 2, 1], 9.09],
    [[2, 4, 1], [2, 1, 0], [2, 0, 2], [1, 4, 2], [1, 2, 4], 9.19],
    [[2, 0, 3], [1, 1, 2], [1, 0, 0], [1, 4, 2], [2, 1, 1], 9.38],
    [[2, 2, 3], [1, 2, 1], [1, 1, 2], [1, 0, 0], [2, 1, 3], 9.46],
    [[1, 0, 4], [1, 4, 0], [1, 0, 0], [1, 4, 4], [2, 3, 1], 9.69],
    [[2, 4, 0], [1, 4, 4], [1, 0, 4], [1, 2, 4], [2, 1, 3], 11.13],
    [[1, 4, 0], [1, 0, 2], [2, 1, 1], [2, 3, 3], [1, 2, 2], 11.24],
    [[2, 0, 4], [1, 2, 0], [2, 3, 1], [2, 1, 1], [1, 2, 2], 11.57]
  ],
  startingAffirmationsExpert: [
    [
      [[261, 19, 0, 1], [101, 11, 1, 2], [83, 10, 0, 3], [10, 1, 2], 6.46],
      [[159, 15, 0, 4], [10, 1, 2], [265, 19, 0, 5], [32, 3, 2], 6.75],
      [[32, 3, 2], [85, 10, 0, 5], [101, 11, 1, 2], [64, 8, 4], 6.95],
      [[357, 22, "B2"], [41, 4, 5], [32, 3, 2], [101, 11, 1, 2], 7.0],
      [[354, 22, "A4"], [14, 1, 3], [27, 2, 3], [101, 11, 1, 2], 7.2],
      [[86, 10, 1, 2], [32, 3, 2], [101, 11, 1, 2], [40, 4, 4], 7.3],
      [[101, 11, 1, 2], [246, 18, 3, 0], [65, 8, 5], [308, 20, 3, 2], 7.36],
      [[52, 6, 4], [101, 11, 1, 2], [87, 10, 1, 3], [262, 19, 0, 2], 7.56],
      [[61, 8, 1], [76, 9, 4, 2], [101, 11, 1, 2], [332, 21, 2, 1], 7.6],
      [[7, 1, 1], [65, 8, 5], [213, 17, 2, 3], [152, 14, 5, 2], 8.54],
      [[311, 20, 4, 0], [49, 6, 1], [145, 14, 0, 5], [78, 9, 4, 3], 9.58],
      [[25, 2, 1], [166, 15, 4, 2], [354, 22, "A4"], [40, 4, 4], 9.94]
    ],
    [
      [[351, 22, "A1"], [181, 16, 2, 0], [45, 5, 3], [347, 21, 5, 1], 6.11],
      [[129, 13, 0, 4], [337, 21, 3, 1], [27, 2, 3], [22, 1, 5], 7.0],
      [[22, 1, 5], [352, 22, "A2"], [164, 15, 5, 1], [64, 8, 4], 7.18],
      [[343, 21, 4, 2], [7, 1, 1], [68, 9, 3, 0], [272, 19, 2, 1], 7.33],
      [[42, 5, 0], [176, 16, 1, 0], [12, 1, 3], [355, 22, "A5"], 7.41],
      [[341, 21, 4, 0], [195, 16, 4, 5], [64, 8, 4], [12, 1, 3], 7.61],
      [[219, 17, 3, 4], [351, 22, "A1"], [84, 10, 0, 4], [37, 4, 1], 8.21],
      [[64, 8, 4], [37, 4, 1], [159, 15, 0, 4], [8, 1, 2], 8.32],
      [[153, 14, 3, 4], [352, 22, "A2"], [29, 2, 5], [343, 21, 4, 2], 8.53],
      [[342, 21, 4, 1], [122, 12, 5, 2], [29, 2, 5], [78, 9, 3, 4], 8.8],
      [[89, 10, 5, 1], [287, 19, 5, 1], [151, 14, 4, 2], [355, 22, "A5"], 8.82],
      [[351, 22, "A1"], [51, 6, 3], [95, 10, 5, 4], [24, 2, 0], 9.46]
    ],
    [
      [[96, 11, 0, 1], [94, 10, 3, 5], [1, 1, 0], [333, 21, 2, 3], 5.99],
      [[334, 21, 2, 4], [94, 10, 3, 5], [247, 18, 3, 1], [96, 11, 0, 1], 6.5],
      [[96, 11, 0, 1], [30, 3, 0], [42, 5, 0], [1, 1, 0], 6.59],
      [[1, 1, 0], [65, 8, 5], [27, 2, 3], [96, 11, 0, 1], 6.76],
      [[96, 11, 0, 1], [289, 19, 5, 3], [42, 5, 0], [365, 22, "C5"], 6.93],
      [[26, 2, 2], [81, 10, 0, 1], [359, 22, "B4"], [335, 21, 2, 5], 8.32],
      [[94, 10, 3, 5], [109, 11, 3, 5], [61, 8, 1], [310, 20, 3, 5], 8.45],
      [[26, 2, 2], [137, 13, 2, 5], [335, 21, 2, 5], [171, 16, 0, 1], 9.09],
      [[187, 16, 3, 1], [42, 5, 0], [94, 10, 3, 5], [1, 1, 0], 9.36],
      [[86, 10, 2, 1], [136, 13, 2, 4], [62, 8, 2], [185, 16, 2, 5], 9.94],
      [[334, 21, 2, 4], [114, 12, 0, 4], [28, 2, 4], [1, 1, 0], 10.6],
      [[61, 8, 1], [153, 14, 3, 4], [27, 2, 3], [228, 17, 5, 2], 11.4]
    ],
    [
      [[81, 10, 0, 1], [46, 5, 4], [351, 22, "A1"], [98, 11, 3, 0], 5.42],
      [[98, 11, 3, 0], [298, 20, 1, 3], [60, 8, 0], [279, 19, 3, 4], 6.29],
      [[98, 11, 3, 0], [162, 15, 3, 1], [38, 4, 2], [360, 22, "B5"], 6.74],
      [[98, 11, 3, 0], [34, 3, 4], [53, 6, 5], [199, 16, 5, 3], 6.78],
      [[98, 11, 3, 0], [60, 8, 0], [199, 16, 5, 3], [303, 20, 2, 3], 7.03],
      [[282, 19, 4, 1], [98, 11, 3, 0], [46, 5, 4], [148, 14, 1, 4], 7.08],
      [[23, 1, 5], [322, 21, 0, 2], [46, 5, 4], [113, 12, 3, 0], 7.13],
      [[366, 22, "D1"], [76, 9, 2, 4], [130, 13, 5, 0], [12, 1, 3], 7.58],
      [[178, 16, 1, 3], [145, 14, 5, 0], [366, 22, "D1"], [98, 11, 3, 0], 8.15],
      [[262, 19, 0, 2], [29, 2, 5], [38, 4, 2], [366, 22, "D1"], 8.67],
      [[48, 6, 0], [131, 13, 2, 1], [25, 2, 1], [238, 18, 1, 3], 9.41],
      [[167, 15, 5, 2], [325, 21, 0, 5], [27, 2, 3], [185, 16, 2, 5], 10.58]
    ],
    [
      [[369, 22, "D4"], [77, 9, 5, 2], [63, 8, 3], [106, 11, 4, 2], 6.46],
      [[106, 11, 4, 2], [369, 22, "D4"], [62, 8, 2], [25, 2, 1], 6.6],
      [[139, 13, 5, 3], [106, 11, 4, 2], [12, 1, 3], [34, 3, 4], 7.65],
      [
        [228, 17, 5, 2],
        [106, 11, 4, 2],
        [362, 22, "C2"],
        [122, 12, 5, 2],
        7.92
      ],
      [[304, 20, 2, 4], [34, 3, 4], [66, 9, 0, 1], [106, 11, 4, 2], 7.98],
      [[146, 14, 1, 2], [304, 20, 2, 4], [21, 1, 5], [63, 8, 3], 8.25],
      [[24, 2, 0], [362, 22, "C2"], [37, 4, 1], [106, 11, 4, 2], 8.35],
      [[181, 16, 2, 0], [61, 8, 1], [369, 22, "D4"], [298, 20, 1, 3], 8.87],
      [[184, 16, 2, 4], [63, 8, 3], [146, 14, 1, 2], [17, 1, 4], 10.03],
      [[34, 3, 4], [87, 10, 1, 3], [63, 8, 3], [37, 4, 1], 10.17],
      [[24, 2, 0], [140, 13, 5, 4], [62, 8, 2], [324, 21, 0, 4], 10.31],
      [[39, 4, 3], [69, 9, 0, 4], [63, 8, 3], [351, 22, "A1"], 10.36]
    ],
    [
      [[317, 20, 5, 1], [99, 11, 4, 0], [10, 1, 2], [51, 6, 3], 5.39],
      [[93, 10, 3, 4], [40, 4, 4], [373, 22, "E3"], [99, 11, 4, 0], 6.74],
      [[60, 8, 0], [32, 3, 2], [18, 1, 4], [99, 11, 4, 0], 7.13],
      [[240, 18, 1, 5], [317, 20, 5, 1], [99, 11, 4, 0], [166, 15, 4, 2], 7.88],
      [[99, 11, 4, 0], [75, 9, 3, 2], [164, 15, 1, 5], [32, 3, 2], 7.93],
      [[326, 21, 1, 0], [99, 11, 4, 0], [79, 9, 5, 3], [65, 8, 5], 7.93],
      [[56, 7, 2], [99, 11, 4, 0], [215, 17, 2, 5], [32, 3, 2], 8.01],
      [[77, 9, 5, 2], [355, 22, "A5"], [219, 17, 3, 4], [99, 11, 4, 0], 8.13],
      [[44, 5, 2], [318, 20, 5, 2], [20, 1, 5], [89, 10, 1, 5], 8.14],
      [[29, 2, 5], [278, 19, 3, 2], [373, 22, "E3"], [64, 8, 4], 8.45],
      [[339, 21, 3, 4], [88, 10, 1, 4], [27, 2, 3], [159, 15, 4, 0], 8.81],
      [[331, 21, 2, 0], [56, 7, 2], [60, 8, 0], [18, 1, 4], 9.95]
    ],
    [
      [[375, 22, "E5"], [62, 8, 2], [54, 7, 0], [22, 1, 5], 6.02],
      [[42, 5, 0], [79, 9, 5, 3], [63, 8, 3], [375, 22, "E5"], 6.42],
      [[143, 14, 3, 0], [30, 3, 0], [8, 1, 2], [62, 8, 2], 7.27],
      [[51, 6, 3], [246, 18, 3, 0], [86, 10, 2, 1], [149, 14, 1, 5], 7.32],
      [[29, 2, 5], [63, 8, 3], [375, 22, "E5"], [17, 1, 4], 7.33],
      [[132, 13, 1, 3], [76, 9, 2, 4], [1, 1, 0], [355, 22, "A5"], 7.42],
      [[314, 20, 4, 3], [63, 8, 3], [354, 22, "A4"], [154, 14, 5, 3], 7.96],
      [[191, 16, 4, 0], [86, 10, 2, 1], [17, 1, 4], [62, 8, 2], 8.18],
      [[165, 15, 2, 3], [230, 17, 5, 4], [95, 10, 5, 4], [17, 1, 4], 8.24],
      [[62, 8, 2], [168, 15, 4, 3], [328, 21, 1, 3], [5, 1, 1], 8.34],
      [[355, 22, "A5"], [63, 8, 3], [87, 10, 1, 3], [38, 4, 2], 8.39],
      [[67, 9, 2, 0], [54, 7, 0], [30, 3, 0], [245, 18, 2, 5], 8.64]
    ],
    [
      [[22, 1, 5], [351, 22, "A1"], [313, 20, 4, 2], [30, 3, 0], 4.0],
      [[104, 11, 5, 1], [351, 22, "A1"], [332, 21, 2, 1], [86, 10, 2, 1], 6.3],
      [
        [104, 11, 5, 1],
        [217, 17, 3, 1],
        [120, 12, 2, 3],
        [351, 22, "A1"],
        6.32
      ],
      [[104, 11, 5, 1], [87, 10, 3, 1], [214, 17, 2, 4], [361, 22, "C1"], 6.41],
      [[275, 19, 2, 5], [86, 10, 2, 1], [131, 13, 2, 1], [104, 11, 5, 1], 6.51],
      [[42, 5, 0], [95, 10, 5, 4], [22, 1, 5], [256, 18, 5, 0], 6.52],
      [[104, 11, 5, 1], [77, 9, 2, 5], [57, 7, 3], [120, 12, 2, 3], 6.77],
      [[104, 11, 5, 1], [355, 22, "A5"], [137, 13, 2, 5], [61, 8, 1], 6.82],
      [[115, 12, 5, 0], [61, 8, 1], [18, 1, 4], [104, 11, 5, 1], 7.1],
      [[75, 9, 2, 3], [295, 20, 0, 5], [4, 1, 1], [351, 22, "A1"], 8.61],
      [[298, 20, 1, 3], [355, 22, "A5"], [95, 10, 5, 4], [64, 8, 4], 8.66],
      [[64, 8, 4], [28, 2, 4], [175, 16, 0, 5], [95, 10, 5, 4], 9.41]
    ],
    [
      [[20, 1, 5], [62, 8, 2], [316, 20, 5, 0], [360, 22, "B5"], 7.1],
      [[142, 14, 0, 2], [259, 18, 5, 3], [308, 20, 3, 2], [80, 9, 5, 4], 7.94],
      [[104, 11, 5, 1], [15, 1, 3], [61, 8, 1], [115, 12, 5, 0], 8.01],
      [[46, 5, 4], [192, 16, 4, 1], [16, 1, 4], [76, 9, 4, 2], 8.42],
      [[338, 21, 3, 2], [86, 10, 1, 2], [16, 1, 4], [121, 12, 4, 2], 8.57],
      [[63, 8, 3], [186, 16, 3, 0], [16, 1, 4], [98, 11, 0, 3], 8.78],
      [[104, 11, 5, 1], [62, 8, 2], [89, 10, 5, 1], [143, 14, 0, 3], 8.88],
      [[122, 12, 5, 2], [336, 21, 3, 0], [63, 8, 3], [46, 5, 4], 8.9],
      [[122, 12, 5, 2], [313, 20, 4, 2], [7, 1, 1], [63, 8, 3], 8.95],
      [[83, 10, 0, 3], [372, 22, "E2"], [51, 6, 3], [29, 2, 5], 9.14],
      [[20, 1, 5], [26, 2, 2], [46, 5, 4], [92, 10, 5, 2], 9.3],
      [[166, 15, 4, 2], [29, 2, 5], [41, 4, 5], [338, 21, 3, 2], 10.25]
    ],
    [
      [[95, 10, 5, 4], [323, 21, 0, 3], [373, 22, "E3"], [231, 18, 0, 1], 4.9],
      [[30, 3, 0], [19, 1, 4], [345, 21, 4, 5], [108, 11, 4, 3], 6.12],
      [[137, 13, 5, 2], [352, 22, "A2"], [108, 11, 4, 3], [50, 6, 2], 6.2],
      [[63, 8, 3], [249, 18, 3, 4], [108, 11, 4, 3], [42, 5, 0], 6.46],
      [[304, 20, 2, 4], [143, 14, 3, 0], [108, 11, 4, 3], [5, 1, 1], 6.73],
      [[23, 1, 5], [372, 22, "E2"], [62, 8, 2], [28, 2, 4], 7.86],
      [[68, 9, 3, 0], [108, 11, 4, 3], [144, 14, 4, 0], [53, 6, 5], 7.94],
      [[95, 10, 5, 4], [62, 8, 2], [30, 3, 0], [303, 20, 2, 3], 8.1],
      [[62, 8, 2], [37, 4, 1], [108, 11, 4, 3], [200, 16, 5, 4], 8.11],
      [[137, 13, 5, 2], [53, 6, 5], [352, 22, "A2"], [29, 2, 5], 8.52],
      [[373, 22, "E3"], [302, 20, 2, 1], [65, 8, 5], [116, 12, 2, 1], 8.63],
      [[148, 14, 4, 1], [90, 10, 2, 3], [53, 6, 5], [328, 21, 1, 3], 9.2]
    ],
    [
      [[226, 17, 5, 0], [153, 14, 3, 4], [9, 1, 2], [51, 6, 3], 7.9],
      [[60, 8, 0], [32, 3, 2], [351, 22, "A1"], [20, 1, 5], 7.93],
      [[56, 7, 2], [66, 9, 0, 1], [25, 2, 1], [139, 13, 5, 3], 8.62],
      [[20, 1, 5], [351, 22, "A1"], [37, 4, 1], [164, 15, 5, 1], 8.83],
      [[324, 21, 0, 4], [76, 9, 4, 2], [17, 1, 4], [45, 5, 3], 8.9],
      [[60, 8, 0], [45, 5, 3], [300, 20, 1, 5], [88, 10, 4, 1], 9.05],
      [[78, 9, 3, 4], [166, 15, 4, 2], [60, 8, 0], [32, 3, 2], 9.05],
      [[240, 18, 1, 5], [84, 10, 4, 0], [322, 21, 0, 2], [351, 22, "A1"], 9.18],
      [[4, 1, 1], [371, 22, "E1"], [236, 18, 1, 0], [321, 21, 0, 1], 9.81],
      [[53, 6, 5], [116, 12, 1, 2], [324, 21, 0, 4], [190, 16, 3, 5], 10.12],
      [[355, 22, "A5"], [13, 1, 3], [29, 2, 5], [60, 8, 0], 10.19],
      [[351, 22, "A1"], [274, 19, 2, 4], [29, 2, 5], [127, 13, 0, 2], 10.39]
    ],
    [
      [[353, 22, "A3"], [115, 12, 0, 5], [102, 11, 3, 1], [18, 1, 4], 7.05],
      [[340, 21, 3, 5], [18, 1, 4], [102, 11, 3, 1], [158, 15, 0, 3], 7.21],
      [[102, 11, 3, 1], [272, 19, 2, 1], [148, 14, 4, 1], [65, 8, 5], 7.83],
      [[33, 3, 3], [68, 9, 0, 3], [294, 20, 0, 4], [167, 15, 2, 5], 7.86],
      [[375, 22, "E5"], [318, 20, 5, 2], [238, 18, 1, 3], [62, 8, 2], 8.37],
      [[18, 1, 4], [62, 8, 2], [148, 14, 4, 1], [293, 20, 0, 3], 8.8],
      [[75, 9, 3, 2], [18, 1, 4], [147, 14, 3, 1], [28, 2, 4], 9.61],
      [[88, 10, 4, 1], [50, 6, 2], [291, 20, 0, 1], [25, 2, 1], 10.0],
      [[38, 4, 2], [342, 21, 4, 1], [81, 10, 0, 1], [145, 14, 0, 5], 10.36],
      [[335, 21, 2, 5], [92, 10, 2, 5], [41, 4, 5], [239, 18, 1, 4], 10.46],
      [[61, 8, 1], [277, 19, 3, 1], [36, 4, 0], [342, 21, 4, 1], 10.85],
      [[50, 6, 2], [78, 9, 3, 4], [140, 13, 4, 5], [3, 1, 0], 11.22]
    ],
    [
      [[48, 6, 0], [361, 22, "C1"], [187, 16, 3, 1], [34, 3, 4], 7.65],
      [[60, 8, 0], [374, 22, "E4"], [23, 1, 5], [149, 14, 5, 1], 7.75],
      [[348, 21, 5, 2], [366, 22, "D1"], [130, 13, 5, 0], [34, 3, 4], 7.88],
      [[209, 17, 1, 4], [7, 1, 1], [98, 11, 3, 0], [63, 8, 3], 8.08],
      [[366, 22, "D1"], [3, 1, 0], [339, 21, 3, 4], [76, 9, 2, 4], 8.22],
      [[87, 10, 3, 1], [128, 13, 3, 0], [46, 5, 4], [233, 18, 0, 3], 8.24],
      [[81, 10, 1, 0], [361, 22, "C1"], [119, 12, 5, 1], [211, 17, 2, 0], 8.78],
      [[10, 1, 2], [34, 3, 4], [92, 10, 2, 5], [36, 4, 0], 8.85],
      [[15, 1, 3], [62, 8, 2], [48, 6, 0], [366, 22, "D1"], 9.04],
      [[154, 14, 5, 3], [71, 9, 2, 1], [366, 22, "D1"], [348, 21, 5, 2], 9.06],
      [[24, 2, 0], [294, 20, 0, 4], [98, 11, 3, 0], [83, 10, 3, 0], 9.42],
      [[24, 2, 0], [3, 1, 0], [292, 20, 0, 2], [60, 8, 0], 9.93]
    ],
    [
      [[306, 20, 3, 0], [355, 22, "A5"], [24, 2, 0], [20, 1, 5], 6.38],
      [[354, 22, "A4"], [234, 18, 0, 4], [20, 1, 5], [98, 11, 3, 0], 7.41],
      [[20, 1, 5], [63, 8, 3], [98, 11, 3, 0], [306, 20, 3, 0], 7.43],
      [[35, 3, 5], [98, 11, 3, 0], [366, 22, "D1"], [343, 21, 4, 2], 7.95],
      [[98, 11, 3, 0], [229, 17, 5, 3], [60, 8, 0], [28, 2, 4], 8.5],
      [[98, 11, 3, 0], [83, 10, 3, 0], [284, 19, 4, 3], [306, 20, 3, 0], 8.71],
      [[327, 21, 1, 2], [355, 22, "A5"], [86, 10, 2, 1], [56, 7, 2], 9.27],
      [[118, 12, 4, 1], [327, 21, 1, 2], [355, 22, "A5"], [84, 10, 4, 0], 9.34],
      [[20, 1, 5], [24, 2, 0], [61, 8, 1], [193, 16, 4, 2], 9.65],
      [[63, 8, 3], [355, 22, "A5"], [25, 2, 1], [52, 6, 4], 10.08],
      [[39, 4, 3], [346, 21, 5, 0], [84, 10, 4, 0], [25, 2, 1], 10.1],
      [[27, 2, 3], [59, 7, 5], [74, 9, 5, 1], [60, 8, 0], 10.54]
    ],
    [
      [[63, 8, 3], [90, 10, 3, 2], [374, 22, "E4"], [307, 20, 3, 1], 7.72],
      [[68, 9, 3, 0], [340, 21, 3, 5], [125, 12, 5, 4], [63, 8, 3], 8.44],
      [[138, 13, 3, 4], [67, 9, 2, 0], [62, 8, 2], [20, 1, 5], 8.93],
      [[39, 4, 3], [68, 9, 3, 0], [20, 1, 5], [62, 8, 2], 9.4],
      [[125, 12, 5, 4], [76, 9, 2, 4], [10, 1, 2], [46, 5, 4], 9.4],
      [[295, 20, 0, 5], [374, 22, "E4"], [218, 17, 3, 2], [63, 8, 3], 9.55],
      [[70, 9, 5, 0], [322, 21, 0, 2], [15, 1, 3], [144, 14, 4, 0], 9.62],
      [[41, 4, 5], [63, 8, 3], [125, 12, 5, 4], [252, 18, 4, 1], 10.19],
      [[262, 19, 0, 2], [92, 10, 5, 2], [111, 12, 1, 0], [62, 8, 2], 10.57],
      [[302, 20, 2, 1], [374, 22, "E4"], [29, 2, 5], [251, 18, 4, 0], 10.78],
      [[229, 17, 5, 3], [54, 7, 0], [92, 10, 5, 2], [302, 20, 2, 1], 10.78],
      [[38, 4, 2], [121, 12, 2, 4], [74, 9, 5, 1], [254, 18, 4, 3], 11.73]
    ],
    [
      [[140, 13, 4, 5], [109, 11, 3, 5], [23, 1, 5], [247, 18, 3, 1], 6.36],
      [[351, 22, "A1"], [7, 1, 1], [105, 11, 3, 2], [240, 18, 1, 5], 6.54],
      [[103, 11, 4, 1], [351, 22, "A1"], [288, 19, 5, 2], [57, 7, 3], 6.77],
      [[64, 8, 4], [53, 6, 5], [352, 22, "A2"], [142, 14, 2, 0], 7.99],
      [[64, 8, 4], [255, 18, 4, 5], [50, 6, 2], [7, 1, 1], 9.1],
      [[55, 7, 1], [72, 9, 3, 1], [133, 13, 4, 1], [60, 8, 0], 9.12],
      [[29, 2, 5], [90, 10, 3, 2], [369, 22, "D4"], [65, 8, 5], 9.18],
      [[109, 11, 3, 5], [16, 1, 4], [92, 10, 2, 5], [348, 21, 5, 2], 9.36],
      [[72, 9, 3, 1], [124, 12, 3, 5], [0, 1, 0], [24, 2, 0], 9.59],
      [[65, 8, 5], [109, 11, 3, 5], [308, 20, 3, 2], [226, 17, 5, 0], 9.68],
      [[55, 7, 1], [162, 15, 3, 1], [84, 10, 4, 0], [60, 8, 0], 10.18],
      [[152, 14, 2, 5], [71, 9, 2, 1], [36, 4, 0], [109, 11, 3, 5], 10.5]
    ],
    [
      [[337, 21, 3, 1], [357, 22, "B2"], [62, 8, 2], [36, 4, 0], 9.59],
      [[85, 10, 0, 5], [59, 7, 5], [18, 1, 4], [354, 22, "A4"], 9.62],
      [[277, 19, 3, 1], [77, 9, 2, 5], [337, 21, 3, 1], [5, 1, 1], 9.92],
      [
        [278, 19, 3, 2],
        [357, 22, "B2"],
        [346, 21, 5, 0],
        [143, 14, 0, 3],
        10.25
      ],
      [[338, 21, 3, 2], [28, 2, 4], [147, 14, 1, 3], [352, 22, "A2"], 10.43],
      [[60, 8, 0], [112, 12, 2, 0], [303, 20, 2, 3], [55, 7, 1], 10.59],
      [[62, 8, 2], [301, 20, 2, 0], [55, 7, 1], [124, 12, 5, 3], 10.84],
      [[352, 22, "A2"], [26, 2, 2], [242, 18, 2, 1], [60, 8, 0], 11.14],
      [[79, 9, 5, 3], [8, 1, 2], [145, 14, 0, 5], [354, 22, "A4"], 11.83],
      [[29, 2, 5], [354, 22, "A4"], [137, 13, 2, 5], [23, 1, 5], 11.84],
      [[91, 10, 2, 4], [60, 8, 0], [124, 12, 5, 3], [354, 22, "A4"], 11.91],
      [[18, 1, 4], [27, 2, 3], [192, 16, 4, 1], [301, 20, 2, 0], 12.28]
    ],
    [
      [[320, 20, 5, 4], [24, 2, 0], [76, 9, 2, 4], [11, 1, 2], 7.65],
      [[245, 18, 2, 5], [56, 7, 2], [140, 13, 4, 5], [21, 1, 5], 8.06],
      [[32, 3, 2], [146, 14, 2, 1], [306, 20, 3, 0], [74, 9, 5, 1], 8.19],
      [[71, 9, 2, 1], [32, 3, 2], [12, 1, 3], [61, 8, 1], 8.41],
      [[81, 10, 0, 1], [40, 4, 4], [32, 3, 2], [301, 20, 2, 0], 8.53],
      [[375, 22, "E5"], [32, 3, 2], [4, 1, 1], [51, 6, 3], 8.57],
      [[324, 21, 0, 4], [44, 5, 2], [95, 10, 4, 5], [375, 22, "E5"], 8.73],
      [[351, 22, "A1"], [60, 8, 0], [245, 18, 2, 5], [21, 1, 5], 9.13],
      [[121, 12, 2, 4], [18, 1, 4], [60, 8, 0], [296, 20, 1, 0], 9.46],
      [[60, 8, 0], [77, 9, 2, 5], [202, 17, 0, 2], [347, 21, 5, 1], 9.56],
      [[316, 20, 5, 0], [199, 16, 5, 3], [79, 9, 5, 3], [120, 12, 2, 3], 9.83],
      [[25, 2, 1], [61, 8, 1], [149, 14, 5, 1], [375, 22, "E5"], 10.18]
    ],
    [
      [[229, 17, 5, 3], [110, 11, 5, 4], [1, 1, 0], [375, 22, "E5"], 6.25],
      [[20, 1, 5], [322, 21, 0, 2], [57, 7, 3], [120, 12, 3, 2], 6.51],
      [[1, 1, 0], [29, 2, 5], [110, 11, 5, 4], [322, 21, 0, 2], 7.44],
      [[44, 5, 2], [101, 11, 1, 2], [60, 8, 0], [247, 18, 3, 1], 7.82],
      [[27, 2, 3], [375, 22, "E5"], [64, 8, 4], [57, 7, 3], 7.92],
      [[133, 13, 1, 4], [329, 21, 1, 4], [60, 8, 0], [101, 11, 1, 2], 8.37],
      [[19, 1, 4], [64, 8, 4], [289, 19, 5, 3], [110, 11, 5, 4], 8.37],
      [[269, 19, 1, 4], [64, 8, 4], [20, 1, 5], [85, 10, 5, 0], 8.54],
      [[121, 12, 2, 4], [60, 8, 0], [309, 20, 3, 4], [27, 2, 3], 8.85],
      [[74, 9, 1, 5], [110, 11, 5, 4], [64, 8, 4], [19, 1, 4], 8.88],
      [[356, 22, "B1"], [85, 10, 5, 0], [28, 2, 4], [19, 1, 4], 9.25],
      [[64, 8, 4], [110, 11, 5, 4], [225, 17, 4, 5], [49, 6, 1], 9.43]
    ],
    [
      [[365, 22, "C5"], [222, 17, 4, 1], [70, 9, 0, 5], [117, 12, 3, 1], 6.76],
      [[62, 8, 2], [82, 10, 0, 2], [368, 22, "D3"], [2, 1, 0], 6.93],
      [[365, 22, "C5"], [31, 3, 1], [62, 8, 2], [68, 9, 0, 3], 6.94],
      [[41, 4, 5], [31, 3, 1], [90, 10, 2, 3], [64, 8, 4], 7.34],
      [[184, 16, 2, 4], [70, 9, 0, 5], [368, 22, "D3"], [170, 15, 4, 5], 7.87],
      [[94, 10, 5, 3], [62, 8, 2], [371, 22, "E1"], [302, 20, 2, 1], 8.28],
      [[304, 20, 2, 4], [39, 4, 3], [31, 3, 1], [129, 13, 0, 4], 8.51],
      [[207, 17, 1, 2], [43, 5, 1], [326, 21, 1, 0], [31, 3, 1], 8.65],
      [[2, 1, 0], [220, 17, 3, 5], [68, 9, 0, 3], [303, 20, 2, 3], 9.0],
      [[74, 9, 5, 1], [179, 16, 1, 4], [368, 22, "D3"], [28, 2, 4], 9.66],
      [[301, 20, 2, 0], [39, 4, 3], [123, 12, 4, 3], [284, 19, 4, 3], 10.62],
      [[153, 14, 4, 3], [183, 16, 2, 3], [38, 4, 2], [304, 20, 2, 4], 11.79]
    ],
    [
      [[362, 22, "C2"], [94, 10, 3, 5], [31, 3, 1], [63, 8, 3], 6.2],
      [[370, 22, "D5"], [67, 9, 0, 2], [31, 3, 1], [256, 18, 5, 0], 6.66],
      [[55, 7, 1], [169, 15, 3, 5], [8, 1, 2], [24, 2, 0], 7.01],
      [[55, 7, 1], [118, 12, 1, 4], [31, 3, 1], [310, 20, 3, 5], 7.4],
      [[214, 17, 2, 4], [29, 2, 5], [55, 7, 1], [362, 22, "C2"], 7.83],
      [[17, 1, 4], [186, 16, 3, 0], [80, 9, 5, 4], [339, 21, 3, 4], 8.21],
      [[55, 7, 1], [351, 22, "A1"], [237, 18, 1, 2], [23, 1, 5], 8.24],
      [[362, 22, "C2"], [48, 6, 0], [17, 1, 4], [257, 18, 5, 1], 8.53],
      [[27, 2, 3], [257, 18, 5, 1], [63, 8, 3], [148, 14, 1, 4], 9.58],
      [[351, 22, "A1"], [29, 2, 5], [214, 17, 2, 4], [118, 12, 1, 4], 9.78],
      [[112, 12, 0, 2], [332, 21, 2, 1], [64, 8, 4], [92, 10, 5, 2], 10.11],
      [[111, 12, 0, 1], [335, 21, 2, 5], [177, 16, 1, 2], [60, 8, 0], 10.84]
    ],
    [
      [[22, 1, 5], [45, 5, 3], [155, 14, 4, 5], [357, 22, "B2"], 5.97],
      [[357, 22, "B2"], [52, 6, 4], [22, 1, 5], [110, 11, 4, 5], 6.08],
      [[22, 1, 5], [251, 18, 4, 0], [163, 15, 4, 1], [357, 22, "B2"], 6.47],
      [[110, 11, 4, 5], [85, 10, 5, 0], [357, 22, "B2"], [251, 18, 4, 0], 7.17],
      [[325, 21, 0, 5], [159, 15, 4, 0], [56, 7, 2], [8, 1, 2], 7.93],
      [[110, 11, 4, 5], [45, 5, 3], [60, 8, 0], [371, 22, "E1"], 8.31],
      [[92, 10, 2, 5], [52, 6, 4], [312, 20, 4, 1], [8, 1, 2], 8.39],
      [[12, 1, 3], [52, 6, 4], [61, 8, 1], [374, 22, "E4"], 8.47],
      [[45, 5, 3], [25, 2, 1], [164, 15, 1, 5], [110, 11, 4, 5], 8.84],
      [[110, 11, 4, 5], [79, 9, 3, 5], [45, 5, 3], [312, 20, 4, 1], 8.95],
      [[321, 21, 0, 1], [64, 8, 4], [197, 16, 5, 1], [110, 11, 4, 5], 9.15],
      [[262, 19, 0, 2], [26, 2, 2], [374, 22, "E4"], [60, 8, 0], 9.72]
    ],
    [
      [[12, 1, 3], [353, 22, "A3"], [110, 11, 5, 4], [213, 17, 2, 3], 6.8],
      [[375, 22, "E5"], [5, 1, 1], [62, 8, 2], [110, 11, 5, 4], 6.99],
      [[110, 11, 5, 4], [133, 13, 1, 4], [348, 21, 5, 2], [49, 6, 1], 7.79],
      [[23, 1, 5], [110, 11, 5, 4], [61, 8, 1], [336, 21, 3, 0], 8.01],
      [
        [169, 15, 5, 3],
        [198, 16, 5, 2],
        [110, 11, 5, 4],
        [338, 21, 3, 2],
        8.03
      ],
      [[310, 20, 3, 5], [62, 8, 2], [375, 22, "E5"], [67, 9, 2, 0], 8.08],
      [[110, 11, 5, 4], [203, 17, 0, 3], [117, 12, 1, 3], [23, 1, 5], 8.16],
      [[61, 8, 1], [353, 22, "A3"], [290, 19, 5, 4], [83, 10, 0, 3], 8.22],
      [[315, 20, 4, 5], [187, 16, 3, 1], [110, 11, 5, 4], [79, 9, 5, 3], 9.0],
      [[62, 8, 2], [153, 14, 4, 3], [346, 21, 5, 0], [110, 11, 5, 4], 9.45],
      [[85, 10, 5, 0], [49, 6, 1], [166, 15, 2, 4], [23, 1, 5], 9.88],
      [[213, 17, 2, 3], [95, 10, 5, 4], [110, 11, 5, 4], [50, 6, 2], 10.06]
    ],
    [
      [[47, 5, 5], [20, 1, 5], [92, 10, 5, 2], [135, 13, 3, 2], 6.22],
      [
        [362, 22, "C2"],
        [107, 11, 5, 2],
        [111, 12, 0, 1],
        [302, 20, 2, 1],
        7.55
      ],
      [[267, 19, 1, 2], [375, 22, "E5"], [107, 11, 5, 2], [72, 9, 1, 3], 7.57],
      [
        [107, 11, 5, 2],
        [311, 20, 4, 0],
        [279, 19, 3, 4],
        [161, 15, 1, 2],
        7.82
      ],
      [[337, 21, 3, 1], [50, 6, 2], [107, 11, 5, 2], [82, 10, 0, 2], 8.01],
      [[107, 11, 5, 2], [154, 14, 5, 3], [4, 1, 1], [301, 20, 2, 0], 8.24],
      [[4, 1, 1], [136, 13, 4, 2], [107, 11, 5, 2], [62, 8, 2], 8.62],
      [[74, 9, 5, 1], [362, 22, "C2"], [58, 7, 4], [159, 15, 4, 0], 8.67],
      [[303, 20, 2, 3], [147, 14, 1, 3], [254, 18, 4, 3], [25, 2, 1], 8.71],
      [[1, 1, 0], [195, 16, 4, 5], [61, 8, 1], [343, 21, 4, 2], 8.9],
      [[135, 13, 3, 2], [196, 16, 5, 0], [15, 1, 3], [361, 22, "C1"], 8.95],
      [[181, 16, 2, 0], [24, 2, 0], [63, 8, 3], [113, 12, 0, 3], 11.66]
    ],
    [
      [[359, 22, "B4"], [169, 15, 3, 5], [41, 4, 5], [109, 11, 3, 5], 6.58],
      [[109, 11, 3, 5], [7, 1, 1], [61, 8, 1], [359, 22, "B4"], 6.59],
      [[109, 11, 3, 5], [222, 17, 4, 1], [64, 8, 4], [358, 22, "B3"], 8.57],
      [[358, 22, "B3"], [60, 8, 0], [304, 20, 2, 4], [70, 9, 5, 0], 9.14],
      [[332, 21, 2, 1], [358, 22, "B3"], [64, 8, 4], [163, 15, 4, 1], 9.5],
      [[292, 20, 0, 2], [63, 8, 3], [109, 11, 3, 5], [27, 2, 3], 9.57],
      [[333, 21, 2, 3], [356, 22, "B1"], [234, 18, 0, 4], [48, 6, 0], 9.76],
      [[109, 11, 3, 5], [119, 12, 5, 1], [86, 10, 1, 2], [61, 8, 1], 9.81],
      [[3, 1, 0], [273, 19, 2, 3], [25, 2, 1], [48, 6, 0], 9.81],
      [[60, 8, 0], [109, 11, 3, 5], [51, 6, 3], [124, 12, 3, 5], 9.82],
      [[40, 4, 4], [64, 8, 4], [356, 22, "B1"], [7, 1, 1], 10.13],
      [[226, 17, 5, 0], [60, 8, 0], [59, 7, 5], [345, 21, 4, 5], 10.82]
    ],
    [
      [[363, 22, "C3"], [107, 11, 2, 5], [141, 14, 1, 0], [61, 8, 1], 6.32],
      [[70, 9, 5, 0], [6, 1, 1], [61, 8, 1], [107, 11, 2, 5], 7.37],
      [[355, 22, "A5"], [107, 11, 2, 5], [83, 10, 3, 0], [330, 21, 1, 5], 8.14],
      [[73, 9, 4, 1], [18, 1, 4], [344, 21, 4, 3], [107, 11, 2, 5], 8.32],
      [[363, 22, "C3"], [226, 17, 5, 0], [66, 9, 1, 0], [335, 21, 2, 5], 8.54],
      [[330, 21, 1, 5], [6, 1, 1], [352, 22, "A2"], [63, 8, 3], 8.58],
      [[363, 22, "C3"], [3, 1, 0], [53, 6, 5], [95, 10, 5, 4], 8.66],
      [[107, 11, 2, 5], [56, 7, 2], [352, 22, "A2"], [132, 13, 3, 1], 8.82],
      [[328, 21, 1, 3], [174, 16, 0, 4], [52, 6, 4], [18, 1, 4], 9.28],
      [[56, 7, 2], [352, 22, "A2"], [28, 2, 4], [333, 21, 2, 3], 10.31],
      [[65, 8, 5], [355, 22, "A5"], [83, 10, 3, 0], [29, 2, 5], 10.38],
      [[328, 21, 1, 3], [114, 12, 4, 0], [27, 2, 3], [352, 22, "A2"], 10.8]
    ],
    [
      [[37, 4, 1], [25, 2, 1], [102, 11, 1, 3], [368, 22, "D3"], 7.66],
      [[2, 1, 0], [189, 16, 3, 4], [83, 10, 0, 3], [368, 22, "D3"], 7.78],
      [[169, 15, 5, 3], [372, 22, "E2"], [46, 5, 4], [102, 11, 1, 3], 8.0],
      [[126, 13, 0, 1], [55, 7, 1], [22, 1, 5], [355, 22, "A5"], 8.14],
      [[22, 1, 5], [236, 18, 1, 0], [80, 9, 5, 4], [34, 3, 4], 8.32],
      [[132, 13, 1, 3], [83, 10, 0, 3], [60, 8, 0], [297, 20, 1, 2], 8.41],
      [[131, 13, 2, 1], [62, 8, 2], [368, 22, "D3"], [82, 10, 2, 0], 8.42],
      [[128, 13, 0, 3], [83, 10, 0, 3], [34, 3, 4], [195, 16, 4, 5], 9.54],
      [[262, 19, 0, 2], [73, 9, 4, 1], [5, 1, 1], [60, 8, 0], 9.97],
      [[37, 4, 1], [295, 20, 0, 5], [13, 1, 3], [74, 9, 5, 1], 10.14],
      [[82, 10, 2, 0], [41, 4, 5], [321, 21, 0, 1], [29, 2, 5], 11.18],
      [[60, 8, 0], [120, 12, 2, 3], [250, 18, 3, 5], [36, 4, 0], 11.6]
    ],
    [
      [[306, 20, 3, 0], [49, 6, 1], [8, 1, 2], [98, 11, 0, 3], 7.35],
      [[334, 21, 2, 4], [144, 14, 0, 4], [174, 16, 0, 4], [35, 3, 5], 8.32],
      [[168, 15, 4, 3], [107, 11, 2, 5], [70, 9, 0, 5], [48, 6, 0], 8.76],
      [[107, 11, 2, 5], [14, 1, 3], [63, 8, 3], [318, 20, 5, 2], 8.8],
      [[366, 22, "D1"], [113, 12, 0, 3], [35, 3, 5], [98, 11, 0, 3], 8.96],
      [[142, 14, 2, 0], [93, 10, 4, 3], [62, 8, 2], [22, 1, 5], 9.11],
      [[22, 1, 5], [39, 4, 3], [27, 2, 3], [328, 21, 1, 3], 9.21],
      [[25, 2, 1], [248, 18, 3, 2], [80, 9, 4, 5], [5, 1, 1], 9.26],
      [[308, 20, 3, 2], [82, 10, 2, 0], [25, 2, 1], [58, 7, 4], 9.56],
      [[172, 16, 0, 2], [28, 2, 4], [142, 14, 2, 0], [71, 9, 2, 1], 10.16],
      [[69, 9, 0, 4], [98, 11, 0, 3], [40, 4, 4], [224, 17, 4, 3], 10.32],
      [[98, 11, 0, 3], [364, 22, "C4"], [28, 2, 4], [147, 14, 1, 3], 10.38]
    ],
    [
      [[365, 22, "C5"], [129, 13, 0, 4], [108, 11, 4, 3], [11, 1, 2], 5.88],
      [[331, 21, 2, 0], [22, 1, 5], [355, 22, "A5"], [44, 5, 2], 6.38],
      [[305, 20, 2, 5], [108, 11, 4, 3], [32, 3, 2], [40, 4, 4], 7.5],
      [[61, 8, 1], [88, 10, 1, 4], [108, 11, 4, 3], [32, 3, 2], 7.58],
      [[71, 9, 1, 2], [61, 8, 1], [108, 11, 4, 3], [163, 15, 1, 4], 8.18],
      [[85, 10, 0, 5], [107, 11, 2, 5], [279, 19, 3, 4], [331, 21, 2, 0], 8.39],
      [[337, 21, 3, 1], [60, 8, 0], [365, 22, "C5"], [27, 2, 3], 8.49],
      [[93, 10, 4, 3], [129, 13, 0, 4], [296, 20, 1, 0], [355, 22, "A5"], 8.55],
      [[37, 4, 1], [168, 15, 4, 3], [107, 11, 2, 5], [67, 9, 0, 2], 8.65],
      [[338, 21, 3, 2], [60, 8, 0], [40, 4, 4], [2, 1, 0], 9.23],
      [[355, 22, "A5"], [4, 1, 1], [118, 12, 1, 4], [341, 21, 4, 0], 9.58],
      [[338, 21, 3, 2], [60, 8, 0], [243, 18, 2, 3], [111, 12, 1, 0], 9.8]
    ],
    [
      [[0, 1, 0], [99, 11, 4, 0], [211, 17, 2, 0], [362, 22, "C2"], 4.77],
      [[99, 11, 4, 0], [82, 10, 2, 0], [32, 3, 2], [22, 1, 5], 5.63],
      [[25, 2, 1], [362, 22, "C2"], [99, 11, 4, 0], [309, 20, 3, 4], 6.36],
      [[32, 3, 2], [356, 22, "B1"], [99, 11, 4, 0], [334, 21, 2, 4], 6.78],
      [[356, 22, "B1"], [99, 11, 4, 0], [290, 19, 5, 4], [150, 14, 2, 3], 7.78],
      [[56, 7, 2], [60, 8, 0], [309, 20, 3, 4], [32, 3, 2], 8.08],
      [[318, 20, 5, 2], [60, 8, 0], [15, 1, 3], [32, 3, 2], 8.1],
      [[130, 13, 5, 0], [317, 20, 5, 1], [39, 4, 3], [356, 22, "B1"], 8.16],
      [[99, 11, 4, 0], [197, 16, 5, 1], [24, 2, 0], [120, 12, 2, 3], 8.48],
      [[334, 21, 2, 4], [8, 1, 2], [276, 19, 3, 0], [167, 15, 5, 2], 8.51],
      [[372, 22, "E2"], [0, 1, 0], [318, 20, 5, 2], [151, 14, 4, 2], 8.63],
      [[253, 18, 4, 2], [37, 4, 1], [0, 1, 0], [73, 9, 4, 1], 9.23]
    ],
    [
      [[46, 5, 4], [10, 1, 2], [84, 10, 0, 4], [103, 11, 4, 1], 6.5],
      [[342, 21, 4, 1], [18, 1, 4], [57, 7, 3], [103, 11, 4, 1], 6.54],
      [[234, 18, 0, 4], [3, 1, 0], [361, 22, "C1"], [103, 11, 4, 1], 7.33],
      [
        [215, 17, 2, 5],
        [154, 14, 5, 3],
        [103, 11, 4, 1],
        [361, 22, "C1"],
        8.24
      ],
      [[103, 11, 4, 1], [166, 15, 4, 2], [331, 21, 2, 0], [14, 1, 3], 8.92],
      [[62, 8, 2], [103, 11, 4, 1], [3, 1, 0], [158, 15, 0, 3], 8.96],
      [[6, 1, 1], [79, 9, 5, 3], [103, 11, 4, 1], [57, 7, 3], 8.99],
      [[284, 19, 4, 3], [364, 22, "C4"], [57, 7, 3], [60, 8, 0], 9.01],
      [[39, 4, 3], [65, 8, 5], [24, 2, 0], [103, 11, 4, 1], 9.48],
      [[14, 1, 3], [133, 13, 4, 1], [83, 10, 0, 3], [282, 19, 4, 1], 9.55],
      [[74, 9, 5, 1], [62, 8, 2], [23, 1, 5], [364, 22, "C4"], 9.55],
      [[24, 2, 0], [140, 13, 4, 5], [67, 9, 0, 2], [46, 5, 4], 10.36]
    ],
    [
      [[21, 1, 5], [105, 11, 2, 3], [54, 7, 0], [371, 22, "E1"], 5.04],
      [[27, 2, 3], [67, 9, 2, 0], [369, 22, "D4"], [324, 21, 0, 4], 6.87],
      [[13, 1, 3], [72, 9, 3, 1], [355, 22, "A5"], [330, 21, 1, 5], 7.34],
      [[5, 1, 1], [225, 17, 4, 5], [369, 22, "D4"], [25, 2, 1], 7.86],
      [[8, 1, 2], [28, 2, 4], [355, 22, "A5"], [134, 13, 1, 5], 7.98],
      [[16, 1, 4], [24, 2, 0], [253, 18, 4, 2], [105, 11, 2, 3], 8.02],
      [[47, 5, 5], [61, 8, 1], [16, 1, 4], [105, 11, 2, 3], 8.1],
      [[355, 22, "A5"], [13, 1, 3], [185, 16, 2, 5], [64, 8, 4], 8.38],
      [[105, 11, 2, 3], [304, 20, 2, 4], [49, 6, 1], [61, 8, 1], 9.18],
      [[61, 8, 1], [77, 9, 2, 5], [153, 14, 4, 3], [303, 20, 2, 3], 9.73],
      [[218, 17, 3, 2], [52, 6, 4], [88, 10, 4, 1], [5, 1, 1], 9.74],
      [[181, 16, 2, 0], [38, 4, 2], [61, 8, 1], [126, 13, 0, 1], 11.09]
    ],
    [
      [[355, 22, "A5"], [61, 8, 1], [108, 11, 4, 3], [34, 3, 4], 6.17],
      [
        [311, 20, 4, 0],
        [362, 22, "C2"],
        [108, 11, 4, 3],
        [286, 19, 5, 0],
        6.56
      ],
      [[54, 7, 0], [108, 11, 4, 3], [13, 1, 3], [220, 17, 3, 5], 7.05],
      [[20, 1, 5], [349, 21, 5, 3], [63, 8, 3], [360, 22, "B5"], 7.44],
      [[34, 3, 4], [205, 17, 0, 5], [360, 22, "B5"], [13, 1, 3], 7.61],
      [[360, 22, "B5"], [1, 1, 0], [61, 8, 1], [108, 11, 4, 3], 7.97],
      [[20, 1, 5], [360, 22, "B5"], [301, 20, 2, 0], [204, 17, 0, 4], 8.07],
      [[135, 13, 2, 3], [81, 10, 1, 0], [4, 1, 1], [63, 8, 3], 9.16],
      [[305, 20, 2, 5], [62, 8, 2], [4, 1, 1], [24, 2, 0], 9.31],
      [[61, 8, 1], [13, 1, 3], [25, 2, 1], [178, 16, 1, 3], 9.73],
      [[62, 8, 2], [27, 2, 3], [20, 1, 5], [271, 19, 2, 0], 9.99],
      [[355, 22, "A5"], [235, 18, 0, 5], [301, 20, 2, 0], [54, 7, 0], 10.23]
    ],
    [
      [[62, 8, 2], [353, 22, "A3"], [243, 18, 2, 3], [57, 7, 3], 7.08],
      [[139, 13, 3, 5], [40, 4, 4], [16, 1, 4], [353, 22, "A3"], 7.56],
      [[64, 8, 4], [285, 19, 4, 5], [338, 21, 3, 2], [33, 3, 3], 8.3],
      [[361, 22, "C1"], [318, 20, 5, 2], [43, 5, 1], [16, 1, 4], 8.54],
      [[33, 3, 3], [375, 22, "E5"], [41, 4, 5], [211, 17, 2, 0], 8.62],
      [[26, 2, 2], [294, 20, 0, 4], [375, 22, "E5"], [224, 17, 4, 3], 8.78],
      [[10, 1, 2], [65, 8, 5], [36, 4, 0], [234, 18, 0, 4], 8.78],
      [[16, 1, 4], [145, 14, 0, 5], [72, 9, 1, 3], [45, 5, 3], 8.95],
      [[375, 22, "E5"], [343, 21, 4, 2], [65, 8, 5], [70, 9, 0, 5], 9.39],
      [[65, 8, 5], [291, 20, 0, 1], [13, 1, 3], [40, 4, 4], 9.75],
      [[65, 8, 5], [13, 1, 3], [167, 15, 2, 5], [25, 2, 1], 9.86],
      [[25, 2, 1], [188, 16, 3, 2], [294, 20, 0, 4], [16, 1, 4], 10.17]
    ],
    [
      [[98, 11, 0, 3], [92, 10, 2, 5], [357, 22, "B2"], [306, 20, 3, 0], 7.2],
      [[53, 6, 5], [62, 8, 2], [98, 11, 0, 3], [357, 22, "B2"], 7.42],
      [[103, 11, 1, 4], [22, 1, 5], [65, 8, 5], [333, 21, 2, 3], 7.72],
      [[29, 2, 5], [307, 20, 3, 1], [69, 9, 0, 4], [103, 11, 1, 4], 8.12],
      [[22, 1, 5], [41, 4, 5], [162, 15, 1, 3], [332, 21, 2, 1], 8.28],
      [[356, 22, "B1"], [192, 16, 4, 1], [46, 5, 4], [14, 1, 3], 8.42],
      [[129, 13, 0, 4], [192, 16, 4, 1], [98, 11, 0, 3], [65, 8, 5], 9.26],
      [[98, 11, 0, 3], [37, 4, 1], [16, 1, 4], [78, 9, 4, 3], 9.33],
      [[203, 17, 0, 3], [98, 11, 0, 3], [61, 8, 1], [90, 10, 3, 2], 9.36],
      [[39, 4, 3], [79, 9, 3, 5], [370, 22, "D5"], [65, 8, 5], 9.55],
      [[208, 17, 1, 3], [29, 2, 5], [91, 10, 4, 2], [356, 22, "B1"], 9.76],
      [[63, 8, 3], [98, 11, 0, 3], [37, 4, 1], [16, 1, 4], 9.81]
    ],
    [
      [[36, 4, 0], [4, 1, 1], [366, 22, "D1"], [24, 2, 0], 7.46],
      [[34, 3, 4], [43, 5, 1], [60, 8, 0], [330, 21, 1, 5], 7.53],
      [[4, 1, 1], [34, 3, 4], [151, 14, 4, 2], [351, 22, "A1"], 7.61],
      [[23, 1, 5], [92, 10, 2, 5], [314, 20, 4, 3], [351, 22, "A1"], 7.62],
      [[154, 14, 3, 5], [34, 3, 4], [74, 9, 1, 5], [8, 1, 2], 8.11],
      [[148, 14, 4, 1], [331, 21, 2, 0], [26, 2, 2], [360, 22, "B5"], 8.25],
      [[34, 3, 4], [226, 17, 5, 0], [366, 22, "D1"], [62, 8, 2], 8.77],
      [[4, 1, 1], [211, 17, 2, 0], [147, 14, 1, 3], [43, 5, 1], 9.16],
      [[134, 13, 1, 5], [12, 1, 3], [60, 8, 0], [314, 20, 4, 3], 9.4],
      [[351, 22, "A1"], [12, 1, 3], [303, 20, 2, 3], [270, 19, 1, 5], 9.67],
      [[38, 4, 2], [26, 2, 2], [199, 16, 5, 3], [161, 15, 1, 2], 10.02],
      [[305, 20, 2, 5], [67, 9, 2, 0], [192, 16, 4, 1], [60, 8, 0], 10.65]
    ],
    [
      [[58, 7, 4], [11, 1, 2], [63, 8, 3], [103, 11, 1, 4], 7.4],
      [[368, 22, "D3"], [80, 9, 5, 4], [228, 17, 5, 2], [109, 11, 5, 3], 7.72],
      [[109, 11, 5, 3], [72, 9, 3, 1], [4, 1, 1], [53, 6, 5], 8.93],
      [[12, 1, 3], [123, 12, 3, 4], [76, 9, 2, 4], [103, 11, 1, 4], 9.09],
      [[50, 6, 2], [103, 11, 1, 4], [368, 22, "D3"], [27, 2, 3], 9.3],
      [[321, 21, 0, 1], [60, 8, 0], [4, 1, 1], [103, 11, 1, 4], 9.4],
      [[51, 6, 3], [358, 22, "B3"], [12, 1, 3], [139, 13, 5, 3], 9.45],
      [[161, 15, 2, 1], [347, 21, 5, 1], [90, 10, 2, 3], [50, 6, 2], 10.25],
      [[103, 11, 1, 4], [343, 21, 4, 2], [28, 2, 4], [245, 18, 2, 5], 10.76],
      [[88, 10, 1, 4], [60, 8, 0], [183, 16, 2, 3], [314, 20, 4, 3], 10.79],
      [[62, 8, 2], [354, 22, "A4"], [25, 2, 1], [49, 6, 1], 10.8],
      [[62, 8, 2], [323, 21, 0, 3], [18, 1, 4], [41, 4, 5], 11.67]
    ],
    [
      [[267, 19, 1, 2], [13, 1, 3], [101, 11, 2, 1], [359, 22, "B4"], 6.09],
      [[101, 11, 2, 1], [47, 5, 5], [213, 17, 2, 3], [1, 1, 0], 6.17],
      [[101, 11, 2, 1], [1, 1, 0], [156, 15, 0, 1], [55, 7, 1], 6.54],
      [[18, 1, 4], [358, 22, "B3"], [55, 7, 1], [125, 12, 4, 5], 7.25],
      [[27, 2, 3], [72, 9, 1, 3], [101, 11, 2, 1], [322, 21, 0, 2], 7.38],
      [[13, 1, 3], [308, 20, 3, 2], [154, 14, 3, 5], [101, 11, 2, 1], 7.4],
      [[181, 16, 2, 0], [160, 15, 0, 5], [101, 11, 2, 1], [13, 1, 3], 7.5],
      [[261, 19, 0, 1], [63, 8, 3], [139, 13, 3, 5], [101, 11, 2, 1], 7.93],
      [[362, 22, "C2"], [193, 16, 4, 2], [70, 9, 0, 5], [125, 12, 4, 5], 8.23],
      [[73, 9, 4, 1], [18, 1, 4], [60, 8, 0], [359, 22, "B4"], 8.63],
      [[155, 14, 4, 5], [64, 8, 4], [241, 18, 2, 0], [9, 1, 2], 9.04],
      [[47, 5, 5], [64, 8, 4], [316, 20, 5, 0], [201, 17, 0, 1], 10.41]
    ]
  ],
  solutionsPieces: [
    [
      [1, 1, 1, 5, 5],
      [1, -1, 5, 5, 3],
      [-1, 2, 2, 3, 3],
      [-1, 2, 2, 4, 3],
      [0, 0, 0, 4, 4]
    ],
    [
      [-1, 0, 1, 1, 1],
      [-1, 0, 1, 2, 2],
      [5, 0, 3, 2, 2],
      [5, 5, 3, 3, 4],
      [-1, 5, 3, 4, 4]
    ],
    [
      [5, -1, 3, 3, 3],
      [5, 5, 0, 3, 4],
      [1, 5, 0, 4, 4],
      [1, -1, 0, 2, 2],
      [1, 1, -1, 2, 2]
    ],
    [
      [-1, 5, 5, -1, 1],
      [5, 5, 1, 1, 1],
      [3, 4, 4, 2, 2],
      [3, 3, 4, 2, 2],
      [3, -1, 0, 0, 0]
    ],
    [
      [-1, 5, 5, 2, 2],
      [5, 5, -1, 2, 2],
      [3, 4, 4, 1, 1],
      [3, 3, 4, -1, 1],
      [3, 0, 0, 0, 1]
    ],
    [
      [0, 0, 0, -1, 4],
      [1, 2, 2, 4, 4],
      [1, 2, 2, 5, -1],
      [1, 1, 3, 5, 5],
      [-1, 3, 3, 3, 5]
    ],
    [
      [3, 3, 3, 2, 2],
      [5, 3, 0, 2, 2],
      [5, 5, 0, 4, 4],
      [-1, 5, 0, 1, 4],
      [-1, 1, 1, 1, -1]
    ],
    [
      [-1, 5, -1, 1, 1],
      [3, 5, 5, 0, 1],
      [3, 3, 5, 0, 1],
      [3, 2, 2, 0, 4],
      [-1, 2, 2, 4, 4]
    ],
    [
      [2, 2, 4, 4, -1],
      [2, 2, 4, 5, -1],
      [0, 0, 0, 5, 5],
      [1, 1, 1, 3, 5],
      [1, -1, 3, 3, 3]
    ],
    [
      [3, 3, 3, 5, 5],
      [-1, 3, 5, 5, -1],
      [4, 0, 0, 0, -1],
      [4, 4, 1, 2, 2],
      [1, 1, 1, 2, 2]
    ],
    [
      [-1, 3, 3, 3, -1],
      [4, 4, 3, 1, 1],
      [5, 4, 2, 2, 1],
      [5, 5, 2, 2, 1],
      [-1, 5, 0, 0, 0]
    ],
    [
      [1, 1, 1, 5, 5],
      [1, -1, 5, 5, 0],
      [-1, 3, 3, 3, 0],
      [2, 2, 3, 4, 0],
      [2, 2, 4, 4, -1]
    ],
    [
      [2, 2, -1, -1, 0],
      [2, 2, 4, 4, 0],
      [1, 1, 1, 4, 0],
      [1, 5, 5, 3, -1],
      [5, 5, 3, 3, 3]
    ],
    [
      [0, 0, 0, -1, 3],
      [2, 2, 5, 3, 3],
      [2, 2, 5, 5, 3],
      [-1, 4, 4, 5, 1],
      [-1, 4, 1, 1, 1]
    ],
    [
      [5, 4, 4, 2, 2],
      [5, 5, 4, 2, 2],
      [1, 5, 0, 0, 0],
      [1, -1, -1, 3, -1],
      [1, 1, 3, 3, 3]
    ],
    [
      [-1, 2, 2, 4, 4],
      [-1, 2, 2, 4, 3],
      [1, 1, 1, 3, 3],
      [1, 5, 5, -1, 3],
      [5, 5, 0, 0, 0]
    ],
    [
      [0, 0, 0, 2, 2],
      [-1, -1, 1, 2, 2],
      [1, 1, 1, 5, 5],
      [-1, 3, 5, 5, 4],
      [3, 3, 3, 4, 4]
    ],
    [
      [-1, 4, -1, 1, 1],
      [4, 4, 2, 2, 1],
      [0, 3, 2, 2, 1],
      [0, 3, 3, 5, 5],
      [0, 3, 5, 5, -1]
    ],
    [
      [4, -1, 2, 2, -1],
      [4, 4, 2, 2, 3],
      [0, 1, 5, 3, 3],
      [0, 1, 5, 5, 3],
      [0, 1, 1, 5, -1]
    ],
    [
      [0, 0, 0, 5, -1],
      [3, 1, 1, 5, 5],
      [3, 3, 1, -1, 5],
      [3, 4, 1, 2, 2],
      [4, 4, -1, 2, 2]
    ],
    [
      [-1, 5, 5, 4, 4],
      [5, 5, -1, 1, 4],
      [0, 1, 1, 1, 3],
      [0, 2, 2, 3, 3],
      [0, 2, 2, -1, 3]
    ],
    [
      [1, 1, 1, 5, -1],
      [1, -1, 3, 5, 5],
      [2, 2, 3, 3, 5],
      [2, 2, 3, 4, -1],
      [0, 0, 0, 4, 4]
    ],
    [
      [2, 2, 0, 0, 0],
      [2, 2, -1, 4, 4],
      [-1, 5, 5, 3, 4],
      [5, 5, 1, 3, 3],
      [1, 1, 1, 3, -1]
    ],
    [
      [1, 1, -1, 2, 2],
      [0, 1, -1, 2, 2],
      [0, 1, 5, 4, 4],
      [0, 3, 5, 5, 4],
      [3, 3, 3, 5, -1]
    ],
    [
      [3, -1, 1, 1, 1],
      [3, 3, 1, 5, 5],
      [3, -1, 5, 5, 0],
      [4, -1, 2, 2, 0],
      [4, 4, 2, 2, 0]
    ],
    [
      [3, 3, 3, 5, 5],
      [-1, 3, 5, 5, 0],
      [2, 2, -1, 1, 0],
      [2, 2, 4, 1, 0],
      [-1, 4, 4, 1, 1]
    ],
    [
      [2, 2, 3, 3, 3],
      [2, 2, 4, 3, -1],
      [5, 4, 4, -1, 1],
      [5, 5, 1, 1, 1],
      [-1, 5, 0, 0, 0]
    ],
    [
      [0, 0, 0, -1, 3],
      [-1, 4, 5, 3, 3],
      [4, 4, 5, 5, 3],
      [2, 2, -1, 5, 1],
      [2, 2, 1, 1, 1]
    ],
    [
      [0, 0, 0, 1, 1],
      [4, 4, 2, 2, 1],
      [5, 4, 2, 2, 1],
      [5, 5, 3, 3, 3],
      [-1, 5, -1, 3, -1]
    ],
    [
      [4, -1, 0, 0, 0],
      [4, 4, -1, 5, -1],
      [1, 2, 2, 5, 5],
      [1, 2, 2, 3, 5],
      [1, 1, 3, 3, 3]
    ],
    [
      [0, 1, -1, 5, 5],
      [0, 1, 5, 5, 3],
      [0, 1, 1, 3, 3],
      [2, 2, -1, 4, 3],
      [2, 2, 4, 4, -1]
    ],
    [
      [4, 4, 5, 5, -1],
      [4, 5, 5, 2, 2],
      [0, 0, 0, 2, 2],
      [3, 3, 3, -1, 1],
      [-1, 3, 1, 1, 1]
    ],
    [
      [3, 3, 3, 1, 1],
      [0, 3, -1, 4, 1],
      [0, 5, 4, 4, 1],
      [0, 5, 5, 2, 2],
      [-1, -1, 5, 2, 2]
    ],
    [
      [2, 2, -1, 5, 5],
      [2, 2, 5, 5, 0],
      [-1, 3, 3, 3, 0],
      [4, 4, 3, 1, 0],
      [4, 1, 1, 1, -1]
    ],
    [
      [1, -1, 4, 4, 3],
      [1, -1, 4, 3, 3],
      [1, 1, 0, 5, 3],
      [2, 2, 0, 5, 5],
      [2, 2, 0, -1, 5]
    ],
    [
      [-1, 5, 5, -1, 0],
      [5, 5, 4, 4, 0],
      [3, 1, 1, 4, 0],
      [3, 3, 1, 2, 2],
      [3, -1, 1, 2, 2]
    ],
    [
      [3, 1, 1, 2, 2],
      [3, 3, 1, 2, 2],
      [3, -1, 1, -1, 4],
      [-1, 5, 5, 4, 4],
      [5, 5, 0, 0, 0]
    ],
    [
      [2, 2, 3, 3, 3],
      [2, 2, -1, 3, 1],
      [0, -1, 1, 1, 1],
      [0, -1, 5, 5, 4],
      [0, 5, 5, 4, 4]
    ]
  ],
  solutionsIndices: [
    [
      [0, 0, 1, 0, 1],
      [0, 0, 0, 0, 1],
      [0, 1, 0, 2, 0],
      [0, 2, 0, 2, 0],
      [0, 0, 2, 0, 0]
    ],
    [
      [0, 2, 0, 0, 1],
      [0, 0, 0, 0, 2],
      [0, 0, 0, 0, 1],
      [0, 0, 0, 2, 0],
      [0, 1, 1, 2, 0]
    ],
    [
      [1, 0, 1, 0, 0],
      [0, 0, 0, 2, 0],
      [1, 0, 0, 2, 0],
      [0, 0, 2, 0, 2],
      [0, 0, 0, 0, 1]
    ],
    [
      [0, 0, 0, 0, 0],
      [1, 0, 1, 0, 0],
      [0, 0, 0, 1, 0],
      [0, 2, 2, 2, 0],
      [1, 0, 2, 0, 0]
    ],
    [
      [0, 0, 1, 0, 2],
      [0, 0, 0, 0, 1],
      [0, 0, 0, 0, 0],
      [0, 2, 2, 0, 0],
      [1, 0, 0, 2, 1]
    ],
    [
      [0, 0, 2, 0, 0],
      [1, 1, 0, 2, 0],
      [0, 2, 0, 1, 0],
      [0, 0, 2, 0, 0],
      [0, 0, 0, 1, 0]
    ],
    [
      [1, 0, 0, 0, 2],
      [0, 2, 0, 0, 1],
      [0, 0, 0, 0, 0],
      [0, 1, 2, 0, 2],
      [0, 1, 0, 0, 0]
    ],
    [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 2, 0],
      [0, 2, 1, 0, 1],
      [1, 0, 2, 0, 0],
      [0, 0, 1, 2, 0]
    ],
    [
      [0, 0, 0, 2, 0],
      [1, 2, 0, 1, 0],
      [0, 0, 2, 0, 0],
      [0, 0, 1, 2, 0],
      [0, 0, 0, 0, 1]
    ],
    [
      [1, 0, 0, 0, 0],
      [0, 2, 1, 0, 0],
      [2, 0, 0, 2, 0],
      [0, 0, 0, 1, 0],
      [1, 0, 0, 2, 0]
    ],
    [
      [0, 1, 0, 0, 0],
      [0, 0, 2, 0, 0],
      [1, 2, 0, 0, 0],
      [0, 0, 1, 2, 1],
      [0, 0, 0, 0, 2]
    ],
    [
      [0, 0, 1, 0, 1],
      [0, 0, 0, 0, 2],
      [0, 1, 0, 0, 0],
      [0, 2, 2, 0, 0],
      [0, 1, 2, 0, 0]
    ],
    [
      [1, 0, 0, 0, 2],
      [2, 0, 0, 0, 0],
      [0, 0, 1, 2, 0],
      [0, 0, 0, 2, 0],
      [1, 0, 0, 0, 1]
    ],
    [
      [2, 0, 0, 0, 1],
      [0, 2, 1, 2, 0],
      [0, 1, 0, 0, 0],
      [0, 0, 2, 0, 0],
      [0, 0, 1, 0, 0]
    ],
    [
      [1, 0, 0, 1, 0],
      [0, 0, 2, 2, 0],
      [1, 0, 0, 0, 2],
      [0, 0, 0, 2, 0],
      [0, 0, 0, 0, 1]
    ],
    [
      [0, 1, 0, 0, 2],
      [0, 2, 0, 0, 1],
      [0, 0, 1, 2, 0],
      [0, 0, 0, 0, 0],
      [1, 0, 0, 0, 2]
    ],
    [
      [0, 0, 2, 0, 2],
      [0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0],
      [0, 2, 1, 0, 0],
      [0, 0, 1, 2, 0]
    ],
    [
      [0, 0, 0, 0, 0],
      [2, 0, 2, 1, 0],
      [0, 0, 0, 0, 1],
      [0, 0, 2, 0, 1],
      [2, 1, 0, 0, 0]
    ],
    [
      [2, 0, 1, 0, 0],
      [0, 0, 2, 0, 1],
      [0, 1, 1, 2, 0],
      [0, 0, 0, 0, 0],
      [2, 0, 0, 0, 0]
    ],
    [
      [2, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 2, 0, 0, 1],
      [1, 0, 1, 0, 2],
      [2, 0, 0, 0, 1]
    ],
    [
      [0, 0, 0, 0, 0],
      [1, 0, 0, 0, 2],
      [0, 1, 0, 0, 1],
      [0, 0, 2, 2, 0],
      [2, 0, 1, 0, 0]
    ],
    [
      [0, 0, 1, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 2, 0, 2, 1],
      [0, 1, 1, 2, 0],
      [2, 0, 0, 0, 0]
    ],
    [
      [0, 2, 0, 0, 2],
      [0, 1, 0, 0, 0],
      [0, 0, 0, 0, 2],
      [1, 0, 0, 0, 2],
      [1, 0, 0, 1, 0]
    ],
    [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 1, 2],
      [0, 1, 1, 0, 0],
      [2, 2, 0, 0, 2],
      [0, 0, 1, 0, 0]
    ],
    [
      [0, 0, 0, 0, 1],
      [0, 2, 0, 0, 1],
      [1, 0, 0, 0, 2],
      [2, 0, 0, 0, 0],
      [0, 0, 1, 2, 0]
    ],
    [
      [1, 0, 0, 0, 0],
      [0, 2, 1, 0, 2],
      [1, 0, 0, 1, 0],
      [2, 0, 0, 0, 0],
      [0, 2, 0, 0, 0]
    ],
    [
      [0, 0, 1, 0, 0],
      [1, 2, 0, 2, 0],
      [0, 2, 0, 0, 0],
      [0, 0, 1, 0, 0],
      [0, 1, 2, 0, 0]
    ],
    [
      [0, 0, 2, 0, 1],
      [0, 0, 0, 2, 0],
      [2, 0, 0, 0, 0],
      [0, 2, 0, 1, 0],
      [0, 1, 1, 0, 0]
    ],
    [
      [2, 0, 0, 0, 0],
      [0, 0, 2, 1, 0],
      [0, 2, 0, 0, 1],
      [0, 0, 1, 0, 0],
      [0, 1, 0, 2, 0]
    ],
    [
      [2, 0, 0, 0, 2],
      [0, 0, 0, 0, 0],
      [1, 0, 2, 0, 0],
      [0, 0, 1, 2, 1],
      [0, 0, 0, 0, 1]
    ],
    [
      [2, 1, 0, 0, 0],
      [0, 0, 1, 0, 1],
      [0, 0, 0, 2, 0],
      [1, 0, 0, 0, 0],
      [2, 0, 2, 0, 0]
    ],
    [
      [0, 2, 0, 1, 0],
      [0, 0, 0, 0, 2],
      [2, 0, 0, 0, 1],
      [1, 0, 0, 0, 0],
      [0, 2, 1, 0, 0]
    ],
    [
      [1, 0, 0, 0, 0],
      [0, 2, 0, 0, 0],
      [0, 1, 2, 0, 1],
      [2, 0, 0, 1, 0],
      [0, 0, 0, 2, 0]
    ],
    [
      [1, 0, 0, 0, 0],
      [2, 0, 1, 0, 2],
      [0, 1, 0, 0, 0],
      [0, 2, 2, 0, 0],
      [0, 1, 0, 0, 0]
    ],
    [
      [1, 0, 0, 2, 1],
      [0, 0, 0, 2, 0],
      [0, 0, 2, 0, 0],
      [0, 0, 0, 0, 0],
      [1, 2, 0, 0, 1]
    ],
    [
      [0, 0, 0, 0, 2],
      [1, 0, 0, 0, 0],
      [0, 0, 0, 2, 0],
      [0, 2, 0, 0, 2],
      [1, 0, 1, 0, 1]
    ],
    [
      [0, 0, 0, 2, 1],
      [0, 2, 0, 0, 0],
      [1, 0, 1, 0, 0],
      [0, 0, 0, 2, 0],
      [1, 0, 2, 0, 0]
    ],
    [
      [0, 0, 1, 0, 0],
      [1, 2, 0, 2, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 0, 1, 0],
      [2, 0, 0, 2, 0]
    ]
  ],
  dailyChallenges: [
    [[[101, 11, 1, 2], [87, 10, 1, 3], [41, 4, 5], [353, 22, "A3"], 6.05], 0],
    [[[61, 8, 1], [29, 2, 5], [283, 19, 4, 2], [352, 22, "A2"], 7.55], 1],
    [[[206, 17, 1, 0], [335, 21, 2, 5], [38, 4, 2], [13, 1, 3], 6.29], 2],
    [[[5, 1, 1], [158, 15, 3, 0], [46, 5, 4], [34, 3, 4], 6.4], 3],
    [[[53, 6, 5], [71, 9, 1, 2], [106, 11, 4, 2], [154, 14, 5, 3], 7.27], 4],
    [[[6, 1, 1], [99, 11, 4, 0], [318, 20, 5, 2], [80, 9, 5, 4], 6.69], 5],
    [[[22, 1, 5], [136, 13, 2, 4], [375, 22, "E5"], [303, 20, 2, 3], 6.35], 6],
    [[[342, 21, 4, 1], [61, 8, 1], [104, 11, 5, 1], [8, 1, 2], 4.71], 7],
    [
      [[67, 9, 0, 2], [329, 21, 1, 4], [252, 18, 4, 1], [115, 12, 5, 0], 7.26],
      8
    ],
    [[[263, 19, 0, 3], [61, 8, 1], [108, 11, 4, 3], [114, 12, 4, 0], 6.57], 9],
    [[[84, 10, 4, 0], [305, 20, 2, 5], [4, 1, 1], [32, 3, 2], 6.89], 10],
    [[[102, 11, 3, 1], [54, 7, 0], [357, 22, "B2"], [79, 9, 3, 5], 6.27], 11],
    [[[82, 10, 2, 0], [3, 1, 0], [34, 3, 4], [62, 8, 2], 7.76], 12],
    [[[328, 21, 1, 3], [48, 6, 0], [24, 2, 0], [2, 1, 0], 8.26], 13],
    [[[80, 9, 5, 4], [336, 21, 3, 0], [54, 7, 0], [195, 16, 4, 5], 7.02], 14],
    [[[149, 14, 1, 5], [76, 9, 4, 2], [28, 2, 4], [324, 21, 0, 4], 10.17], 15],
    [[[302, 20, 2, 1], [119, 12, 1, 5], [24, 2, 0], [210, 17, 1, 5], 8.83], 16],
    [[[18, 1, 4], [361, 22, "C1"], [88, 10, 4, 1], [32, 3, 2], 5.03], 17],
    [[[75, 9, 3, 2], [309, 20, 3, 4], [24, 2, 0], [60, 8, 0], 8.67], 18],
    [[[217, 17, 3, 1], [62, 8, 2], [74, 9, 5, 1], [365, 22, "C5"], 7.78], 19],
    [[[64, 8, 4], [92, 10, 5, 2], [44, 5, 2], [31, 3, 1], 7.3], 20],
    [[[127, 13, 2, 0], [61, 8, 1], [33, 3, 3], [69, 9, 4, 0], 8.36], 21],
    [
      [[226, 17, 5, 0], [153, 14, 4, 3], [362, 22, "C2"], [110, 11, 5, 4], 6.6],
      22
    ],
    [[[9, 1, 2], [160, 15, 5, 0], [58, 7, 4], [84, 10, 4, 0], 6.93], 23],
    [[[199, 16, 5, 3], [7, 1, 1], [52, 6, 4], [163, 15, 4, 1], 8.9], 24],
    [[[63, 8, 3], [363, 22, "C3"], [26, 2, 2], [3, 1, 0], 6.74], 25],
    [[[195, 16, 4, 5], [62, 8, 2], [102, 11, 1, 3], [5, 1, 1], 7.16], 26],
    [[[70, 9, 0, 5], [49, 6, 1], [113, 12, 0, 3], [107, 11, 2, 5], 7.12], 27],
    [[[7, 1, 1], [205, 17, 0, 5], [101, 11, 1, 2], [83, 10, 0, 3], 6.16], 0],
    [[[61, 8, 1], [95, 10, 5, 4], [7, 1, 1], [311, 20, 4, 0], 6.72], 1],
    [[[249, 18, 3, 4], [335, 21, 2, 5], [356, 22, "B1"], [25, 2, 1], 7.47], 2],
    [[[98, 11, 3, 0], [27, 2, 3], [46, 5, 4], [73, 9, 1, 4], 5.82], 3],
    [[[362, 22, "C2"], [106, 11, 4, 2], [29, 2, 5], [62, 8, 2], 8.13], 4],
    [[[128, 13, 3, 0], [56, 7, 2], [373, 22, "E3"], [338, 21, 3, 2], 7.03], 5],
    [[[120, 12, 2, 3], [30, 3, 0], [17, 1, 4], [192, 16, 4, 1], 4.21], 6],
    [[[61, 8, 1], [30, 3, 0], [292, 20, 0, 2], [52, 6, 4], 6.27], 7],
    [
      [[316, 20, 5, 0], [115, 12, 5, 0], [98, 11, 0, 3], [89, 10, 5, 1], 6.02],
      8
    ],
    [[[171, 16, 0, 1], [118, 12, 4, 1], [30, 3, 0], [345, 21, 4, 5], 7.34], 9],
    [
      [[94, 10, 5, 3], [351, 22, "A1"], [305, 20, 2, 5], [117, 12, 3, 1], 7.09],
      10
    ],
    [[[8, 1, 2], [131, 13, 2, 1], [200, 16, 5, 4], [102, 11, 3, 1], 6.64], 11],
    [[[34, 3, 4], [346, 21, 5, 0], [23, 1, 5], [147, 14, 3, 1], 7.16], 12],
    [[[98, 11, 3, 0], [35, 3, 5], [341, 21, 4, 0], [90, 10, 3, 2], 6.44], 13],
    [[[364, 22, "C4"], [80, 9, 5, 4], [53, 6, 5], [162, 15, 3, 1], 7.52], 14],
    [[[10, 1, 2], [313, 20, 4, 2], [60, 8, 0], [84, 10, 4, 0], 8.64], 15],
    [[[199, 16, 5, 3], [0, 1, 0], [357, 22, "B2"], [91, 10, 2, 4], 7.34], 16],
    [[[279, 19, 3, 4], [61, 8, 1], [69, 9, 0, 4], [298, 20, 1, 3], 6.84], 17],
    [[[14, 1, 3], [27, 2, 3], [131, 13, 1, 2], [60, 8, 0], 8.86], 18],
    [[[12, 1, 3], [26, 2, 2], [36, 4, 0], [302, 20, 2, 1], 8.51], 19],
    [[[31, 3, 1], [55, 7, 1], [201, 17, 0, 1], [80, 9, 5, 4], 6.32], 20],
    [[[57, 7, 3], [318, 20, 5, 2], [142, 14, 2, 0], [33, 3, 3], 7.48], 21],
    [[[110, 11, 5, 4], [206, 17, 1, 0], [61, 8, 1], [137, 13, 2, 5], 7.15], 22],
    [[[107, 11, 5, 2], [375, 22, "E5"], [61, 8, 1], [115, 12, 5, 0], 6.17], 23],
    [[[342, 21, 4, 1], [109, 11, 3, 5], [145, 14, 5, 0], [64, 8, 4], 7.4], 24],
    [[[27, 2, 3], [82, 10, 2, 0], [51, 6, 3], [172, 16, 0, 2], 9.9], 25],
    [[[164, 15, 5, 1], [102, 11, 1, 3], [368, 22, "D3"], [34, 3, 4], 5.57], 26],
    [[[333, 21, 2, 3], [35, 3, 5], [161, 15, 2, 1], [51, 6, 3], 7.79], 27],
    [[[61, 8, 1], [7, 1, 1], [57, 7, 3], [29, 2, 5], 8.5], 0],
    [[[29, 2, 5], [351, 22, "A1"], [164, 15, 5, 1], [49, 6, 1], 8.09], 1],
    [[[42, 5, 0], [365, 22, "C5"], [62, 8, 2], [301, 20, 2, 0], 6.36], 2],
    [[[92, 10, 5, 2], [360, 22, "B5"], [98, 11, 3, 0], [12, 1, 3], 5.0], 3],
    [[[183, 16, 2, 3], [106, 11, 4, 2], [61, 8, 1], [58, 7, 4], 7.78], 4],
    [[[130, 13, 5, 0], [373, 22, "E3"], [65, 8, 5], [0, 1, 0], 6.41], 5],
    [[[344, 21, 4, 3], [181, 16, 2, 0], [30, 3, 0], [39, 4, 3], 5.72], 6],
    [[[335, 21, 2, 5], [361, 22, "C1"], [64, 8, 4], [29, 2, 5], 7.83], 7],
    [[[72, 9, 1, 3], [62, 8, 2], [128, 13, 0, 3], [52, 6, 4], 8.63], 8],
    [
      [[108, 11, 4, 3], [320, 20, 5, 4], [73, 9, 4, 1], [154, 14, 5, 3], 6.32],
      9
    ],
    [[[25, 2, 1], [351, 22, "A1"], [135, 13, 3, 2], [0, 1, 0], 7.43], 10],
    [[[57, 7, 3], [345, 21, 4, 5], [65, 8, 5], [375, 22, "E5"], 6.98], 11],
    [[[308, 20, 3, 2], [98, 11, 3, 0], [23, 1, 5], [172, 16, 0, 2], 6.46], 12],
    [[[26, 2, 2], [59, 7, 5], [120, 12, 3, 2], [80, 9, 4, 5], 8.75], 13],
    [[[69, 9, 4, 0], [229, 17, 5, 3], [54, 7, 0], [20, 1, 5], 7.11], 14],
    [
      [[228, 17, 5, 2], [129, 13, 4, 0], [84, 10, 4, 0], [352, 22, "A2"], 7.25],
      15
    ],
    [[[357, 22, "B2"], [15, 1, 3], [71, 9, 2, 1], [155, 14, 4, 5], 7.24], 16],
    [[[320, 20, 5, 4], [87, 10, 1, 3], [32, 3, 2], [4, 1, 1], 5.99], 17],
    [[[64, 8, 4], [131, 13, 1, 2], [14, 1, 3], [371, 22, "E1"], 6.58], 18],
    [[[371, 22, "E1"], [302, 20, 2, 1], [78, 9, 4, 3], [12, 1, 3], 7.86], 19],
    [[[84, 10, 0, 4], [264, 19, 0, 4], [362, 22, "C2"], [1, 1, 0], 6.98], 20],
    [[[342, 21, 4, 1], [57, 7, 3], [8, 1, 2], [155, 14, 4, 5], 7.12], 21],
    [[[149, 14, 1, 5], [110, 11, 5, 4], [28, 2, 4], [327, 21, 1, 2], 7.1], 22],
    [[[261, 19, 0, 1], [118, 12, 4, 1], [40, 4, 4], [314, 20, 4, 3], 7.21], 23],
    [[[239, 18, 1, 4], [109, 11, 3, 5], [358, 22, "B3"], [64, 8, 4], 6.72], 24],
    [[[13, 1, 3], [293, 20, 0, 3], [149, 14, 5, 1], [260, 18, 5, 4], 8.6], 25],
    [[[29, 2, 5], [102, 11, 1, 3], [151, 14, 2, 4], [46, 5, 4], 7.18], 26],
    [[[107, 11, 2, 5], [341, 21, 4, 0], [35, 3, 5], [123, 12, 4, 3], 6.56], 27],
    [[[88, 10, 1, 4], [7, 1, 1], [353, 22, "A3"], [61, 8, 1], 7.77], 0],
    [[[84, 10, 0, 4], [352, 22, "A2"], [64, 8, 4], [3, 1, 0], 6.4], 1],
    [
      [[247, 18, 3, 1], [96, 11, 0, 1], [135, 13, 2, 3], [68, 9, 3, 0], 5.41],
      2
    ],
    [
      [[351, 22, "A1"], [140, 13, 5, 4], [179, 16, 1, 4], [98, 11, 3, 0], 5.76],
      3
    ],
    [
      [
        [279, 19, 3, 4],
        [143, 14, 0, 3],
        [106, 11, 4, 2],
        [369, 22, "D4"],
        5.96
      ],
      4
    ],
    [[[338, 21, 3, 2], [85, 10, 5, 0], [40, 4, 4], [27, 2, 3], 7.75], 5],
    [[[375, 22, "E5"], [136, 13, 2, 4], [63, 8, 3], [327, 21, 1, 2], 6.91], 6],
    [[[64, 8, 4], [39, 4, 3], [22, 1, 5], [104, 11, 5, 1], 5.98], 7],
    [[[59, 7, 5], [338, 21, 3, 2], [187, 16, 3, 1], [9, 1, 2], 6.1], 8],
    [[[372, 22, "E2"], [320, 20, 5, 4], [42, 5, 0], [171, 16, 0, 1], 6.18], 9],
    [[[294, 20, 0, 4], [84, 10, 4, 0], [60, 8, 0], [32, 3, 2], 7.26], 10],
    [[[21, 1, 5], [37, 4, 1], [65, 8, 5], [154, 14, 3, 5], 8.49], 11],
    [[[166, 15, 2, 4], [85, 10, 5, 0], [98, 11, 3, 0], [10, 1, 2], 6.54], 12],
    [[[86, 10, 2, 1], [98, 11, 3, 0], [355, 22, "A5"], [59, 7, 5], 6.29], 13],
    [[[374, 22, "E4"], [46, 5, 4], [304, 20, 2, 4], [111, 12, 1, 0], 7.81], 14],
    [[[50, 6, 2], [324, 21, 0, 4], [16, 1, 4], [60, 8, 0], 8.87], 15],
    [[[29, 2, 5], [83, 10, 0, 3], [15, 1, 3], [302, 20, 2, 1], 8.8], 16],
    [[[95, 10, 4, 5], [18, 1, 4], [60, 8, 0], [288, 19, 5, 2], 7.62], 17],
    [[[327, 21, 1, 2], [57, 7, 3], [101, 11, 1, 2], [24, 2, 0], 5.51], 18],
    [[[64, 8, 4], [161, 15, 2, 1], [26, 2, 2], [18, 1, 4], 7.71], 19],
    [[[26, 2, 2], [5, 1, 1], [311, 20, 4, 0], [351, 22, "A1"], 7.98], 20],
    [[[7, 1, 1], [36, 4, 0], [316, 20, 5, 0], [277, 19, 3, 1], 8.07], 21],
    [[[110, 11, 5, 4], [214, 17, 2, 4], [12, 1, 3], [310, 20, 3, 5], 6.71], 22],
    [[[4, 1, 1], [37, 4, 1], [88, 10, 4, 1], [107, 11, 5, 2], 6.78], 23],
    [[[342, 21, 4, 1], [50, 6, 2], [25, 2, 1], [109, 11, 3, 5], 7.83], 24],
    [[[25, 2, 1], [188, 16, 3, 2], [107, 11, 2, 5], [154, 14, 5, 3], 7.97], 25],
    [[[102, 11, 1, 3], [368, 22, "D3"], [139, 13, 5, 3], [62, 8, 2], 5.86], 26],
    [[[69, 9, 0, 4], [35, 3, 5], [47, 5, 5], [331, 21, 2, 0], 7.36], 27],
    [[[101, 11, 1, 2], [81, 10, 0, 1], [32, 3, 2], [332, 21, 2, 1], 5.58], 0],
    [[[48, 6, 0], [352, 22, "A2"], [92, 10, 5, 2], [64, 8, 4], 7.11], 1],
    [[[311, 20, 4, 0], [30, 3, 0], [96, 11, 0, 1], [229, 17, 5, 3], 5.82], 2],
    [[[12, 1, 3], [98, 11, 3, 0], [236, 18, 1, 0], [81, 10, 0, 1], 6.0], 3],
    [[[61, 8, 1], [142, 14, 0, 2], [12, 1, 3], [257, 18, 5, 1], 8.41], 4],
    [[[171, 16, 0, 1], [0, 1, 0], [99, 11, 4, 0], [85, 10, 5, 0], 5.34], 5],
    [[[22, 1, 5], [311, 20, 4, 0], [147, 14, 1, 3], [42, 5, 0], 6.62], 6],
    [[[335, 21, 2, 5], [61, 8, 1], [42, 5, 0], [284, 19, 4, 3], 6.74], 7],
    [[[324, 21, 0, 4], [0, 1, 0], [54, 7, 0], [61, 8, 1], 6.47], 8],
    [[[258, 18, 5, 2], [352, 22, "A2"], [10, 1, 2], [37, 4, 1], 7.45], 9],
    [[[45, 5, 3], [29, 2, 5], [349, 21, 5, 3], [284, 19, 4, 3], 6.57], 10],
    [[[150, 14, 3, 2], [291, 20, 0, 1], [45, 5, 3], [79, 9, 3, 5], 8.28], 11],
    [[[51, 6, 3], [87, 10, 3, 1], [214, 17, 2, 4], [15, 1, 3], 8.57], 12],
    [[[355, 22, "A5"], [14, 1, 3], [60, 8, 0], [98, 11, 3, 0], 6.48], 13],
    [[[24, 2, 0], [259, 18, 5, 3], [68, 9, 3, 0], [65, 8, 5], 8.75], 14],
    [[[129, 13, 4, 0], [103, 11, 4, 1], [76, 9, 4, 2], [10, 1, 2], 6.32], 15],
    [[[88, 10, 1, 4], [163, 15, 1, 4], [312, 20, 4, 1], [62, 8, 2], 9.64], 16],
    [[[306, 20, 3, 0], [56, 7, 2], [87, 10, 1, 3], [224, 17, 4, 3], 7.3], 17],
    [[[87, 10, 1, 3], [356, 22, "B1"], [309, 20, 3, 4], [10, 1, 2], 5.2], 18],
    [[[31, 3, 1], [4, 1, 1], [82, 10, 0, 2], [144, 14, 0, 4], 6.94], 19],
    [[[157, 15, 0, 2], [63, 8, 3], [252, 18, 4, 1], [1, 1, 0], 8.59], 20],
    [[[64, 8, 4], [263, 19, 0, 3], [67, 9, 2, 0], [345, 21, 4, 5], 8.51], 21],
    [
      [[79, 9, 5, 3], [375, 22, "E5"], [110, 11, 5, 4], [338, 21, 3, 2], 6.48],
      22
    ],
    [[[1, 1, 0], [63, 8, 3], [107, 11, 5, 2], [362, 22, "C2"], 6.25], 23],
    [[[50, 6, 2], [64, 8, 4], [109, 11, 3, 5], [153, 14, 4, 3], 7.91], 24],
    [[[63, 8, 3], [76, 9, 2, 4], [121, 12, 2, 4], [46, 5, 4], 8.97], 25],
    [[[131, 13, 2, 1], [34, 3, 4], [70, 9, 5, 0], [368, 22, "D3"], 6.3], 26],
    [[[364, 22, "C4"], [5, 1, 1], [69, 9, 0, 4], [98, 11, 0, 3], 6.67], 27],
    [[[354, 22, "A4"], [284, 19, 4, 3], [44, 5, 2], [10, 1, 2], 6.21], 0],
    [[[64, 8, 4], [311, 20, 4, 0], [42, 5, 0], [351, 22, "A1"], 6.53], 1],
    [[[204, 17, 0, 4], [96, 11, 0, 1], [356, 22, "B1"], [50, 6, 2], 6.07], 2],
    [
      [[140, 13, 5, 4], [98, 11, 3, 0], [293, 20, 0, 3], [67, 9, 2, 0], 6.06],
      3
    ],
    [[[369, 22, "D4"], [345, 21, 4, 5], [58, 7, 4], [143, 14, 0, 3], 7.37], 4],
    [[[72, 9, 1, 3], [355, 22, "A5"], [60, 8, 0], [32, 3, 2], 6.98], 5],
    [[[66, 9, 1, 0], [8, 1, 2], [29, 2, 5], [355, 22, "A5"], 6.53], 6],
    [[[29, 2, 5], [361, 22, "C1"], [168, 15, 3, 4], [86, 10, 2, 1], 6.72], 7],
    [
      [[141, 14, 1, 0], [372, 22, "E2"], [98, 11, 0, 3], [313, 20, 4, 2], 5.82],
      8
    ],
    [[[108, 11, 4, 3], [372, 22, "E2"], [42, 5, 0], [10, 1, 2], 4.38], 9],
    [[[321, 21, 0, 1], [355, 22, "A5"], [44, 5, 2], [24, 2, 0], 7.85], 10],
    [[[69, 9, 0, 4], [33, 3, 3], [357, 22, "B2"], [173, 16, 0, 3], 6.62], 11],
    [[[211, 17, 2, 0], [24, 2, 0], [85, 10, 5, 0], [62, 8, 2], 9.5], 12],
    [[[98, 11, 3, 0], [35, 3, 5], [93, 10, 3, 4], [244, 18, 2, 4], 6.6], 13],
    [[[15, 1, 3], [65, 8, 5], [324, 21, 0, 4], [192, 16, 4, 1], 7.42], 14],
    [[[23, 1, 5], [64, 8, 4], [228, 17, 5, 2], [81, 10, 0, 1], 8.15], 15],
    [[[18, 1, 4], [55, 7, 1], [243, 18, 2, 3], [301, 20, 2, 0], 7.06], 16],
    [[[32, 3, 2], [361, 22, "C1"], [12, 1, 3], [118, 12, 4, 1], 5.78], 17],
    [[[121, 12, 2, 4], [375, 22, "E5"], [60, 8, 0], [39, 4, 3], 7.72], 18],
    [[[4, 1, 1], [129, 13, 0, 4], [371, 22, "E1"], [62, 8, 2], 7.4], 19],
    [
      [[236, 18, 1, 0], [335, 21, 2, 5], [169, 15, 3, 5], [92, 10, 5, 2], 7.59],
      20
    ],
    [[[357, 22, "B2"], [215, 17, 2, 5], [8, 1, 2], [81, 10, 1, 0], 5.99], 21],
    [[[26, 2, 2], [145, 14, 5, 0], [83, 10, 0, 3], [195, 16, 4, 5], 9.04], 22],
    [[[107, 11, 5, 2], [143, 14, 0, 3], [204, 17, 0, 4], [61, 8, 1], 6.65], 23],
    [[[63, 8, 3], [356, 22, "B1"], [234, 18, 0, 4], [113, 12, 3, 0], 8.57], 24],
    [[[95, 10, 5, 4], [222, 17, 4, 1], [63, 8, 3], [149, 14, 5, 1], 8.91], 25],
    [[[82, 10, 2, 0], [102, 11, 1, 3], [368, 22, "D3"], [2, 1, 0], 5.84], 26],
    [[[28, 2, 4], [98, 11, 0, 3], [47, 5, 5], [62, 8, 2], 7.91], 27],
    [[[86, 10, 1, 2], [0, 1, 0], [133, 13, 1, 4], [306, 20, 3, 0], 5.85], 0],
    [[[38, 4, 2], [170, 15, 5, 4], [22, 1, 5], [355, 22, "A5"], 6.61], 1],
    [[[365, 22, "C5"], [42, 5, 0], [28, 2, 4], [160, 15, 0, 5], 6.78], 2],
    [[[25, 2, 1], [81, 10, 0, 1], [61, 8, 1], [293, 20, 0, 3], 6.89], 3],
    [[[141, 14, 0, 1], [21, 1, 5], [106, 11, 4, 2], [369, 22, "D4"], 3.55], 4],
    [[[366, 22, "D1"], [326, 21, 1, 0], [56, 7, 2], [99, 11, 4, 0], 6.87], 5],
    [[[252, 18, 4, 1], [17, 1, 4], [62, 8, 2], [355, 22, "A5"], 5.82], 6],
    [[[361, 22, "C1"], [12, 1, 3], [226, 17, 5, 0], [64, 8, 4], 6.89], 7],
    [[[98, 11, 0, 3], [122, 12, 5, 2], [360, 22, "B5"], [16, 1, 4], 6.64], 8],
    [[[225, 17, 4, 5], [304, 20, 2, 4], [23, 1, 5], [161, 15, 2, 1], 7.25], 9],
    [[[60, 8, 0], [295, 20, 0, 5], [287, 19, 5, 1], [89, 10, 5, 1], 7.9], 10],
    [[[7, 1, 1], [29, 2, 5], [357, 22, "B2"], [193, 16, 4, 2], 7.68], 11],
    [
      [[252, 18, 4, 1], [121, 12, 2, 4], [71, 9, 2, 1], [294, 20, 0, 4], 8.64],
      12
    ],
    [[[131, 13, 2, 1], [20, 1, 5], [271, 19, 2, 0], [63, 8, 3], 7.42], 13],
    [[[15, 1, 3], [88, 10, 1, 4], [142, 14, 2, 0], [374, 22, "E4"], 7.76], 14],
    [[[72, 9, 3, 1], [123, 12, 3, 4], [48, 6, 0], [351, 22, "A1"], 9.01], 15],
    [[[18, 1, 4], [145, 14, 0, 5], [88, 10, 1, 4], [29, 2, 5], 10.34], 16],
    [[[351, 22, "A1"], [45, 5, 3], [18, 1, 4], [115, 12, 0, 5], 7.52], 17],
    [[[43, 5, 1], [110, 11, 5, 4], [85, 10, 5, 0], [187, 16, 3, 1], 6.83], 18],
    [[[317, 20, 5, 1], [12, 1, 3], [60, 8, 0], [118, 12, 4, 1], 8.34], 19],
    [[[370, 22, "D5"], [72, 9, 3, 1], [64, 8, 4], [214, 17, 2, 4], 7.85], 20],
    [[[69, 9, 4, 0], [110, 11, 4, 5], [33, 3, 3], [64, 8, 4], 6.38], 21],
    [[[313, 20, 4, 2], [38, 4, 2], [23, 1, 5], [375, 22, "E5"], 7.38], 22],
    [
      [[107, 11, 5, 2], [338, 21, 3, 2], [375, 22, "E5"], [79, 9, 5, 3], 6.03],
      23
    ],
    [[[21, 1, 5], [184, 16, 2, 4], [109, 11, 3, 5], [50, 6, 2], 6.66], 24],
    [[[84, 10, 4, 0], [18, 1, 4], [352, 22, "A2"], [141, 14, 1, 0], 5.92], 25],
    [[[102, 11, 1, 3], [53, 6, 5], [5, 1, 1], [83, 10, 0, 3], 7.43], 26],
    [[[224, 17, 4, 3], [47, 5, 5], [98, 11, 0, 3], [93, 10, 4, 3], 7.63], 27],
    [
      [[325, 21, 0, 5], [284, 19, 4, 3], [353, 22, "A3"], [81, 10, 0, 1], 7.35],
      0
    ],
    [[[342, 21, 4, 1], [150, 14, 3, 2], [42, 5, 0], [352, 22, "A2"], 6.15], 1],
    [[[26, 2, 2], [204, 17, 0, 4], [86, 10, 2, 1], [359, 22, "B4"], 6.36], 2],
    [[[246, 18, 3, 0], [98, 11, 3, 0], [366, 22, "D1"], [46, 5, 4], 4.9], 3],
    [[[42, 5, 0], [71, 9, 1, 2], [0, 1, 0], [185, 16, 2, 5], 7.71], 4],
    [
      [[373, 22, "E3"], [163, 15, 1, 4], [99, 11, 4, 0], [67, 9, 2, 0], 5.75],
      5
    ],
    [[[63, 8, 3], [301, 20, 2, 0], [92, 10, 2, 5], [132, 13, 1, 3], 6.91], 6],
    [[[284, 19, 4, 3], [104, 11, 5, 1], [30, 3, 0], [342, 21, 4, 1], 4.6], 7],
    [
      [
        [146, 14, 1, 2],
        [209, 17, 1, 4],
        [360, 22, "B5"],
        [338, 21, 3, 2],
        6.54
      ],
      8
    ],
    [[[40, 4, 4], [182, 16, 2, 1], [5, 1, 1], [304, 20, 2, 4], 7.78], 9],
    [[[371, 22, "E1"], [218, 17, 3, 2], [27, 2, 3], [300, 20, 1, 5], 8.1], 10],
    [[[293, 20, 0, 3], [277, 19, 3, 1], [357, 22, "B2"], [61, 8, 1], 7.47], 11],
    [[[374, 22, "E4"], [121, 12, 2, 4], [55, 7, 1], [102, 11, 3, 1], 6.57], 12],
    [[[98, 11, 3, 0], [26, 2, 2], [297, 20, 1, 2], [20, 1, 5], 6.37], 13],
    [[[327, 21, 1, 2], [262, 19, 0, 2], [25, 2, 1], [88, 10, 1, 4], 9.1], 14],
    [[[105, 11, 3, 2], [0, 1, 0], [60, 8, 0], [310, 20, 3, 5], 5.79], 15],
    [[[348, 21, 5, 2], [67, 9, 2, 0], [51, 6, 3], [26, 2, 2], 9.73], 16],
    [[[29, 2, 5], [4, 1, 1], [82, 10, 2, 0], [298, 20, 1, 3], 7.35], 17],
    [
      [
        [101, 11, 1, 2],
        [371, 22, "E1"],
        [223, 17, 4, 2],
        [156, 15, 1, 0],
        6.31
      ],
      18
    ],
    [[[31, 3, 1], [371, 22, "E1"], [126, 13, 0, 1], [22, 1, 5], 5.09], 19],
    [
      [
        [156, 15, 0, 1],
        [310, 20, 3, 5],
        [228, 17, 5, 2],
        [370, 22, "D5"],
        6.48
      ],
      20
    ],
    [[[332, 21, 2, 1], [57, 7, 3], [284, 19, 4, 3], [110, 11, 4, 5], 6.3], 21],
    [[[37, 4, 1], [313, 20, 4, 2], [86, 10, 1, 2], [362, 22, "C2"], 6.99], 22],
    [[[136, 13, 4, 2], [290, 19, 5, 4], [9, 1, 2], [26, 2, 2], 8.04], 23],
    [[[63, 8, 3], [140, 13, 4, 5], [50, 6, 2], [3, 1, 0], 7.94], 24],
    [[[95, 10, 5, 4], [63, 8, 3], [363, 22, "C3"], [54, 7, 0], 7.15], 25],
    [[[63, 8, 3], [102, 11, 1, 3], [76, 9, 2, 4], [18, 1, 4], 7.03], 26],
    [
      [[83, 10, 0, 3], [266, 19, 1, 0], [364, 22, "C4"], [129, 13, 0, 4], 7.52],
      27
    ],
    [[[49, 6, 1], [312, 20, 4, 1], [61, 8, 1], [353, 22, "A3"], 6.99], 0],
    [[[181, 16, 2, 0], [158, 15, 3, 0], [61, 8, 1], [49, 6, 1], 8.38], 1],
    [[[13, 1, 3], [61, 8, 1], [94, 10, 3, 5], [257, 18, 5, 1], 6.79], 2],
    [[[34, 3, 4], [360, 22, "B5"], [332, 21, 2, 1], [46, 5, 4], 5.16], 3],
    [[[106, 11, 4, 2], [201, 17, 0, 1], [362, 22, "C2"], [34, 3, 4], 6.88], 4],
    [[[18, 1, 4], [163, 15, 1, 4], [37, 4, 1], [65, 8, 5], 7.34], 5],
    [[[63, 8, 3], [30, 3, 0], [79, 9, 5, 3], [13, 1, 3], 5.8], 6],
    [[[64, 8, 4], [39, 4, 3], [93, 10, 3, 4], [104, 11, 5, 1], 6.89], 7],
    [[[172, 16, 0, 2], [7, 1, 1], [308, 20, 3, 2], [83, 10, 0, 3], 7.92], 8],
    [[[352, 22, "A2"], [62, 8, 2], [108, 11, 4, 3], [27, 2, 3], 6.21], 9],
    [[[371, 22, "E1"], [45, 5, 3], [60, 8, 0], [77, 9, 5, 2], 8.19], 10],
    [[[140, 13, 4, 5], [29, 2, 5], [62, 8, 2], [45, 5, 3], 8.59], 11],
    [[[157, 15, 2, 0], [102, 11, 3, 1], [190, 16, 3, 5], [29, 2, 5], 9.19], 12],
    [[[47, 5, 5], [8, 1, 2], [122, 12, 2, 5], [215, 17, 2, 5], 7.32], 13],
    [[[94, 10, 3, 5], [15, 1, 3], [374, 22, "E4"], [62, 8, 2], 7.27], 14],
    [[[16, 1, 4], [103, 11, 4, 1], [55, 7, 1], [351, 22, "A1"], 5.3], 15],
    [[[276, 19, 3, 0], [357, 22, "B2"], [18, 1, 4], [343, 21, 4, 2], 7.2], 16],
    [[[299, 20, 1, 4], [60, 8, 0], [81, 10, 0, 1], [287, 19, 5, 1], 7.83], 17],
    [[[304, 20, 2, 4], [57, 7, 3], [262, 19, 0, 2], [371, 22, "E1"], 6.17], 18],
    [[[62, 8, 2], [161, 15, 2, 1], [91, 10, 4, 2], [320, 20, 5, 4], 9.1], 19],
    [[[44, 5, 2], [17, 1, 4], [351, 22, "A1"], [60, 8, 0], 8.26], 20],
    [
      [[91, 10, 4, 2], [263, 19, 0, 3], [374, 22, "E4"], [321, 21, 0, 1], 7.67],
      21
    ],
    [[[60, 8, 0], [48, 6, 0], [312, 20, 4, 1], [110, 11, 5, 4], 7.82], 22],
    [[[85, 10, 5, 0], [196, 16, 5, 0], [63, 8, 3], [20, 1, 5], 5.55], 23],
    [[[217, 17, 3, 1], [165, 15, 3, 2], [94, 10, 3, 5], [9, 1, 2], 6.75], 24],
    [
      [[355, 22, "A5"], [75, 9, 2, 3], [107, 11, 2, 5], [172, 16, 0, 2], 6.4],
      25
    ],
    [[[63, 8, 3], [295, 20, 0, 5], [153, 14, 4, 3], [102, 11, 1, 3], 6.75], 26],
    [[[119, 12, 1, 5], [74, 9, 1, 5], [297, 20, 1, 2], [8, 1, 2], 8.64], 27],
    [[[256, 18, 5, 0], [7, 1, 1], [81, 10, 0, 1], [332, 21, 2, 1], 7.94], 0],
    [[[202, 17, 0, 2], [42, 5, 0], [341, 21, 4, 0], [147, 14, 3, 1], 7.29], 1],
    [[[307, 20, 3, 1], [65, 8, 5], [42, 5, 0], [78, 9, 3, 4], 6.52], 2],
    [[[80, 9, 5, 4], [58, 7, 4], [98, 11, 3, 0], [321, 21, 0, 1], 6.4], 3],
    [[[106, 11, 4, 2], [27, 2, 3], [165, 15, 2, 3], [369, 22, "D4"], 6.22], 4],
    [[[312, 20, 4, 1], [53, 6, 5], [192, 16, 4, 1], [60, 8, 0], 8.75], 5],
    [[[315, 20, 4, 5], [30, 3, 0], [375, 22, "E5"], [168, 15, 4, 3], 4.77], 6],
    [[[30, 3, 0], [361, 22, "C1"], [212, 17, 2, 1], [53, 6, 5], 5.54], 7],
    [[[215, 17, 2, 5], [63, 8, 3], [52, 6, 4], [80, 9, 5, 4], 7.88], 8],
    [[[30, 3, 0], [183, 16, 2, 3], [143, 14, 3, 0], [5, 1, 1], 6.16], 9],
    [
      [[322, 21, 0, 2], [88, 10, 4, 1], [224, 17, 4, 3], [355, 22, "A5"], 7.64],
      10
    ],
    [[[25, 2, 1], [233, 18, 0, 3], [62, 8, 2], [291, 20, 0, 1], 8.59], 11],
    [[[17, 1, 4], [65, 8, 5], [295, 20, 0, 5], [98, 11, 3, 0], 6.41], 12],
    [
      [
        [119, 12, 5, 1],
        [354, 22, "A4"],
        [318, 20, 5, 2],
        [288, 19, 5, 2],
        8.32
      ],
      13
    ],
    [[[41, 4, 5], [6, 1, 1], [364, 22, "C4"], [310, 20, 3, 5], 6.57], 14],
    [[[120, 12, 3, 2], [103, 11, 4, 1], [25, 2, 1], [250, 18, 3, 5], 7.66], 15],
    [[[63, 8, 3], [74, 9, 1, 5], [151, 14, 2, 4], [59, 7, 5], 9.45], 16],
    [[[11, 1, 2], [61, 8, 1], [51, 6, 3], [153, 14, 4, 3], 8.27], 17],
    [
      [[375, 22, "E5"], [110, 11, 5, 4], [301, 20, 2, 0], [83, 10, 3, 0], 6.39],
      18
    ],
    [[[36, 4, 0], [196, 16, 5, 0], [62, 8, 2], [365, 22, "C5"], 8.35], 19],
    [[[60, 8, 0], [251, 18, 4, 0], [66, 9, 0, 1], [351, 22, "A1"], 8.61], 20],
    [[[22, 1, 5], [237, 18, 1, 2], [357, 22, "B2"], [345, 21, 4, 5], 5.46], 21],
    [[[123, 12, 4, 3], [17, 1, 4], [83, 10, 0, 3], [315, 20, 4, 5], 7.86], 22],
    [[[361, 22, "C1"], [62, 8, 2], [54, 7, 0], [243, 18, 2, 3], 7.71], 23],
    [[[153, 14, 4, 3], [282, 19, 4, 1], [49, 6, 1], [109, 11, 3, 5], 7.56], 24],
    [
      [[137, 13, 2, 5], [355, 22, "A5"], [79, 9, 5, 3], [318, 20, 5, 2], 6.52],
      25
    ],
    [[[27, 2, 3], [84, 10, 0, 4], [13, 1, 3], [58, 7, 4], 8.81], 26],
    [[[341, 21, 4, 0], [22, 1, 5], [124, 12, 3, 5], [47, 5, 5], 6.86], 27]
  ],
  dailyPuzzles: [
    [
      [
        [3, 2, 2, 0, 0],
        [6, 3, 0, 2, 0],
        [2, 6, 0, 0, 2],
        [4, 5, 2, 0, 0]
      ],
      [],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 4, 5, 2, 2],
          [4, 4, 5, 5, 0],
          [-1, 3, -1, 5, 0],
          [3, 3, 3, -1, 0]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 1, 2],
          [2, 0, 0, 0, 2],
          [0, 2, 0, 1, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [4, 9, 2, 0, 3],
        [2, 1, 2, 0, 0]
      ],
      [
        [98, 11, 3, 0],
        [333, 21, 2, 3]
      ],
      [
        [
          [0, 0, 0, -1, 3],
          [-1, 5, 5, 3, 3],
          [5, 5, 1, -1, 3],
          [2, 2, 1, 4, 4],
          [2, 2, 1, 1, 4]
        ],
        [
          [0, 0, 2, 0, 1],
          [0, 0, 0, 2, 0],
          [1, 0, 1, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 1, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [3, 4, 0, 2, 0],
        [2, 7, 0, 2, 1]
      ],
      [
        [171, 16, 0, 1],
        [21, 1, 5]
      ],
      [
        [
          [1, 1, 1, 5, 5],
          [1, 4, 5, 5, -1],
          [4, 4, -1, 3, 0],
          [2, 2, 3, 3, 0],
          [2, 2, -1, 3, 0]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 0, 0, 0],
          [2, 0, 0, 1, 0],
          [0, 0, 2, 0, 0],
          [1, 2, 0, 0, 2]
        ]
      ]
    ],
    [
      [[5, 6, 0, 2, 1]],
      [
        [95, 10, 5, 4],
        [239, 18, 1, 4],
        [333, 21, 2, 3]
      ],
      [
        [
          [1, 1, 1, -1, 3],
          [1, 4, 4, 3, 3],
          [0, 4, -1, 5, 3],
          [0, 2, 2, 5, 5],
          [0, 2, 2, -1, 5]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 2, 2, 0],
          [2, 0, 0, 1, 0],
          [0, 1, 0, 0, 0],
          [0, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [[6, 0, 2, 0, 0]],
      [
        [87, 10, 3, 1],
        [204, 17, 0, 4],
        [0, 1, 0]
      ],
      [
        [
          [-1, -1, 3, 4, 4],
          [-1, 3, 3, 3, 4],
          [1, 5, 0, 0, 0],
          [1, 5, 5, 2, 2],
          [1, 1, 5, 2, 2]
        ],
        [
          [0, 0, 2, 0, 0],
          [0, 0, 0, 1, 2],
          [1, 0, 0, 0, 2],
          [0, 0, 0, 0, 0],
          [0, 0, 1, 1, 2]
        ]
      ]
    ],
    [
      [
        [5, 7, 1, 0, 3],
        [7, 2, 0, 3, 0]
      ],
      [
        [1, 1, 0],
        [335, 21, 2, 5]
      ],
      [
        [
          [0, 5, -1, 4, -1],
          [0, 5, 5, 4, 4],
          [0, 3, 5, 1, 1],
          [3, 3, 2, 2, 1],
          [-1, 3, 2, 2, 1]
        ],
        [
          [0, 1, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [2, 1, 0, 0, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 1, 2, 1]
        ]
      ]
    ],
    [
      [
        [7, 6, 3, 3, 0],
        [3, 0, 0, 0, 3]
      ],
      [
        [137, 13, 2, 5],
        [79, 9, 3, 5]
      ],
      [
        [
          [0, 5, -1, 4, 4],
          [0, 5, 5, 3, 4],
          [0, -1, 5, 3, 3],
          [2, 2, -1, 3, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [2, 0, 1, 0, 2],
          [1, 0, 0, 1, 0],
          [2, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [2, 1, 0],
        [23, 1, 5],
        [335, 21, 2, 5],
        [85, 10, 5, 0]
      ],
      [
        [
          [1, 1, 1, 4, 4],
          [1, 5, 5, 3, 4],
          [5, 5, 3, 3, 3],
          [-1, -1, -1, 2, 2],
          [0, 0, 0, 2, 2]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 2, 2],
          [1, 0, 0, 0, 1],
          [0, 0, 0, 2, 1],
          [2, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 2, 1, 1, 0],
        [2, 8, 0, 2, 0],
        [2, 3, 2, 0, 0]
      ],
      [[68, 9, 3, 0]],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, -1, 3, -1],
          [1, 5, 3, 3, 3],
          [1, 5, 5, 4, 4],
          [1, 1, 5, 4, -1]
        ],
        [
          [0, 2, 2, 0, 0],
          [0, 1, 0, 2, 0],
          [1, 0, 0, 0, 1],
          [0, 0, 0, 0, 2],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 0, 1, 0, 0],
        [4, 8, 2, 0, 3],
        [6, 0, 0, 0, 0]
      ],
      [[93, 10, 3, 4]],
      [
        [
          [1, 5, 4, 4, -1],
          [1, 5, 5, 4, 0],
          [1, 1, 5, -1, 0],
          [2, 2, -1, 3, 0],
          [2, 2, 3, 3, 3]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 0, 0, 2, 2],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 2, 0],
          [1, 2, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 3, 2, 2, 0],
        [2, 1, 0, 0, 2],
        [0, 1, 1]
      ],
      [
        [76, 9, 2, 4],
        [28, 2, 4]
      ],
      [
        [
          [-1, 4, 4, 2, 2],
          [0, -1, 4, 2, 2],
          [0, -1, 3, 3, 3],
          [0, 5, 5, 3, 1],
          [5, 5, 1, 1, 1]
        ],
        [
          [0, 0, 0, 2, 1],
          [0, 0, 2, 0, 0],
          [0, 0, 1, 0, 0],
          [2, 0, 0, 2, 0],
          [1, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 2, 0, 0, 0],
        [2, 7, 1, 0, 0],
        [7, 3, 0, 0, 0]
      ],
      [[2, 1, 0]],
      [
        [
          [1, 1, 1, 4, 4],
          [1, -1, 5, 5, 4],
          [3, 5, 5, 2, 2],
          [3, 3, -1, 2, 2],
          [3, 0, 0, 0, -1]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 1, 2],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [1, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 2, 0, 0, 0],
        [2, 4, 2, 1, 2]
      ],
      [
        [348, 21, 5, 2],
        [63, 8, 3]
      ],
      [
        [
          [3, 3, 3, 2, 2],
          [-1, 3, -1, 2, 2],
          [-1, 5, 5, 1, 1],
          [5, 5, 4, 4, 1],
          [0, 0, 0, 4, 1]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [0, 0, 2, 2, 1]
        ]
      ]
    ],
    [
      [
        [4, 0, 0, 0, 0],
        [2, 9, 1, 2, 0],
        [2, 3, 1, 0, 0],
        [0, 8, 1]
      ],
      [],
      [
        [
          [-1, 5, 5, -1, -1],
          [5, 5, 1, 4, 4],
          [1, 1, 1, 3, 4],
          [2, 2, 3, 3, 3],
          [2, 2, 0, 0, 0]
        ],
        [
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [1, 0, 0, 2, 2],
          [0, 0, 0, 0, 1],
          [1, 2, 2, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [10, 1, 2],
        [371, 22, "E1"],
        [1, 1, 0]
      ],
      [
        [
          [5, 1, 1, -1, -1],
          [5, 5, 1, 4, 4],
          [0, 5, 1, 4, 3],
          [0, 2, 2, 3, 3],
          [0, 2, 2, -1, 3]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 1, 1, 0, 1],
          [0, 1, 0, 2, 0],
          [2, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [1, 4, 2],
        [8, 9, 0, 0, 0],
        [0, 6, 1]
      ],
      [],
      [
        [
          [0, -1, 3, 2, 2],
          [0, 3, 3, 2, 2],
          [0, 1, 3, 5, 5],
          [-1, 1, 5, 5, 4],
          [-1, 1, 1, 4, 4]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 2, 0, 0, 1],
          [2, 1, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [5, 3, 3, 0, 3],
        [3, 6, 3, 0, 0]
      ],
      [[68, 9, 0, 3]],
      [
        [
          [-1, 0, 0, 0, 3],
          [4, 4, 5, 3, 3],
          [-1, 4, 5, 5, 3],
          [2, 2, -1, 5, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [0, 0, 0, 2, 1],
          [0, 0, 0, 2, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 1, 0],
          [1, 2, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 1, 3, 0, 3],
        [3, 9, 0, 0, 0]
      ],
      [
        [351, 22, "A1"],
        [0, 1, 0]
      ],
      [
        [
          [-1, 0, 0, 0, -1],
          [-1, 5, 5, 1, 1],
          [5, 5, 4, 4, 1],
          [2, 2, 4, 3, 1],
          [2, 2, 3, 3, 3]
        ],
        [
          [0, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 2, 0],
          [1, 0, 0, 2, 1],
          [2, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [3, 9, 0, 3, 0],
        [4, 6, 0, 0, 0]
      ],
      [[256, 18, 5, 0]],
      [
        [
          [5, -1, -1, 2, 2],
          [5, 5, -1, 2, 2],
          [1, 5, 0, 0, 0],
          [1, 3, 3, 3, 4],
          [1, 1, 3, 4, 4]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 0, 0, 1, 2],
          [1, 0, 2, 0, 0],
          [0, 1, 0, 0, 0],
          [0, 0, 2, 2, 0]
        ]
      ]
    ],
    [
      [
        [4, 6, 3, 3, 0],
        [5, 3, 0, 3, 1],
        [2, 0, 0, 0, 1]
      ],
      [[28, 2, 4]],
      [
        [
          [-1, 5, 5, 3, 0],
          [5, 5, 3, 3, 0],
          [-1, 4, -1, 3, 0],
          [4, 4, 1, 2, 2],
          [1, 1, 1, 2, 2]
        ],
        [
          [0, 0, 0, 1, 0],
          [1, 0, 2, 0, 0],
          [0, 0, 0, 0, 2],
          [2, 0, 0, 1, 0],
          [1, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [3, 0, 3, 0, 0],
        [5, 9, 0, 3, 2],
        [2, 4, 0, 0, 1]
      ],
      [[280, 19, 3, 5]],
      [
        [
          [4, 4, 0, 2, 2],
          [-1, 4, 0, 2, 2],
          [-1, 3, 0, 5, 5],
          [3, 3, 5, 5, 1],
          [-1, 3, 1, 1, 1]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [0, 1, 2, 0, 0],
          [2, 0, 1, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 3, 0, 2, 0],
        [1, 5, 2]
      ],
      [
        [363, 22, "C3"],
        [18, 1, 4],
        [250, 18, 3, 5]
      ],
      [
        [
          [-1, 3, -1, 2, 2],
          [0, 3, 3, 2, 2],
          [0, 3, -1, 5, 5],
          [0, 4, 5, 5, 1],
          [4, 4, 1, 1, 1]
        ],
        [
          [0, 0, 0, 2, 1],
          [0, 0, 2, 0, 0],
          [0, 1, 0, 0, 1],
          [2, 0, 0, 0, 0],
          [2, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [139, 13, 3, 5],
        [16, 1, 4],
        [1, 1, 0],
        [261, 19, 0, 1]
      ],
      [
        [
          [-1, 4, 4, 1, 1],
          [0, 4, 5, 5, 1],
          [0, 5, 5, -1, 1],
          [0, 3, -1, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [0, 0, 2, 0, 0],
          [0, 0, 0, 1, 0],
          [0, 0, 0, 0, 1],
          [2, 2, 0, 0, 0],
          [0, 0, 1, 1, 2]
        ]
      ]
    ],
    [
      [],
      [
        [344, 21, 4, 3],
        [362, 22, "C2"]
      ],
      [
        [
          [0, 0, 0, -1, 3],
          [2, 2, -1, 3, 3],
          [2, 2, 1, 5, 3],
          [4, 4, 1, 5, 5],
          [-1, 4, 1, 1, 5]
        ],
        [
          [2, 0, 0, 0, 1],
          [1, 0, 0, 2, 0],
          [2, 0, 1, 1, 0],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 9, 1, 0, 2],
        [2, 3, 0, 1, 1]
      ],
      [
        [164, 15, 5, 1],
        [18, 1, 4]
      ],
      [
        [
          [1, -1, 4, 5, -1],
          [1, 4, 4, 5, 5],
          [1, 1, -1, 3, 5],
          [2, 2, 3, 3, 3],
          [2, 2, 0, 0, 0]
        ],
        [
          [1, 0, 0, 1, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 2, 0],
          [1, 0, 0, 0, 1],
          [2, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [3, 8, 3, 0, 0],
        [8, 9, 0, 0, 1],
        [1, 9, 3]
      ],
      [[21, 1, 5]],
      [
        [
          [-1, 0, 0, 0, -1],
          [2, 2, 1, 1, 1],
          [2, 2, 1, 4, 4],
          [-1, 5, 5, 3, 4],
          [5, 5, 3, 3, 3]
        ],
        [
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 1],
          [1, 2, 0, 0, 0],
          [0, 0, 1, 2, 2],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [5, 1, 0, 2, 1],
        [6, 7, 3, 0, 0]
      ],
      [[194, 16, 4, 3]],
      [
        [
          [3, 3, 3, 2, 2],
          [-1, 3, -1, 2, 2],
          [1, 1, 1, 4, 4],
          [1, 5, 5, 4, -1],
          [5, 5, 0, 0, 0]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [0, 0, 1, 0, 2],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [7, 2, 0, 1, 0],
        [2, 4, 0, 2, 0]
      ],
      [
        [282, 19, 4, 1],
        [196, 16, 5, 0]
      ],
      [
        [
          [0, 0, 0, 5, -1],
          [1, 1, 1, 5, 5],
          [1, 3, 2, 2, 5],
          [3, 3, 2, 2, 4],
          [-1, 3, -1, 4, 4]
        ],
        [
          [2, 0, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 1, 0, 2, 1],
          [2, 0, 0, 1, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [5, 3, 3, 0, 3],
        [2, 0, 0, 0, 2],
        [4, 9, 0, 0, 0]
      ],
      [[133, 13, 4, 1]],
      [
        [
          [-1, 5, 5, 4, 0],
          [5, 5, 4, 4, 0],
          [1, 2, 2, -1, 0],
          [1, 2, 2, 3, -1],
          [1, 1, 3, 3, 3]
        ],
        [
          [0, 0, 0, 0, 2],
          [1, 0, 2, 0, 0],
          [1, 2, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [5, 3, 0, 3, 3],
        [7, 7, 0, 3, 0],
        [5, 2, 0, 0, 0]
      ],
      [[44, 5, 2]],
      [
        [
          [1, 1, 1, 5, 5],
          [1, 3, 5, 5, 4],
          [0, 3, 3, 4, 4],
          [0, 3, 2, 2, -1],
          [0, -1, 2, 2, -1]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 0, 0, 0],
          [0, 0, 2, 2, 0],
          [0, 1, 0, 0, 0],
          [2, 0, 1, 2, 0]
        ]
      ]
    ],
    [
      [[2, 3, 1, 2, 1]],
      [
        [11, 1, 2],
        [2, 1, 0]
      ],
      [
        [
          [1, 1, 1, 5, 5],
          [1, -1, 5, 5, 4],
          [0, 0, 0, 4, 4],
          [2, 2, 3, 3, 3],
          [2, 2, -1, 3, -1]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 1, 0, 0],
          [2, 0, 0, 2, 0],
          [2, 1, 1, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [[2, 7, 0, 2, 1]],
      [[360, 22, "B5"]],
      [
        [
          [1, 1, 1, -1, 3],
          [1, 2, 2, 3, 3],
          [0, 2, 2, 5, 3],
          [0, -1, 4, 5, 5],
          [0, -1, 4, 4, 5]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 1, 0, 2, 0],
          [2, 2, 0, 1, 0],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[7, 1, 0, 3, 0]],
      [
        [327, 21, 1, 2],
        [28, 2, 4],
        [144, 14, 0, 4]
      ],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, -1, 5, -1],
          [1, 1, 1, 5, 5],
          [1, 4, 4, 3, 5],
          [-1, 4, 3, 3, 3]
        ],
        [
          [0, 0, 0, 0, 2],
          [1, 2, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 0, 2, 2, 1],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [0, 6, 1],
        [4, 3, 0, 3, 3]
      ],
      [
        [2, 1, 0],
        [235, 18, 0, 5]
      ],
      [
        [
          [0, 0, 0, 2, 2],
          [3, 3, 3, 2, 2],
          [4, 3, 5, 1, 1],
          [4, 4, 5, 5, 1],
          [-1, -1, -1, 5, 1]
        ],
        [
          [2, 0, 0, 0, 2],
          [1, 0, 0, 0, 1],
          [2, 2, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 1, 1]
        ]
      ]
    ],
    [
      [
        [2, 8, 1, 2, 0],
        [5, 5, 2, 3, 0]
      ],
      [
        [120, 12, 3, 2],
        [217, 17, 3, 1]
      ],
      [
        [
          [0, 0, 0, 1, 1],
          [-1, -1, 4, 4, 1],
          [2, 2, 4, 5, 1],
          [2, 2, 3, 5, 5],
          [-1, 3, 3, 3, 5]
        ],
        [
          [0, 0, 2, 0, 0],
          [0, 0, 0, 2, 0],
          [2, 1, 0, 0, 1],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 1, 1]
        ]
      ]
    ],
    [
      [
        [2, 5, 1, 0, 2],
        [1, 2, 3]
      ],
      [[356, 22, "B1"]],
      [
        [
          [0, -1, 4, 2, 2],
          [0, 4, 4, 2, 2],
          [0, 5, 5, -1, 3],
          [5, 5, 1, 3, 3],
          [1, 1, 1, -1, 3]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [2, 0, 1, 0, 1],
          [0, 0, 0, 2, 0],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [14, 1, 3],
        [119, 12, 1, 5]
      ],
      [
        [
          [-1, 4, 1, 1, 1],
          [4, 4, 1, 5, 5],
          [2, 2, 5, 5, 3],
          [2, 2, -1, 3, 3],
          [0, 0, 0, -1, 3]
        ],
        [
          [0, 0, 0, 0, 1],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 1],
          [1, 2, 0, 2, 0],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [6, 2, 0, 1, 0],
        [7, 3, 0, 1, 3]
      ],
      [
        [129, 13, 4, 0],
        [225, 17, 4, 5]
      ],
      [
        [
          [3, -1, 0, 2, 2],
          [3, 3, 0, 2, 2],
          [3, -1, 0, 1, 1],
          [4, 4, 5, 5, 1],
          [4, 5, 5, -1, 1]
        ],
        [
          [0, 0, 2, 1, 0],
          [0, 2, 0, 2, 0],
          [1, 0, 0, 0, 0],
          [0, 2, 0, 1, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 8, 0, 0, 1],
        [2, 4, 0, 2, 1]
      ],
      [
        [138, 13, 3, 4],
        [149, 14, 5, 1]
      ],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 4, 5, 2, 2],
          [4, 4, 5, 5, 0],
          [-1, 3, -1, 5, 0],
          [3, 3, 3, -1, 0]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 0, 0, 0, 1],
          [2, 0, 0, 0, 0],
          [0, 2, 0, 1, 0],
          [0, 0, 1, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 9, 0, 1, 2],
        [7, 2, 0, 3, 0]
      ],
      [
        [304, 20, 2, 4],
        [288, 19, 5, 2]
      ],
      [
        [
          [1, 5, -1, 2, 2],
          [1, 5, 5, 2, 2],
          [1, 1, 5, 3, -1],
          [4, -1, 3, 3, 3],
          [4, 4, 0, 0, 0]
        ],
        [
          [1, 1, 0, 1, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 2, 0],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 2, 1, 0, 1],
        [4, 7, 3, 3, 0]
      ],
      [
        [168, 15, 3, 4],
        [147, 14, 1, 3]
      ],
      [
        [
          [0, -1, 4, -1, 3],
          [0, 4, 4, 3, 3],
          [0, -1, 1, 5, 3],
          [2, 2, 1, 5, 5],
          [2, 2, 1, 1, 5]
        ],
        [
          [2, 0, 0, 0, 1],
          [0, 2, 0, 2, 0],
          [0, 0, 1, 1, 0],
          [0, 0, 0, 0, 0],
          [1, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [192, 16, 4, 1],
        [94, 10, 3, 5],
        [314, 20, 4, 3]
      ],
      [
        [
          [3, 1, 1, 2, 2],
          [3, 3, 1, 2, 2],
          [3, -1, 1, -1, 0],
          [-1, 5, 5, 4, 0],
          [5, 5, 4, 4, 0]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [1, 0, 1, 0, 2],
          [0, 0, 1, 0, 0],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [[3, 7, 2, 0, 0]],
      [
        [251, 18, 4, 0],
        [126, 13, 1, 0],
        [230, 17, 5, 4]
      ],
      [
        [
          [5, -1, 1, 1, 1],
          [5, 5, 1, 2, 2],
          [-1, 5, 3, 2, 2],
          [-1, 3, 3, 3, 4],
          [0, 0, 0, 4, 4]
        ],
        [
          [1, 0, 0, 0, 1],
          [0, 0, 0, 0, 0],
          [0, 0, 2, 1, 2],
          [0, 0, 0, 1, 0],
          [0, 0, 2, 2, 0]
        ]
      ]
    ],
    [
      [
        [7, 6, 3, 0, 3],
        [4, 9, 0, 3, 0]
      ],
      [
        [333, 21, 2, 3],
        [219, 17, 3, 4]
      ],
      [
        [
          [1, 5, 3, 3, 3],
          [1, 5, 5, 3, -1],
          [1, 1, 5, 4, -1],
          [2, 2, -1, 4, 4],
          [2, 2, 0, 0, 0]
        ],
        [
          [1, 0, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 1, 2, 0],
          [1, 0, 0, 0, 0],
          [2, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 3, 2, 1, 0],
        [5, 7, 1, 0, 2]
      ],
      [
        [73, 9, 4, 1],
        [125, 12, 4, 5]
      ],
      [
        [
          [0, 1, 2, 2, -1],
          [0, 1, 2, 2, 3],
          [0, 1, 1, 3, 3],
          [4, 4, 5, 5, 3],
          [4, 5, 5, -1, -1]
        ],
        [
          [0, 1, 1, 0, 0],
          [0, 0, 2, 0, 1],
          [2, 0, 0, 2, 0],
          [0, 2, 0, 1, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [1, 4, 1],
        [6, 9, 3, 1, 3],
        [2, 1, 1, 2, 2]
      ],
      [[3, 1, 0]],
      [
        [
          [0, 2, 2, -1, 3],
          [0, 2, 2, 3, 3],
          [0, 5, 5, 4, 3],
          [5, 5, 1, 4, 4],
          [1, 1, 1, -1, -1]
        ],
        [
          [2, 0, 0, 0, 1],
          [0, 1, 2, 2, 0],
          [0, 0, 1, 2, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [4, 4, 0, 0, 0],
        [2, 9, 1, 1, 0],
        [4, 1, 0, 0, 0]
      ],
      [[75, 9, 2, 3]],
      [
        [
          [0, 2, 2, 1, 1],
          [0, 2, 2, -1, 1],
          [0, 3, 4, 4, 1],
          [3, 3, 4, 5, 5],
          [-1, 3, 5, 5, -1]
        ],
        [
          [2, 0, 2, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 1, 0, 2, 1],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[7, 8, 3, 0, 3]],
      [
        [13, 1, 3],
        [319, 20, 5, 3]
      ],
      [
        [
          [2, 2, 4, 1, 1],
          [2, 2, 4, 4, 1],
          [0, 0, 0, 5, 1],
          [3, 3, 3, 5, 5],
          [-1, 3, -1, -1, 5]
        ],
        [
          [0, 0, 2, 0, 0],
          [1, 2, 0, 0, 0],
          [0, 0, 2, 0, 1],
          [1, 0, 0, 0, 0],
          [0, 2, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 1, 0, 0, 0],
        [7, 4, 0, 0, 0],
        [0, 2, 1],
        [4, 4, 0, 0, 0]
      ],
      [[364, 22, "C4"]],
      [
        [
          [2, 2, -1, 5, 5],
          [2, 2, 5, 5, 0],
          [1, 4, 4, -1, 0],
          [1, 4, -1, 3, 0],
          [1, 1, 3, 3, 3]
        ],
        [
          [2, 1, 0, 0, 1],
          [0, 0, 0, 0, 0],
          [1, 0, 2, 0, 0],
          [0, 0, 0, 2, 2],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [3, 7, 0, 0, 2],
        [6, 2, 3, 0, 2],
        [3, 0, 0, 0, 0]
      ],
      [[110, 11, 4, 5]],
      [
        [
          [1, 5, -1, 4, 4],
          [1, 5, 5, 3, 4],
          [1, 1, 5, 3, 3],
          [2, 2, -1, 3, -1],
          [2, 2, 0, 0, 0]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 0, 1, 0, 2],
          [0, 0, 0, 1, 0],
          [1, 2, 2, 0, 0]
        ]
      ]
    ],
    [
      [[5, 1, 3, 0, 3]],
      [
        [30, 3, 0],
        [1, 1, 0],
        [61, 8, 1]
      ],
      [
        [
          [2, 2, -1, 4, 4],
          [2, 2, 0, 5, 4],
          [1, -1, 0, 5, 5],
          [1, -1, 0, 3, 5],
          [1, 1, 3, 3, 3]
        ],
        [
          [2, 1, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 0, 0, 0],
          [0, 0, 2, 2, 1],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [3, 9, 0, 0, 3],
        [4, 7, 0, 0, 0]
      ],
      [[286, 19, 5, 0]],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, 1, 5, -1],
          [1, 1, 1, 5, 5],
          [3, 3, 3, 4, 5],
          [-1, 3, -1, 4, 4]
        ],
        [
          [0, 2, 2, 0, 0],
          [0, 1, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [1, 0, 0, 2, 1],
          [0, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 4, 2, 1, 2],
        [1, 4, 2]
      ],
      [[115, 12, 0, 5]],
      [
        [
          [5, 3, 3, 3, 4],
          [5, 5, 3, 4, 4],
          [0, 5, 1, 1, 1],
          [0, -1, 1, 2, 2],
          [0, -1, -1, 2, 2]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 0, 2, 2, 0],
          [0, 0, 0, 0, 1],
          [0, 0, 0, 0, 0],
          [2, 0, 0, 1, 2]
        ]
      ]
    ],
    [
      [
        [2, 4, 1, 0, 1],
        [5, 1, 1, 0, 2]
      ],
      [
        [213, 17, 2, 3],
        [4, 1, 1]
      ],
      [
        [
          [2, 2, -1, 1, 1],
          [2, 2, -1, 4, 1],
          [0, 3, 4, 4, 1],
          [0, 3, 3, 5, 5],
          [0, 3, 5, 5, -1]
        ],
        [
          [0, 0, 0, 0, 0],
          [1, 2, 0, 0, 0],
          [2, 0, 2, 0, 1],
          [0, 0, 2, 0, 0],
          [0, 1, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 5, 0, 1, 1],
        [2, 2, 0, 0, 2],
        [1, 3, 3]
      ],
      [[271, 19, 2, 0]],
      [
        [
          [0, 0, 0, 2, 2],
          [3, 3, 3, 2, 2],
          [-1, 3, -1, 1, 1],
          [-1, 5, 5, 4, 1],
          [5, 5, 4, 4, 1]
        ],
        [
          [0, 0, 2, 1, 0],
          [1, 0, 0, 2, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 2, 0, 1]
        ]
      ]
    ],
    [
      [
        [0, 7, 1],
        [7, 7, 0, 1, 3]
      ],
      [
        [200, 16, 5, 4],
        [239, 18, 1, 4]
      ],
      [
        [
          [-1, -1, 5, 1, 1],
          [0, 4, 5, 5, 1],
          [0, 4, 4, 5, 1],
          [0, 3, -1, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 1],
          [2, 2, 0, 0, 0],
          [0, 0, 1, 1, 2]
        ]
      ]
    ],
    [
      [
        [3, 7, 0, 3, 0],
        [2, 3, 0, 0, 2]
      ],
      [
        [147, 14, 3, 1],
        [368, 22, "D3"]
      ],
      [
        [
          [0, 0, 0, 3, -1],
          [4, 4, 3, 3, 3],
          [4, 5, 5, -1, -1],
          [5, 5, 1, 2, 2],
          [1, 1, 1, 2, 2]
        ],
        [
          [2, 0, 0, 2, 0],
          [0, 2, 0, 0, 1],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 6, 0, 0, 0],
        [2, 8, 2, 0, 0],
        [1, 6, 2],
        [4, 8, 3, 3, 0]
      ],
      [],
      [
        [
          [0, 5, -1, -1, 3],
          [0, 5, 5, 3, 3],
          [0, -1, 5, 4, 3],
          [2, 2, 4, 4, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [0, 0, 0, 0, 1],
          [0, 0, 0, 2, 0],
          [2, 0, 1, 0, 0],
          [1, 0, 2, 0, 0],
          [2, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [[3, 5, 0, 3, 0]],
      [
        [23, 1, 5],
        [69, 9, 0, 4],
        [155, 14, 5, 4]
      ],
      [
        [
          [3, 3, 3, 5, 5],
          [-1, 3, 5, 5, -1],
          [0, 0, 0, 4, 4],
          [2, 2, -1, 4, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [0, 0, 2, 0, 2],
          [2, 1, 0, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 1, 0, 0],
        [2, 9, 1, 2, 0],
        [5, 6, 0, 2, 1]
      ],
      [[267, 19, 1, 2]],
      [
        [
          [4, 4, 0, 0, 0],
          [4, 2, 2, 5, -1],
          [-1, 2, 2, 5, 5],
          [1, 1, 1, 3, 5],
          [1, -1, 3, 3, 3]
        ],
        [
          [0, 2, 0, 0, 2],
          [0, 1, 0, 1, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 2, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 9, 1, 2, 2],
        [2, 3, 1, 0, 2]
      ],
      [[66, 9, 1, 0]],
      [
        [
          [1, 2, 2, 4, 4],
          [1, 2, 2, 4, -1],
          [1, 1, 0, 0, 0],
          [-1, 5, 5, 3, -1],
          [5, 5, 3, 3, 3]
        ],
        [
          [1, 1, 0, 0, 2],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 0, 1, 2, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 7, 0, 0, 0],
        [5, 8, 0, 0, 0],
        [2, 4, 2, 0, 1]
      ],
      [[18, 1, 4]],
      [
        [
          [0, 2, 2, -1, 3],
          [0, 2, 2, 3, 3],
          [0, 5, 5, -1, 3],
          [5, 5, 1, -1, 4],
          [1, 1, 1, 4, 4]
        ],
        [
          [2, 1, 0, 0, 1],
          [0, 2, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [1, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [8, 5, 0, 0, 0],
        [2, 1, 0, 2, 0]
      ],
      [
        [17, 1, 4],
        [126, 13, 1, 0]
      ],
      [
        [
          [4, 4, 1, 1, 1],
          [-1, 4, 1, 5, 5],
          [0, -1, 5, 5, 3],
          [0, 2, 2, 3, 3],
          [0, 2, 2, -1, 3]
        ],
        [
          [0, 0, 0, 0, 1],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 0, 1],
          [0, 2, 1, 2, 0],
          [2, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 1, 0, 2, 0],
        [2, 2, 0, 0, 0]
      ],
      [
        [271, 19, 2, 0],
        [246, 18, 3, 0]
      ],
      [
        [
          [1, 4, 4, 3, -1],
          [1, 4, 3, 3, 0],
          [1, 1, 5, 3, 0],
          [2, 2, 5, 5, 0],
          [2, 2, -1, 5, -1]
        ],
        [
          [1, 0, 2, 1, 0],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 2],
          [2, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [
        [2, 1, 1, 2, 1],
        [2, 4, 0, 0, 2]
      ],
      [
        [247, 18, 3, 1],
        [346, 21, 5, 0]
      ],
      [
        [
          [0, 0, 0, 2, 2],
          [-1, 5, 5, 2, 2],
          [5, 5, 3, 3, 3],
          [1, 1, 1, 3, 4],
          [1, -1, -1, 4, 4]
        ],
        [
          [2, 0, 0, 0, 0],
          [0, 0, 1, 1, 2],
          [0, 0, 1, 0, 0],
          [0, 0, 1, 2, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [[3, 7, 0, 0, 2]],
      [
        [20, 1, 5],
        [285, 19, 4, 5],
        [281, 19, 4, 0]
      ],
      [
        [
          [0, 0, 0, 1, 1],
          [5, -1, 2, 2, 1],
          [5, 5, 2, 2, 1],
          [4, 5, 3, 3, 3],
          [4, 4, -1, 3, -1]
        ],
        [
          [0, 0, 2, 0, 0],
          [1, 0, 0, 2, 0],
          [0, 0, 0, 1, 1],
          [2, 0, 1, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [2, 2, 0, 1, 0],
        [1, 7, 2],
        [5, 8, 3, 0, 3],
        [2, 1, 0, 2, 0]
      ],
      [],
      [
        [
          [3, 3, 3, 5, -1],
          [0, 3, -1, 5, 5],
          [0, 1, 1, -1, 5],
          [0, 4, 1, 2, 2],
          [4, 4, 1, 2, 2]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 1],
          [2, 0, 0, 0, 2],
          [2, 0, 1, 0, 1]
        ]
      ]
    ],
    [
      [[7, 7, 0, 0, 0]],
      [
        [20, 1, 5],
        [370, 22, "D5"],
        [149, 14, 5, 1]
      ],
      [
        [
          [4, 4, 0, 2, 2],
          [5, 4, 0, 2, 2],
          [5, 5, 0, -1, 3],
          [-1, 5, 1, 3, 3],
          [1, 1, 1, -1, 3]
        ],
        [
          [0, 0, 0, 2, 1],
          [1, 2, 0, 0, 0],
          [0, 0, 2, 0, 1],
          [0, 0, 0, 2, 0],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 9, 0, 0, 0],
        [2, 4, 1, 0, 1],
        [2, 2, 1, 0, 0]
      ],
      [[199, 16, 5, 3]],
      [
        [
          [0, 3, -1, 4, -1],
          [0, 3, 3, 4, 4],
          [0, 3, -1, 5, 5],
          [2, 2, 5, 5, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [2, 0, 0, 2, 0],
          [0, 0, 2, 0, 0],
          [0, 1, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [0, 1, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [0, 7, 1],
        [2, 2, 0, 1, 1]
      ],
      [
        [73, 9, 1, 4],
        [298, 20, 1, 3]
      ],
      [
        [
          [0, 4, -1, 2, 2],
          [0, 4, 4, 2, 2],
          [0, 3, 1, 1, 1],
          [3, 3, 1, 5, 5],
          [-1, 3, 5, 5, -1]
        ],
        [
          [2, 2, 0, 1, 0],
          [0, 0, 0, 2, 0],
          [0, 1, 0, 0, 1],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[5, 5, 0, 0, 0]],
      [
        [93, 10, 4, 3],
        [10, 1, 2],
        [244, 18, 2, 4]
      ],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 4, 4, 2, 2],
          [3, -1, 4, 5, -1],
          [3, 3, -1, 5, 5],
          [3, 0, 0, 0, 5]
        ],
        [
          [0, 0, 1, 1, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 2, 0, 0],
          [0, 2, 0, 0, 0],
          [1, 0, 0, 2, 1]
        ]
      ]
    ],
    [
      [
        [5, 3, 1, 3, 0],
        [6, 7, 0, 1, 0],
        [0, 3, 2]
      ],
      [],
      [
        [
          [1, 1, 1, 5, 5],
          [1, 4, 5, 5, -1],
          [4, 4, 0, 0, 0],
          [-1, 3, -1, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 0, 0, 0],
          [2, 0, 2, 0, 0],
          [0, 2, 0, 1, 0],
          [0, 0, 1, 2, 0]
        ]
      ]
    ],
    [
      [
        [1, 3, 3],
        [6, 9, 2, 0, 0],
        [5, 4, 0, 1, 2]
      ],
      [[3, 1, 0]],
      [
        [
          [0, 4, 4, 3, -1],
          [0, 5, 4, 3, 3],
          [0, 5, 5, 3, -1],
          [2, 2, 5, -1, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [2, 0, 0, 0, 0],
          [0, 0, 2, 0, 2],
          [0, 0, 0, 1, 0],
          [0, 0, 1, 0, 0],
          [1, 2, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [6, 1, 2, 0, 0],
        [5, 3, 1, 2, 0]
      ],
      [
        [79, 9, 5, 3],
        [350, 21, 5, 4]
      ],
      [
        [
          [1, 3, 3, 3, 4],
          [1, -1, 3, 4, 4],
          [1, 1, 5, -1, 0],
          [2, 2, 5, 5, 0],
          [2, 2, -1, 5, 0]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 0, 2, 2, 0],
          [0, 0, 1, 0, 0],
          [2, 1, 0, 0, 0],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [3, 5, 0, 2, 0],
        [1, 7, 3]
      ],
      [
        [69, 9, 4, 0],
        [246, 18, 3, 0]
      ],
      [
        [
          [3, -1, -1, 5, 5],
          [3, 3, 5, 5, -1],
          [3, 4, 1, 1, 1],
          [4, 4, 1, 2, 2],
          [0, 0, 0, 2, 2]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [1, 0, 0, 0, 1],
          [2, 0, 0, 0, 0],
          [2, 0, 0, 1, 2]
        ]
      ]
    ],
    [
      [
        [2, 5, 0, 0, 1],
        [2, 1, 0, 1, 0],
        [4, 9, 0, 0, 0]
      ],
      [
        [19, 1, 4],
        [87, 10, 3, 1]
      ],
      [
        [
          [0, 0, 0, 2, 2],
          [3, 3, 3, 2, 2],
          [-1, 3, 5, 1, 1],
          [-1, 4, 5, 5, 1],
          [-1, 4, 4, 5, 1]
        ],
        [
          [0, 0, 2, 2, 1],
          [1, 0, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[7, 6, 0, 3, 3]],
      [
        [3, 1, 0],
        [360, 22, "B5"],
        [271, 19, 2, 0]
      ],
      [
        [
          [4, 4, 1, -1, 3],
          [0, 4, 1, 3, 3],
          [0, 5, 1, 1, 3],
          [0, 5, 5, 2, 2],
          [-1, -1, 5, 2, 2]
        ],
        [
          [0, 0, 1, 0, 1],
          [2, 2, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 2, 1],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 0, 1, 1, 0],
        [5, 3, 2, 0, 1]
      ],
      [
        [3, 1, 0],
        [85, 10, 5, 0]
      ],
      [
        [
          [1, 1, 1, 5, 5],
          [1, 3, 5, 5, -1],
          [3, 3, 3, -1, 0],
          [2, 2, 4, -1, 0],
          [2, 2, 4, 4, 0]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 0, 2],
          [2, 1, 2, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 0, 0, 2, 2],
        [7, 5, 0, 0, 1]
      ],
      [
        [280, 19, 3, 5],
        [375, 22, "E5"]
      ],
      [
        [
          [0, 2, 2, 4, 4],
          [0, 2, 2, 4, -1],
          [0, 5, 5, 3, -1],
          [5, 5, 1, 3, 3],
          [1, 1, 1, 3, -1]
        ],
        [
          [2, 0, 0, 0, 2],
          [0, 1, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 2],
          [1, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [
        [3, 3, 0, 3, 0],
        [7, 8, 0, 3, 3]
      ],
      [
        [205, 17, 0, 5],
        [111, 12, 1, 0],
        [333, 21, 2, 3]
      ],
      [
        [
          [-1, 1, 1, 1, 3],
          [4, 1, 0, 3, 3],
          [4, 4, 0, 5, 3],
          [2, 2, 0, 5, 5],
          [2, 2, -1, -1, 5]
        ],
        [
          [0, 0, 0, 1, 1],
          [2, 0, 2, 2, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [2, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[2, 9, 0, 0, 0]],
      [
        [168, 15, 3, 4],
        [10, 1, 2],
        [87, 10, 3, 1]
      ],
      [
        [
          [5, 0, 0, 0, 1],
          [5, 5, 1, 1, 1],
          [3, 5, -1, 2, 2],
          [3, 3, 4, 2, 2],
          [3, -1, 4, 4, -1]
        ],
        [
          [1, 2, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 1, 0],
          [0, 2, 2, 2, 0],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[6, 0, 0, 0, 0]],
      [
        [11, 1, 2],
        [199, 16, 5, 3]
      ],
      [
        [
          [2, 2, -1, 5, 5],
          [2, 2, 5, 5, 0],
          [1, 1, 1, 3, 0],
          [1, 4, 3, 3, 0],
          [4, 4, -1, 3, -1]
        ],
        [
          [2, 1, 0, 0, 0],
          [0, 0, 1, 0, 2],
          [0, 0, 1, 1, 0],
          [0, 0, 2, 0, 0],
          [2, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [133, 13, 4, 1],
        [16, 1, 4]
      ],
      [
        [
          [5, -1, 0, 4, 4],
          [5, 5, 0, 4, -1],
          [3, 5, 0, 1, 1],
          [3, 3, 2, 2, 1],
          [3, -1, 2, 2, 1]
        ],
        [
          [0, 0, 2, 0, 2],
          [0, 0, 0, 0, 0],
          [0, 1, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [1, 0, 2, 0, 1]
        ]
      ]
    ],
    [
      [
        [3, 9, 0, 0, 2],
        [2, 1, 1, 2, 0],
        [2, 2, 0, 0, 1]
      ],
      [[137, 13, 5, 2]],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, 1, 1, 1],
          [-1, 5, 1, -1, 3],
          [4, 5, 5, 3, 3],
          [4, 4, 5, -1, 3]
        ],
        [
          [1, 0, 0, 0, 2],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 1],
          [2, 0, 0, 2, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [369, 22, "D4"],
        [22, 1, 5],
        [46, 5, 4],
        [77, 9, 5, 2]
      ],
      [
        [
          [5, -1, 2, 2, 0],
          [5, 5, 2, 2, 0],
          [3, 5, 4, 4, 0],
          [3, 3, 4, -1, 1],
          [3, -1, 1, 1, 1]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 0, 2, 0, 0],
          [0, 1, 0, 2, 0],
          [0, 2, 0, 0, 0],
          [1, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 1, 2, 0],
        [5, 3, 1, 2, 0]
      ],
      [
        [17, 1, 4],
        [60, 8, 0]
      ],
      [
        [
          [1, 1, 1, -1, 3],
          [1, 5, 5, 3, 3],
          [5, 5, 4, 4, 3],
          [2, 2, -1, 4, -1],
          [2, 2, 0, 0, 0]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 0, 2, 0],
          [1, 0, 0, 0, 0],
          [2, 1, 0, 2, 0],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [6, 6, 0, 3, 0],
        [2, 7, 0, 1, 2],
        [7, 2, 0, 3, 0]
      ],
      [[57, 7, 3]],
      [
        [
          [2, 2, 4, 4, 0],
          [2, 2, 3, 4, 0],
          [5, 3, 3, 3, 0],
          [5, 5, -1, -1, 1],
          [-1, 5, 1, 1, 1]
        ],
        [
          [1, 0, 0, 0, 0],
          [2, 0, 2, 2, 0],
          [0, 0, 0, 1, 2],
          [0, 0, 0, 0, 0],
          [0, 1, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 7, 3, 0, 3],
        [6, 0, 0, 0, 0]
      ],
      [
        [46, 5, 4],
        [138, 13, 4, 3]
      ],
      [
        [
          [1, 5, 4, 4, -1],
          [1, 5, 5, 4, -1],
          [1, 1, 5, 3, 0],
          [2, 2, 3, 3, 0],
          [2, 2, -1, 3, 0]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 1, 2],
          [0, 2, 2, 0, 0],
          [0, 1, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 0, 0, 1, 2],
        [3, 8, 3, 0, 0],
        [1, 9, 2]
      ],
      [[11, 1, 2]],
      [
        [
          [-1, 5, 5, 2, 2],
          [5, 5, 3, 2, 2],
          [4, 3, 3, 3, 1],
          [4, 4, 1, 1, 1],
          [-1, 0, 0, 0, -1]
        ],
        [
          [0, 0, 0, 2, 1],
          [1, 0, 2, 0, 0],
          [2, 0, 0, 1, 0],
          [0, 0, 1, 0, 0],
          [0, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 0, 0, 0],
        [7, 1, 3, 3, 0],
        [2, 6, 2, 2, 1]
      ],
      [[294, 20, 0, 4]],
      [
        [
          [2, 2, -1, 1, 1],
          [2, 2, 5, 5, 1],
          [-1, 5, 5, 3, 1],
          [4, 4, 3, 3, 3],
          [4, -1, 0, 0, 0]
        ],
        [
          [0, 0, 0, 0, 0],
          [1, 2, 0, 0, 0],
          [0, 1, 0, 2, 1],
          [0, 2, 0, 0, 1],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [3, 0, 3, 0, 0],
        [7, 1, 0, 3, 3]
      ],
      [[108, 11, 4, 3]],
      [
        [
          [0, -1, 5, 5, 3],
          [0, 5, 5, 3, 3],
          [0, 4, 4, -1, 3],
          [2, 2, 4, -1, 1],
          [2, 2, 1, 1, 1]
        ],
        [
          [0, 0, 0, 1, 1],
          [0, 0, 0, 2, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 2, 0, 0],
          [1, 2, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [3, 2, 2, 0, 0],
        [7, 4, 0, 1, 0]
      ],
      [
        [60, 8, 0],
        [161, 15, 2, 1]
      ],
      [
        [
          [2, 2, 3, 1, 1],
          [2, 2, 3, 3, 1],
          [0, -1, 3, 5, 1],
          [0, -1, 4, 5, 5],
          [0, -1, 4, 4, 5]
        ],
        [
          [1, 0, 0, 0, 0],
          [2, 0, 0, 2, 0],
          [2, 0, 1, 0, 1],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[2, 4, 0, 0, 2]],
      [
        [2, 1, 0],
        [196, 16, 5, 0],
        [86, 10, 1, 2]
      ],
      [
        [
          [1, 4, 4, 2, 2],
          [1, -1, 4, 2, 2],
          [1, 1, 5, 5, 3],
          [-1, 5, 5, 3, 3],
          [0, 0, 0, -1, 3]
        ],
        [
          [1, 0, 0, 0, 2],
          [0, 0, 2, 0, 1],
          [0, 0, 0, 1, 1],
          [0, 0, 0, 2, 0],
          [2, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 6, 2, 0, 1],
        [8, 2, 0, 0, 0],
        [0, 7, 2],
        [8, 1, 0, 0, 3]
      ],
      [],
      [
        [
          [1, 1, 1, 5, 5],
          [1, -1, 5, 5, 3],
          [0, 0, 0, 3, 3],
          [2, 2, -1, 4, 3],
          [2, 2, -1, 4, 4]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 0, 0, 1],
          [2, 0, 0, 2, 0],
          [0, 2, 0, 2, 0],
          [0, 1, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [1, 6, 3],
        [2, 7, 0, 1, 0]
      ],
      [[62, 8, 2]],
      [
        [
          [3, -1, -1, 2, 2],
          [3, 3, -1, 2, 2],
          [3, 4, 5, 1, 1],
          [4, 4, 5, 5, 1],
          [0, 0, 0, 5, 1]
        ],
        [
          [0, 0, 0, 2, 1],
          [0, 2, 0, 0, 0],
          [1, 0, 1, 0, 0],
          [2, 0, 0, 0, 0],
          [2, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 4, 0, 1, 1],
        [0, 3, 1]
      ],
      [
        [0, 1, 0],
        [151, 14, 2, 4]
      ],
      [
        [
          [5, 0, 0, 0, 1],
          [5, 5, 1, 1, 1],
          [-1, 5, 4, 4, -1],
          [-1, 3, 4, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [1, 0, 0, 2, 0],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 2, 0, 0, 2],
          [0, 0, 1, 0, 1]
        ]
      ]
    ],
    [
      [
        [5, 1, 3, 2, 0],
        [2, 2, 2, 1, 0]
      ],
      [
        [299, 20, 1, 4],
        [102, 11, 3, 1]
      ],
      [
        [
          [0, 2, 2, -1, 3],
          [0, 2, 2, 3, 3],
          [0, -1, 1, 5, 3],
          [4, -1, 1, 5, 5],
          [4, 4, 1, 1, 5]
        ],
        [
          [0, 1, 0, 0, 1],
          [0, 2, 0, 2, 0],
          [2, 0, 1, 0, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [4, 3, 0, 3, 0],
        [3, 2, 0, 0, 3],
        [2, 6, 1, 1, 0]
      ],
      [[372, 22, "E2"]],
      [
        [
          [1, 5, 3, 3, 3],
          [1, 5, 5, 3, -1],
          [1, 1, 5, -1, 0],
          [2, 2, -1, 4, 0],
          [2, 2, 4, 4, 0]
        ],
        [
          [1, 1, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [2, 1, 0, 0, 0],
          [0, 0, 2, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 1, 0, 0, 2],
        [4, 0, 0, 0, 3],
        [0, 6, 1]
      ],
      [[212, 17, 2, 1]],
      [
        [
          [0, 2, 2, -1, 4],
          [0, 2, 2, 4, 4],
          [0, -1, 5, 1, 1],
          [-1, 3, 5, 5, 1],
          [3, 3, 3, 5, 1]
        ],
        [
          [2, 2, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 1, 1]
        ]
      ]
    ],
    [
      [
        [3, 2, 2, 0, 0],
        [7, 2, 0, 3, 3],
        [0, 5, 2],
        [1, 3, 3]
      ],
      [],
      [
        [
          [-1, 3, 0, 2, 2],
          [3, 3, 0, 2, 2],
          [-1, 3, 0, 5, 5],
          [4, -1, 5, 5, 1],
          [4, 4, 1, 1, 1]
        ],
        [
          [0, 1, 0, 2, 1],
          [2, 0, 0, 0, 0],
          [0, 0, 2, 0, 0],
          [2, 0, 1, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [5, 5, 3, 0, 3],
        [7, 7, 3, 0, 0],
        [0, 1, 1],
        [0, 7, 1]
      ],
      [],
      [
        [
          [2, 2, 4, 4, 0],
          [2, 2, -1, 4, 0],
          [1, 3, 3, 3, 0],
          [1, -1, 3, 5, 5],
          [1, 1, 5, 5, -1]
        ],
        [
          [0, 2, 0, 0, 0],
          [0, 1, 0, 2, 0],
          [1, 1, 0, 0, 2],
          [0, 0, 2, 0, 1],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [4, 7, 0, 0, 3],
        [2, 0, 2, 1, 1]
      ],
      [[77, 9, 5, 2]],
      [
        [
          [5, -1, 2, 2, 0],
          [5, 5, 2, 2, 0],
          [3, 5, 4, 4, 0],
          [3, 3, -1, 4, 1],
          [3, -1, 1, 1, 1]
        ],
        [
          [1, 0, 1, 0, 2],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 2, 0],
          [1, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 9, 0, 0, 0],
        [2, 5, 0, 0, 2]
      ],
      [
        [73, 9, 1, 4],
        [127, 13, 2, 0]
      ],
      [
        [
          [0, 3, -1, 5, -1],
          [0, 3, 3, 5, 5],
          [0, 3, 1, 4, 5],
          [2, 2, 1, 4, 4],
          [2, 2, 1, 1, -1]
        ],
        [
          [2, 0, 0, 1, 0],
          [0, 0, 2, 0, 0],
          [0, 1, 1, 2, 0],
          [0, 2, 0, 0, 0],
          [0, 1, 0, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [203, 17, 0, 3],
        [23, 1, 5],
        [84, 10, 0, 4]
      ],
      [
        [
          [-1, -1, 3, 2, 2],
          [-1, 3, 3, 2, 2],
          [0, 1, 3, 5, 5],
          [0, 1, 5, 5, 4],
          [0, 1, 1, 4, 4]
        ],
        [
          [0, 0, 1, 2, 1],
          [0, 2, 0, 0, 0],
          [2, 1, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [7, 2, 0, 0, 3],
        [2, 3, 2, 1, 2]
      ],
      [
        [146, 14, 1, 2],
        [133, 13, 4, 1]
      ],
      [
        [
          [0, -1, 5, 4, 4],
          [0, -1, 5, 5, 4],
          [0, 1, 1, 5, 3],
          [2, 2, 1, 3, 3],
          [2, 2, 1, -1, 3]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [2, 0, 0, 0, 1],
          [2, 1, 0, 2, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 4, 0, 1, 2],
        [8, 0, 3, 3, 0],
        [0, 5, 1]
      ],
      [[158, 15, 3, 0]],
      [
        [
          [1, 1, -1, 5, -1],
          [-1, 1, 3, 5, 5],
          [0, 1, 3, 3, 5],
          [0, 4, 3, 2, 2],
          [0, 4, 4, 2, 2]
        ],
        [
          [0, 0, 0, 1, 0],
          [0, 0, 0, 0, 0],
          [0, 1, 0, 2, 0],
          [0, 2, 1, 0, 2],
          [2, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[2, 2, 0, 0, 2]],
      [
        [302, 20, 2, 1],
        [248, 18, 3, 2]
      ],
      [
        [
          [1, 1, 1, 5, 5],
          [1, 3, 5, 5, -1],
          [3, 3, -1, 2, 2],
          [4, 3, -1, 2, 2],
          [4, 4, 0, 0, 0]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 1, 1, 0, 0],
          [2, 0, 0, 0, 0],
          [2, 0, 0, 1, 2],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 5, 0, 0, 1],
        [2, 2, 2, 0, 1],
        [7, 8, 0, 3, 3]
      ],
      [[236, 18, 1, 0]],
      [
        [
          [2, 2, 1, 1, 1],
          [2, 2, 1, 5, 5],
          [-1, 0, 5, 5, 3],
          [-1, 0, 4, 3, 3],
          [-1, 0, 4, 4, 3]
        ],
        [
          [0, 0, 0, 0, 1],
          [1, 2, 0, 0, 1],
          [0, 2, 0, 0, 1],
          [0, 0, 2, 2, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[3, 7, 0, 0, 3]],
      [
        [13, 1, 3],
        [20, 1, 5],
        [138, 13, 3, 4]
      ],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 4, 4, 2, 2],
          [5, 4, 0, 0, 0],
          [5, 5, 3, 3, 3],
          [-1, 5, -1, 3, -1]
        ],
        [
          [0, 0, 1, 2, 1],
          [0, 0, 2, 0, 0],
          [1, 0, 0, 0, 2],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [0, 4, 1],
        [3, 8, 3, 0, 0],
        [2, 2, 0, 1, 1]
      ],
      [
        [0, 1, 0],
        [64, 8, 4]
      ],
      [
        [
          [5, -1, 0, 0, 0],
          [5, 5, -1, 2, 2],
          [1, 5, 3, 2, 2],
          [1, 3, 3, 4, 4],
          [1, 1, 3, -1, 4]
        ],
        [
          [1, 0, 0, 0, 2],
          [0, 0, 0, 2, 1],
          [1, 0, 1, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [[2, 9, 0, 1, 1]],
      [[181, 16, 2, 0]],
      [
        [
          [-1, 1, 1, 1, 3],
          [4, 1, -1, 3, 3],
          [4, 4, 5, 5, 3],
          [-1, 5, 5, 2, 2],
          [0, 0, 0, 2, 2]
        ],
        [
          [0, 0, 0, 1, 1],
          [2, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 1, 0, 2, 1],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [3, 1, 3, 0, 0],
        [5, 5, 0, 1, 2]
      ],
      [[105, 11, 2, 3]],
      [
        [
          [-1, -1, 0, 1, 1],
          [2, 2, 0, -1, 1],
          [2, 2, 0, 5, 1],
          [4, 4, 3, 5, 5],
          [4, 3, 3, 3, 5]
        ],
        [
          [0, 0, 2, 0, 0],
          [1, 0, 0, 0, 0],
          [2, 0, 0, 0, 1],
          [0, 2, 2, 0, 0],
          [0, 0, 0, 1, 1]
        ]
      ]
    ],
    [
      [[5, 3, 3, 1, 0]],
      [
        [3, 1, 0],
        [21, 1, 5],
        [115, 12, 0, 5]
      ],
      [
        [
          [3, 3, 3, 5, 5],
          [0, 3, 5, 5, 1],
          [0, 4, 1, 1, 1],
          [0, 4, 4, 2, 2],
          [-1, -1, -1, 2, 2]
        ],
        [
          [1, 0, 0, 0, 1],
          [2, 2, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [0, 0, 0, 1, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 1, 0, 0],
        [5, 0, 0, 3, 3]
      ],
      [[330, 21, 1, 5]],
      [
        [
          [2, 2, -1, 5, 5],
          [2, 2, 5, 5, 0],
          [4, 4, 3, 1, 0],
          [4, 3, 3, 1, 0],
          [-1, -1, 3, 1, 1]
        ],
        [
          [0, 0, 0, 0, 1],
          [1, 2, 0, 0, 2],
          [0, 2, 1, 1, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[2, 7, 2, 1, 1]],
      [
        [6, 1, 1],
        [318, 20, 5, 2]
      ],
      [
        [
          [1, -1, 3, 3, 3],
          [1, -1, 5, 3, 0],
          [1, 1, 5, 5, 0],
          [2, 2, 4, 5, 0],
          [2, 2, 4, 4, -1]
        ],
        [
          [1, 0, 1, 0, 0],
          [0, 0, 1, 2, 2],
          [0, 0, 0, 0, 0],
          [0, 2, 2, 0, 0],
          [0, 1, 0, 0, 0]
        ]
      ]
    ],
    [
      [[7, 9, 0, 3, 1]],
      [
        [0, 1, 0],
        [375, 22, "E5"],
        [151, 14, 4, 2]
      ],
      [
        [
          [1, 1, 1, 5, -1],
          [1, 2, 2, 5, 5],
          [3, 2, 2, 4, 5],
          [3, 3, 4, 4, -1],
          [3, 0, 0, 0, -1]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 2, 1, 0, 0],
          [0, 0, 0, 0, 1],
          [0, 2, 2, 0, 0],
          [1, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [[4, 6, 0, 0, 0]],
      [
        [2, 1, 0],
        [174, 16, 0, 4],
        [354, 22, "A4"]
      ],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, 4, 1, 1],
          [-1, -1, 4, 4, 1],
          [-1, 5, 5, 3, 1],
          [5, 5, 3, 3, 3]
        ],
        [
          [1, 0, 2, 0, 0],
          [2, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 2, 1],
          [1, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [0, 0, 1],
        [0, 6, 2]
      ],
      [[20, 1, 5]],
      [
        [
          [0, -1, 4, 2, 2],
          [0, 4, 4, 2, 2],
          [0, -1, 5, 1, 1],
          [-1, 3, 5, 5, 1],
          [3, 3, 3, 5, 1]
        ],
        [
          [0, 0, 0, 0, 2],
          [0, 2, 0, 0, 1],
          [2, 0, 1, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 0, 1]
        ]
      ]
    ],
    [
      [[7, 6, 3, 3, 0]],
      [
        [46, 5, 4],
        [28, 2, 4],
        [59, 7, 5]
      ],
      [
        [
          [1, 1, 1, 4, 0],
          [1, 5, 4, 4, 0],
          [-1, 5, 5, -1, 0],
          [2, 2, 5, 3, -1],
          [2, 2, 3, 3, 3]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 1, 2, 0],
          [2, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [4, 8, 3, 3, 0],
        [2, 1, 2, 1, 2],
        [2, 0, 0, 1, 0]
      ],
      [[75, 9, 2, 3]],
      [
        [
          [2, 2, 3, 3, 3],
          [2, 2, 0, 3, -1],
          [5, -1, 0, 4, 4],
          [5, 5, 0, 4, 1],
          [-1, 5, 1, 1, 1]
        ],
        [
          [0, 0, 1, 0, 0],
          [1, 2, 0, 2, 0],
          [1, 0, 0, 0, 2],
          [0, 0, 2, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 3, 2, 1, 0],
        [3, 7, 0, 0, 2],
        [2, 6, 0, 0, 0]
      ],
      [[81, 10, 1, 0]],
      [
        [
          [0, 0, 0, -1, -1],
          [2, 2, 4, 1, 1],
          [2, 2, 4, 4, 1],
          [-1, 5, 5, 3, 1],
          [5, 5, 3, 3, 3]
        ],
        [
          [0, 0, 2, 0, 0],
          [1, 0, 2, 0, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 2, 1],
          [1, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[2, 2, 0, 0, 1]],
      [
        [83, 10, 0, 3],
        [218, 17, 3, 2]
      ],
      [
        [
          [4, -1, 5, -1, 0],
          [4, 4, 5, 5, 0],
          [1, 1, 1, 5, 0],
          [1, 3, -1, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [2, 0, 1, 0, 2],
          [0, 0, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 2, 0, 2, 1],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 1, 2, 1],
        [2, 1, 2, 0, 0]
      ],
      [
        [95, 10, 5, 4],
        [24, 2, 0]
      ],
      [
        [
          [1, 1, 1, 4, 4],
          [1, 5, 5, -1, 4],
          [5, 5, 0, 0, 0],
          [-1, 3, -1, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 2, 0, 0],
          [0, 2, 0, 1, 0],
          [0, 0, 1, 2, 0]
        ]
      ]
    ],
    [
      [[2, 1, 0, 0, 2]],
      [
        [88, 10, 1, 4],
        [111, 12, 0, 1]
      ],
      [
        [
          [-1, 5, 5, 4, 4],
          [5, 5, 1, -1, 4],
          [1, 1, 1, 3, 0],
          [2, 2, 3, 3, 0],
          [2, 2, -1, 3, 0]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 0, 1, 2],
          [2, 1, 2, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[3, 2, 0, 3, 0]],
      [
        [48, 6, 0],
        [249, 18, 3, 4]
      ],
      [
        [
          [3, 3, 3, 5, 5],
          [-1, 3, 5, 5, -1],
          [2, 2, 4, 4, 0],
          [2, 2, 4, 1, 0],
          [-1, 1, 1, 1, 0]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [1, 2, 0, 0, 0],
          [0, 1, 0, 0, 2]
        ]
      ]
    ],
    [
      [[7, 2, 3, 0, 0]],
      [
        [133, 13, 4, 1],
        [341, 21, 4, 0]
      ],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, 4, 3, -1],
          [5, 4, 4, 3, 3],
          [5, 5, -1, 3, 1],
          [-1, 5, 1, 1, 1]
        ],
        [
          [2, 1, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 0, 2],
          [0, 0, 0, 1, 0],
          [0, 1, 1, 0, 0]
        ]
      ]
    ],
    [
      [[7, 1, 0, 0, 1]],
      [
        [11, 1, 2],
        [159, 15, 4, 0],
        [129, 13, 4, 0],
        [16, 1, 4]
      ],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, 1, 5, -1],
          [1, 1, 1, 5, 5],
          [4, 4, -1, 3, 5],
          [4, -1, 3, 3, 3]
        ],
        [
          [2, 1, 2, 0, 0],
          [0, 0, 0, 1, 0],
          [1, 0, 0, 0, 0],
          [0, 2, 0, 2, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[2, 1, 0, 0, 0]],
      [
        [271, 19, 2, 0],
        [8, 1, 2],
        [236, 18, 1, 0]
      ],
      [
        [
          [1, 1, 1, 4, 4],
          [1, 5, 5, 4, 0],
          [5, 5, 3, -1, 0],
          [2, 2, 3, 3, 0],
          [2, 2, 3, -1, -1]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [0, 2, 0, 2, 2],
          [0, 1, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 8, 3, 0, 1],
        [2, 6, 0, 1, 0]
      ],
      [
        [69, 9, 4, 0],
        [245, 18, 2, 5]
      ],
      [
        [
          [0, 2, 2, -1, 3],
          [0, 2, 2, 3, 3],
          [0, 1, 1, 5, 3],
          [4, -1, 1, 5, 5],
          [4, 4, 1, -1, 5]
        ],
        [
          [0, 0, 0, 0, 1],
          [0, 1, 2, 2, 0],
          [2, 0, 0, 1, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 6, 0, 0, 0],
        [0, 7, 2],
        [7, 9, 0, 3, 0]
      ],
      [[153, 14, 4, 3]],
      [
        [
          [2, 2, -1, 3, 0],
          [2, 2, 3, 3, 0],
          [1, 4, 4, 3, 0],
          [1, 4, -1, 5, 5],
          [1, 1, 5, 5, -1]
        ],
        [
          [1, 0, 0, 1, 2],
          [2, 0, 2, 0, 0],
          [1, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 3, 0, 1, 0],
        [0, 4, 1]
      ],
      [],
      [
        [
          [0, 0, 0, 3, -1],
          [2, 2, 3, 3, 3],
          [2, 2, -1, 5, 5],
          [4, 4, 5, 5, 1],
          [-1, 4, 1, 1, 1]
        ],
        [
          [2, 0, 0, 2, 0],
          [1, 0, 0, 0, 1],
          [2, 0, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 2, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 3, 1, 0, 2],
        [2, 4, 0, 0, 2],
        [3, 8, 0, 0, 0]
      ],
      [[2, 1, 0]],
      [
        [
          [1, 4, 3, 3, 3],
          [1, 4, 4, 3, -1],
          [1, 1, 5, 5, -1],
          [-1, 5, 5, 2, 2],
          [0, 0, 0, 2, 2]
        ],
        [
          [1, 2, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 1, 0],
          [0, 0, 0, 2, 1],
          [2, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 0, 0, 1, 0],
        [5, 6, 0, 0, 0]
      ],
      [
        [93, 10, 3, 4],
        [7, 1, 1]
      ],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 4, 4, 2, 2],
          [0, 4, -1, 5, -1],
          [0, -1, 3, 5, 5],
          [0, 3, 3, 3, 5]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 2, 1, 2],
          [0, 0, 0, 1, 0],
          [0, 0, 2, 0, 0],
          [2, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [
        [3, 4, 3, 0, 0],
        [7, 7, 0, 0, 3]
      ],
      [
        [78, 9, 3, 4],
        [372, 22, "E2"]
      ],
      [
        [
          [2, 2, 1, 1, 1],
          [2, 2, 1, 4, -1],
          [0, 3, 4, 4, -1],
          [0, 3, 3, 5, 5],
          [0, 3, 5, 5, -1]
        ],
        [
          [0, 2, 0, 0, 1],
          [0, 1, 0, 0, 0],
          [2, 0, 2, 0, 0],
          [0, 0, 2, 0, 1],
          [0, 1, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [6, 2, 3, 0, 0],
        [8, 6, 0, 0, 0]
      ],
      [
        [2, 1, 0],
        [341, 21, 4, 0]
      ],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, -1, 4, 4],
          [3, -1, 1, 5, 4],
          [3, 3, 1, 5, 5],
          [3, -1, 1, 1, 5]
        ],
        [
          [0, 0, 2, 0, 0],
          [1, 2, 0, 0, 0],
          [0, 0, 1, 0, 2],
          [0, 2, 0, 0, 0],
          [1, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [],
      [
        [1, 1, 0],
        [375, 22, "E5"],
        [371, 22, "E1"]
      ],
      [
        [
          [4, 4, 5, 5, -1],
          [4, 5, 5, 3, 0],
          [2, 2, 3, 3, 0],
          [2, 2, 1, 3, 0],
          [1, 1, 1, -1, -1]
        ],
        [
          [0, 2, 0, 0, 0],
          [0, 1, 0, 1, 0],
          [1, 0, 2, 0, 0],
          [2, 0, 0, 0, 2],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 5, 0, 0, 0],
        [2, 3, 0, 0, 0]
      ],
      [[342, 21, 4, 1]],
      [
        [
          [1, 1, 1, 3, -1],
          [1, 5, 3, 3, 3],
          [-1, 5, 5, 2, 2],
          [4, 4, 5, 2, 2],
          [-1, 4, 0, 0, 0]
        ],
        [
          [0, 0, 1, 2, 0],
          [0, 1, 0, 0, 1],
          [0, 0, 0, 2, 1],
          [0, 0, 0, 0, 0],
          [0, 2, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 2, 3, 0, 0],
        [6, 0, 3, 0, 0]
      ],
      [
        [95, 10, 4, 5],
        [264, 19, 0, 4]
      ],
      [
        [
          [3, 4, 4, 1, 1],
          [3, 3, 4, -1, 1],
          [3, -1, 0, 5, 1],
          [2, 2, 0, 5, 5],
          [2, 2, 0, -1, 5]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 2, 2, 0, 0],
          [1, 0, 2, 0, 1],
          [2, 1, 0, 0, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [2, 1, 2, 1, 2],
        [0, 7, 1]
      ],
      [[91, 10, 4, 2]],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 4, -1, 2, 2],
          [3, 4, 4, 5, 5],
          [3, 3, 5, 5, -1],
          [3, 0, 0, 0, -1]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 2, 0, 1, 2],
          [0, 0, 0, 0, 1],
          [0, 2, 0, 0, 0],
          [1, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [4, 8, 0, 0, 3],
        [2, 2, 2, 0, 0]
      ],
      [[350, 21, 5, 4]],
      [
        [
          [2, 2, 4, -1, 0],
          [2, 2, 4, 4, 0],
          [5, 1, 1, 3, 0],
          [5, 5, 1, 3, 3],
          [-1, 5, 1, 3, -1]
        ],
        [
          [0, 2, 2, 0, 0],
          [0, 1, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 0, 0, 0, 2],
          [0, 1, 1, 1, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 2, 0, 0],
        [6, 4, 0, 0, 0],
        [0, 2, 1],
        [2, 6, 0, 2, 2]
      ],
      [],
      [
        [
          [4, 4, 2, 2, -1],
          [3, 4, 2, 2, 0],
          [3, 3, -1, 1, 0],
          [3, 5, 5, 1, 0],
          [5, 5, -1, 1, 1]
        ],
        [
          [0, 0, 2, 1, 0],
          [0, 2, 0, 0, 0],
          [0, 2, 0, 1, 0],
          [1, 0, 0, 0, 2],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 1, 2, 0, 0],
        [2, 8, 0, 2, 1]
      ],
      [[193, 16, 4, 2]],
      [
        [
          [2, 2, -1, 5, -1],
          [2, 2, -1, 5, 5],
          [1, 4, 4, 3, 5],
          [1, 4, 3, 3, 3],
          [1, 1, 0, 0, 0]
        ],
        [
          [1, 0, 0, 1, 0],
          [2, 0, 0, 0, 0],
          [1, 0, 2, 2, 0],
          [0, 0, 0, 0, 1],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [[3, 7, 0, 0, 2]],
      [
        [353, 22, "A3"],
        [103, 11, 4, 1]
      ],
      [
        [
          [0, 0, 0, -1, 4],
          [3, 3, 3, 4, 4],
          [-1, 3, 1, 5, -1],
          [2, 2, 1, 5, 5],
          [2, 2, 1, 1, 5]
        ],
        [
          [0, 0, 2, 0, 0],
          [1, 0, 0, 2, 0],
          [0, 2, 1, 1, 0],
          [0, 0, 0, 0, 0],
          [1, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 6, 1, 0, 2],
        [2, 1, 2, 0, 0]
      ],
      [
        [361, 22, "C1"],
        [112, 12, 2, 0]
      ],
      [
        [
          [5, -1, -1, 2, 2],
          [5, 5, 0, 2, 2],
          [3, 5, 0, 4, 4],
          [3, 3, 0, 1, 4],
          [3, 1, 1, 1, -1]
        ],
        [
          [1, 0, 0, 2, 1],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 0, 2],
          [1, 1, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 2, 1, 0, 1],
        [1, 3, 3]
      ],
      [
        [153, 14, 3, 4],
        [187, 16, 3, 1]
      ],
      [
        [
          [5, -1, 2, 2, 0],
          [5, 5, 2, 2, 0],
          [1, 5, 4, -1, 0],
          [1, 4, 4, 3, -1],
          [1, 1, 3, 3, 3]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 0, 2, 0, 0],
          [1, 1, 0, 0, 0],
          [0, 2, 0, 2, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [3, 2, 0, 3, 0],
        [7, 6, 3, 0, 3],
        [8, 6, 0, 0, 3],
        [7, 1, 3, 3, 0]
      ],
      [],
      [
        [
          [5, 3, 3, 3, -1],
          [5, 5, 3, 2, 2],
          [1, 5, 0, 2, 2],
          [1, -1, 0, 4, 4],
          [1, 1, 0, -1, 4]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 0, 2, 1, 0],
          [1, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 2, 0, 2]
        ]
      ]
    ],
    [
      [
        [5, 3, 2, 0, 3],
        [3, 8, 0, 0, 0]
      ],
      [[2, 1, 0]],
      [
        [
          [0, 0, 0, -1, 3],
          [-1, 5, 5, 3, 3],
          [5, 5, 1, -1, 3],
          [2, 2, 1, 4, 4],
          [2, 2, 1, 1, 4]
        ],
        [
          [2, 0, 0, 0, 1],
          [0, 0, 1, 2, 0],
          [0, 0, 1, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 1, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 0, 0, 0, 2],
        [4, 1, 3, 3, 0],
        [2, 4, 0, 1, 1]
      ],
      [[305, 20, 2, 5]],
      [
        [
          [-1, 4, -1, 2, 2],
          [3, 4, 4, 2, 2],
          [3, 3, 1, 1, 0],
          [3, 5, 5, 1, 0],
          [5, 5, -1, 1, 0]
        ],
        [
          [0, 2, 0, 0, 0],
          [0, 0, 0, 1, 2],
          [0, 2, 0, 0, 2],
          [1, 0, 0, 0, 0],
          [1, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [
        [2, 8, 0, 0, 0],
        [2, 5, 1, 1, 1],
        [0, 8, 1]
      ],
      [[21, 1, 5]],
      [
        [
          [3, 3, 3, 4, 4],
          [-1, 3, 1, -1, 4],
          [1, 1, 1, 5, 5],
          [2, 2, 5, 5, -1],
          [2, 2, 0, 0, 0]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 0, 0, 2],
          [1, 0, 0, 0, 1],
          [0, 0, 0, 0, 0],
          [1, 2, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 8, 0, 0, 2],
        [3, 1, 3, 0, 0],
        [2, 4, 1, 0, 1]
      ],
      [[3, 1, 0]],
      [
        [
          [4, 4, 2, 2, 0],
          [4, -1, 2, 2, 0],
          [1, 1, 1, -1, 0],
          [1, 5, 5, 3, -1],
          [5, 5, 3, 3, 3]
        ],
        [
          [0, 2, 1, 0, 2],
          [0, 0, 2, 0, 0],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 2, 0],
          [1, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [0, 0, 1],
        [0, 8, 1],
        [5, 5, 2, 0, 3]
      ],
      [
        [290, 19, 5, 4],
        [2, 1, 0]
      ],
      [
        [
          [1, 1, 1, -1, 4],
          [1, 5, 5, 4, 4],
          [5, 5, -1, 3, -1],
          [2, 2, 3, 3, 3],
          [2, 2, 0, 0, 0]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 1, 2, 0],
          [0, 0, 0, 2, 0],
          [2, 1, 0, 0, 1],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [0, 7, 1],
        [2, 6, 0, 2, 2]
      ],
      [],
      [
        [
          [4, 4, 3, 1, 1],
          [4, 3, 3, 3, 1],
          [0, -1, -1, 5, 1],
          [0, 2, 2, 5, 5],
          [0, 2, 2, -1, 5]
        ],
        [
          [0, 2, 2, 0, 0],
          [0, 0, 0, 1, 0],
          [2, 0, 0, 0, 1],
          [0, 2, 1, 0, 0],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [[2, 8, 0, 1, 0]],
      [
        [129, 13, 4, 0],
        [123, 12, 3, 4],
        [310, 20, 3, 5]
      ],
      [
        [
          [5, -1, 2, 2, 0],
          [5, 5, 2, 2, 0],
          [1, 5, -1, 3, 0],
          [1, 4, 4, 3, 3],
          [1, 1, 4, 3, -1]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 0, 2, 0, 0],
          [1, 1, 0, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 0, 2, 1, 0]
        ]
      ]
    ],
    [
      [
        [5, 6, 1, 0, 3],
        [5, 5, 0, 0, 0],
        [2, 9, 1, 1, 0]
      ],
      [],
      [
        [
          [0, 1, -1, 5, 5],
          [0, 1, 5, 5, 3],
          [0, 1, 1, 3, 3],
          [-1, 2, 2, 4, 3],
          [-1, 2, 2, 4, 4]
        ],
        [
          [2, 1, 0, 0, 1],
          [0, 0, 0, 0, 1],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 2, 0],
          [0, 1, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 4, 2, 2, 1],
        [0, 6, 1]
      ],
      [[0, 1, 0]],
      [
        [
          [5, -1, 0, 0, 0],
          [5, 5, 3, 3, 3],
          [1, 5, -1, 3, -1],
          [1, 4, 4, 2, 2],
          [1, 1, 4, 2, 2]
        ],
        [
          [1, 0, 0, 0, 2],
          [0, 0, 1, 0, 0],
          [1, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 2, 1, 2]
        ]
      ]
    ],
    [
      [
        [4, 2, 3, 0, 3],
        [5, 3, 1, 3, 0],
        [8, 2, 0, 0, 1]
      ],
      [[234, 18, 0, 4]],
      [
        [
          [3, 3, 3, 5, 5],
          [-1, 3, 5, 5, 0],
          [-1, 4, 4, 1, 0],
          [2, 2, 4, 1, 0],
          [2, 2, -1, 1, 1]
        ],
        [
          [1, 0, 0, 0, 1],
          [0, 2, 0, 0, 0],
          [0, 0, 0, 1, 0],
          [1, 0, 2, 0, 2],
          [2, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [23, 1, 5],
        [170, 15, 5, 4],
        [195, 16, 4, 5]
      ],
      [
        [
          [0, 3, -1, 2, 2],
          [0, 3, 3, 2, 2],
          [0, 3, -1, 4, 4],
          [-1, 5, 5, 4, 1],
          [5, 5, 1, 1, 1]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 0, 2, 1, 2],
          [2, 1, 0, 0, 2],
          [0, 0, 0, 0, 0],
          [1, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [0, 4, 1],
        [2, 7, 0, 0, 1],
        [8, 8, 0, 0, 0],
        [7, 8, 1, 3, 0]
      ],
      [],
      [
        [
          [2, 2, 5, 4, 4],
          [2, 2, 5, 5, 4],
          [1, 1, 1, 5, 3],
          [1, -1, -1, 3, 3],
          [-1, 0, 0, 0, 3]
        ],
        [
          [1, 0, 0, 0, 0],
          [2, 0, 0, 0, 2],
          [0, 0, 1, 1, 1],
          [0, 0, 0, 2, 0],
          [0, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [[4, 6, 0, 3, 0]],
      [
        [2, 1, 0],
        [281, 19, 4, 0]
      ],
      [
        [
          [0, 0, 0, 3, -1],
          [2, 2, 3, 3, 3],
          [2, 2, 5, 1, 1],
          [4, -1, 5, 5, 1],
          [4, 4, -1, 5, 1]
        ],
        [
          [2, 0, 0, 2, 0],
          [0, 2, 0, 0, 1],
          [0, 1, 0, 0, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 1, 1]
        ]
      ]
    ],
    [
      [
        [5, 3, 0, 0, 0],
        [2, 0, 2, 0, 2],
        [3, 5, 0, 2, 0]
      ],
      [[79, 9, 5, 3]],
      [
        [
          [0, 0, 0, 2, 2],
          [4, 4, -1, 2, 2],
          [4, 5, 5, 3, -1],
          [5, 5, 1, 3, 3],
          [1, 1, 1, 3, -1]
        ],
        [
          [2, 0, 0, 0, 2],
          [0, 2, 0, 0, 1],
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [[2, 3, 2, 1, 2]],
      [
        [57, 7, 3],
        [153, 14, 4, 3]
      ],
      [
        [
          [1, 1, 1, 5, 5],
          [1, -1, 5, 5, 3],
          [0, 0, 0, 3, 3],
          [2, 2, 4, -1, 3],
          [2, 2, 4, 4, -1]
        ],
        [
          [0, 0, 1, 0, 1],
          [0, 0, 0, 0, 1],
          [2, 0, 0, 2, 0],
          [2, 1, 2, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 0, 0, 1],
        [2, 1, 0, 0, 0],
        [2, 6, 2, 2, 0],
        [1, 2, 4]
      ],
      [[320, 20, 5, 4]],
      [
        [
          [4, 4, 5, -1, 0],
          [4, -1, 5, 5, 0],
          [1, -1, 3, 5, 0],
          [1, 3, 3, 2, 2],
          [1, 1, 3, 2, 2]
        ],
        [
          [0, 2, 0, 0, 2],
          [0, 0, 0, 0, 0],
          [1, 0, 1, 1, 0],
          [0, 2, 0, 0, 2],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [1, 3, 2],
        [5, 1, 3, 2, 0]
      ],
      [[18, 1, 4]],
      [
        [
          [1, 5, -1, 2, 2],
          [1, 5, 5, 2, 2],
          [1, 1, 5, -1, 4],
          [3, 3, 3, 4, 4],
          [-1, 3, 0, 0, 0]
        ],
        [
          [1, 1, 0, 1, 0],
          [0, 0, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 2, 0],
          [0, 2, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [3, 1, 0, 0, 0],
        [2, 3, 2, 2, 0]
      ],
      [],
      [
        [
          [2, 2, -1, 1, 1],
          [2, 2, -1, -1, 1],
          [0, 0, 0, 5, 1],
          [4, 4, 3, 5, 5],
          [4, 3, 3, 3, 5]
        ],
        [
          [0, 0, 0, 0, 0],
          [1, 2, 0, 0, 0],
          [0, 0, 2, 1, 1],
          [0, 2, 2, 0, 0],
          [0, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [[2, 9, 2, 1, 0]],
      [
        [2, 1, 0],
        [145, 14, 0, 5],
        [144, 14, 0, 4]
      ],
      [
        [
          [-1, 0, 0, 0, -1],
          [3, 3, 3, 2, 2],
          [5, 3, 1, 2, 2],
          [5, 5, 1, 4, 4],
          [-1, 5, 1, 1, 4]
        ],
        [
          [0, 2, 0, 0, 0],
          [1, 0, 0, 2, 1],
          [1, 2, 1, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 3, 2, 0, 0],
        [7, 8, 1, 3, 0],
        [1, 7, 2]
      ],
      [[349, 21, 5, 3]],
      [
        [
          [4, 4, 3, 1, 1],
          [4, 3, 3, 3, 1],
          [2, 2, -1, 5, 1],
          [2, 2, -1, 5, 5],
          [-1, 0, 0, 0, 5]
        ],
        [
          [0, 2, 2, 0, 0],
          [0, 0, 0, 1, 0],
          [1, 0, 0, 1, 1],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [1, 8, 2],
        [2, 2, 1, 2, 1]
      ],
      [[219, 17, 3, 4]],
      [
        [
          [2, 2, -1, 3, 0],
          [2, 2, 3, 3, 0],
          [1, 5, -1, 3, 0],
          [1, 5, 5, 4, 4],
          [1, 1, 5, 4, -1]
        ],
        [
          [0, 2, 0, 1, 0],
          [0, 1, 2, 0, 0],
          [1, 1, 0, 0, 2],
          [0, 0, 0, 0, 2],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 3, 0, 0, 0],
        [2, 5, 0, 1, 0]
      ],
      [[149, 14, 1, 5]],
      [
        [
          [5, 1, 1, 2, 2],
          [5, 5, 1, 2, 2],
          [3, 5, 1, -1, 4],
          [3, 3, -1, 4, 4],
          [3, 0, 0, 0, -1]
        ],
        [
          [0, 0, 0, 1, 0],
          [0, 0, 0, 2, 0],
          [0, 1, 1, 0, 0],
          [0, 2, 0, 2, 0],
          [1, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 6, 0, 1, 2],
        [0, 7, 1]
      ],
      [
        [367, 22, "D2"],
        [78, 9, 3, 4]
      ],
      [
        [
          [1, 1, 1, 4, 4],
          [1, 5, 5, -1, 4],
          [5, 5, 3, 3, 3],
          [2, 2, -1, 3, -1],
          [2, 2, 0, 0, 0]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 1, 0, 0],
          [0, 2, 0, 2, 0],
          [0, 1, 2, 0, 0]
        ]
      ]
    ],
    [
      [[2, 2, 0, 0, 0]],
      [
        [62, 8, 2],
        [132, 13, 1, 3]
      ],
      [
        [
          [5, 3, 3, 3, 4],
          [5, 5, 3, 4, 4],
          [-1, 5, 0, 1, 1],
          [2, 2, 0, -1, 1],
          [2, 2, 0, -1, 1]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 0, 2, 2, 0],
          [0, 0, 0, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 1, 2, 0, 1]
        ]
      ]
    ],
    [
      [
        [3, 6, 0, 0, 2],
        [4, 0, 0, 3, 2],
        [2, 1, 1, 2, 2],
        [1, 0, 2]
      ],
      [],
      [
        [
          [-1, -1, 4, 4, 3],
          [2, 2, 4, 3, 3],
          [2, 2, 1, 5, 3],
          [1, 1, 1, 5, 5],
          [-1, 0, 0, 0, 5]
        ],
        [
          [0, 0, 0, 2, 1],
          [2, 1, 0, 2, 0],
          [0, 0, 0, 1, 0],
          [1, 0, 0, 0, 0],
          [0, 2, 0, 0, 0]
        ]
      ]
    ],
    [
      [[4, 2, 3, 3, 0]],
      [
        [132, 13, 3, 1],
        [76, 9, 4, 2]
      ],
      [
        [
          [3, 3, 3, 5, 5],
          [-1, 3, 5, 5, -1],
          [1, 4, 4, 2, 2],
          [1, -1, 4, 2, 2],
          [1, 1, 0, 0, 0]
        ],
        [
          [1, 0, 0, 0, 1],
          [0, 2, 0, 0, 0],
          [1, 0, 0, 0, 0],
          [0, 0, 2, 1, 2],
          [0, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [5, 9, 1, 0, 2],
        [0, 4, 1]
      ],
      [
        [52, 6, 4],
        [46, 5, 4]
      ],
      [
        [
          [0, 0, 0, 2, 2],
          [3, 3, 3, 2, 2],
          [1, 3, -1, 5, -1],
          [1, 4, 4, 5, 5],
          [1, 1, 4, -1, 5]
        ],
        [
          [2, 0, 0, 0, 2],
          [1, 0, 0, 0, 1],
          [1, 2, 0, 1, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [],
      [
        [22, 1, 5],
        [93, 10, 3, 4],
        [352, 22, "A2"],
        [52, 6, 4]
      ],
      [
        [
          [4, 4, 5, 1, 1],
          [-1, 4, 5, 5, 1],
          [0, -1, 3, 5, 1],
          [0, 3, 3, 2, 2],
          [0, -1, 3, 2, 2]
        ],
        [
          [0, 0, 0, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 1, 1],
          [0, 2, 0, 0, 0],
          [2, 0, 0, 1, 2]
        ]
      ]
    ],
    [
      [
        [0, 3, 1],
        [3, 2, 0, 3, 0],
        [0, 8, 3],
        [2, 6, 1, 0, 1],
        [3, 4, 0, 0, 0]
      ],
      [],
      [
        [
          [2, 2, 3, -1, 0],
          [2, 2, 3, 3, 0],
          [1, 5, 3, -1, 0],
          [1, 5, 5, -1, 4],
          [1, 1, 5, 4, 4]
        ],
        [
          [2, 1, 0, 0, 2],
          [0, 0, 0, 2, 0],
          [1, 1, 1, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [2, 8, 2, 2, 0],
        [2, 4, 0, 2, 0],
        [2, 7, 0, 0, 0]
      ],
      [[91, 10, 2, 4]],
      [
        [
          [3, 3, 3, 2, 2],
          [-1, 3, -1, 2, 2],
          [1, 5, 0, 0, 0],
          [1, 5, 5, -1, 4],
          [1, 1, 5, 4, 4]
        ],
        [
          [1, 0, 0, 2, 1],
          [0, 2, 0, 0, 0],
          [1, 1, 0, 0, 2],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [[2, 5, 0, 2, 0]],
      [
        [20, 1, 5],
        [52, 6, 4]
      ],
      [
        [
          [4, 4, 2, 2, 0],
          [-1, 4, 2, 2, 0],
          [-1, 3, 1, 5, 0],
          [3, 3, 1, 5, 5],
          [-1, 3, 1, 1, 5]
        ],
        [
          [0, 0, 2, 1, 2],
          [0, 2, 0, 0, 0],
          [0, 1, 1, 1, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [[6, 2, 3, 3, 1]],
      [
        [17, 1, 4],
        [122, 12, 2, 5]
      ],
      [
        [
          [0, 2, 2, 5, -1],
          [0, 2, 2, 5, 5],
          [0, 3, 3, 3, 5],
          [4, 4, 3, -1, 1],
          [-1, 4, 1, 1, 1]
        ],
        [
          [0, 0, 0, 1, 0],
          [0, 1, 2, 0, 0],
          [2, 1, 0, 0, 0],
          [0, 0, 2, 0, 0],
          [0, 2, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [3, 7, 0, 3, 0],
        [2, 1, 2, 0, 0]
      ],
      [
        [5, 1, 1],
        [131, 13, 1, 2]
      ],
      [
        [
          [3, 3, 3, 2, 2],
          [-1, 3, -1, 2, 2],
          [0, 0, 0, 5, 5],
          [-1, 4, 5, 5, 1],
          [4, 4, 1, 1, 1]
        ],
        [
          [1, 0, 0, 2, 1],
          [0, 2, 0, 0, 0],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 0],
          [2, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [5, 8, 0, 3, 1],
        [2, 1, 1, 2, 2],
        [1, 5, 2]
      ],
      [[281, 19, 4, 0]],
      [
        [
          [3, 3, 3, 2, 2],
          [0, 3, 5, 2, 2],
          [0, 1, 5, 5, -1],
          [0, 1, -1, 5, 4],
          [-1, 1, 1, 4, 4]
        ],
        [
          [1, 0, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [0, 1, 0, 0, 0],
          [2, 0, 0, 1, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [3, 2, 0, 3, 0],
        [5, 0, 0, 3, 3]
      ],
      [[159, 15, 0, 4]],
      [
        [
          [3, -1, 0, 2, 2],
          [3, 3, 0, 2, 2],
          [3, 5, 0, 1, -1],
          [4, 5, 5, 1, -1],
          [4, 4, 5, 1, 1]
        ],
        [
          [0, 0, 2, 0, 0],
          [0, 2, 0, 1, 2],
          [1, 1, 0, 1, 0],
          [2, 0, 0, 0, 0],
          [0, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 8, 1, 2, 2],
        [1, 6, 3]
      ],
      [
        [2, 1, 0],
        [281, 19, 4, 0],
        [13, 1, 3]
      ],
      [
        [
          [4, -1, 0, 0, 0],
          [4, 4, 3, 3, 3],
          [1, 5, -1, 3, -1],
          [1, 5, 5, 2, 2],
          [1, 1, 5, 2, 2]
        ],
        [
          [2, 0, 2, 0, 0],
          [0, 0, 1, 0, 0],
          [1, 1, 0, 2, 0],
          [0, 0, 0, 1, 0],
          [0, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [3, 0, 0, 0, 2],
        [4, 6, 0, 0, 0],
        [0, 7, 1],
        [4, 1, 3, 0, 3]
      ],
      [],
      [
        [
          [3, -1, -1, -1, 0],
          [3, 3, 4, 4, 0],
          [3, 5, 5, 4, 0],
          [5, 5, 1, 2, 2],
          [1, 1, 1, 2, 2]
        ],
        [
          [0, 0, 0, 0, 2],
          [0, 2, 0, 0, 0],
          [1, 0, 0, 2, 0],
          [1, 0, 0, 1, 0],
          [1, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [[5, 9, 0, 1, 2]],
      [
        [133, 13, 1, 4],
        [2, 1, 0],
        [241, 18, 2, 0]
      ],
      [
        [
          [5, 3, 3, 3, 4],
          [5, 5, 3, 4, 4],
          [1, 5, -1, 2, 2],
          [1, -1, -1, 2, 2],
          [1, 1, 0, 0, 0]
        ],
        [
          [0, 1, 0, 0, 0],
          [0, 0, 2, 2, 0],
          [1, 1, 0, 0, 2],
          [0, 0, 0, 0, 1],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 0, 0, 2],
        [7, 4, 0, 0, 0]
      ],
      [
        [143, 14, 0, 3],
        [10, 1, 2]
      ],
      [
        [
          [-1, 3, 1, 1, 1],
          [3, 3, 1, 5, 5],
          [0, 3, 5, 5, -1],
          [0, 4, 4, 2, 2],
          [0, 4, -1, 2, 2]
        ],
        [
          [0, 1, 0, 0, 1],
          [2, 0, 0, 0, 1],
          [0, 0, 0, 0, 0],
          [0, 0, 2, 1, 0],
          [2, 0, 0, 2, 0]
        ]
      ]
    ],
    [
      [
        [3, 2, 0, 0, 2],
        [2, 5, 2, 1, 0],
        [4, 8, 3, 0, 2]
      ],
      [[170, 15, 4, 5]],
      [
        [
          [2, 2, 0, 4, -1],
          [2, 2, 0, 4, 4],
          [1, 5, 0, 3, -1],
          [1, 5, 5, 3, 3],
          [1, 1, 5, 3, -1]
        ],
        [
          [2, 1, 0, 2, 0],
          [0, 0, 0, 0, 0],
          [1, 1, 2, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [
        [2, 3, 2, 2, 1],
        [2, 6, 0, 0, 0],
        [2, 1, 2, 0, 0]
      ],
      [[68, 9, 0, 3]],
      [
        [
          [1, 1, 1, 3, -1],
          [1, 5, 5, 3, 3],
          [5, 5, 0, 3, -1],
          [4, -1, 0, 2, 2],
          [4, 4, 0, 2, 2]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 0, 2],
          [1, 0, 0, 1, 0],
          [2, 0, 0, 2, 1],
          [0, 0, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [4, 3, 0, 3, 2],
        [0, 6, 1],
        [2, 8, 0, 2, 1]
      ],
      [[204, 17, 0, 4]],
      [
        [
          [1, 1, 1, 2, 2],
          [1, 5, -1, 2, 2],
          [3, 5, 5, -1, 4],
          [3, 3, 5, 4, 4],
          [3, -1, 0, 0, 0]
        ],
        [
          [0, 0, 1, 0, 0],
          [0, 0, 0, 1, 2],
          [0, 0, 0, 0, 0],
          [0, 2, 1, 2, 0],
          [1, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [
        [2, 5, 0, 1, 1],
        [2, 2, 2, 0, 0]
      ],
      [[111, 12, 0, 1]],
      [
        [
          [1, 2, 2, 4, 4],
          [1, 2, 2, 3, 4],
          [1, 1, 3, 3, 0],
          [-1, 5, 5, 3, 0],
          [5, 5, -1, -1, 0]
        ],
        [
          [1, 1, 0, 0, 0],
          [0, 2, 0, 1, 2],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [[2, 3, 0, 2, 2]],
      [
        [91, 10, 2, 4],
        [93, 10, 3, 4],
        [170, 15, 5, 4]
      ],
      [
        [
          [0, 0, 0, 2, 2],
          [1, 1, 1, 2, 2],
          [1, 3, 3, 3, -1],
          [4, -1, 3, 5, 5],
          [4, 4, 5, 5, -1]
        ],
        [
          [2, 0, 0, 0, 2],
          [0, 0, 1, 0, 1],
          [0, 1, 0, 0, 0],
          [2, 0, 2, 0, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 6, 2, 0, 0],
        [6, 7, 0, 0, 1]
      ],
      [
        [1, 1, 0],
        [336, 21, 3, 0]
      ],
      [
        [
          [0, 4, 4, 1, 1],
          [0, 4, 5, 5, 1],
          [0, 5, 5, -1, 1],
          [-1, 3, -1, 2, 2],
          [3, 3, 3, 2, 2]
        ],
        [
          [0, 0, 2, 0, 0],
          [0, 0, 0, 1, 0],
          [2, 0, 0, 0, 1],
          [0, 2, 0, 0, 2],
          [0, 0, 1, 0, 1]
        ]
      ]
    ],
    [
      [[2, 6, 0, 0, 0]],
      [
        [19, 1, 4],
        [64, 8, 4],
        [214, 17, 2, 4]
      ],
      [
        [
          [5, 1, 1, -1, 3],
          [5, 5, 1, 3, 3],
          [0, 5, 1, -1, 3],
          [0, 2, 2, 4, -1],
          [0, 2, 2, 4, 4]
        ],
        [
          [1, 0, 0, 0, 1],
          [0, 0, 0, 2, 0],
          [2, 0, 1, 0, 0],
          [0, 0, 2, 2, 0],
          [0, 0, 1, 0, 0]
        ]
      ]
    ],
    [
      [
        [5, 0, 3, 1, 0],
        [2, 7, 2, 1, 0],
        [3, 0, 3, 2, 3]
      ],
      [[72, 9, 1, 3]],
      [
        [
          [2, 2, 0, 0, 0],
          [2, 2, -1, 1, 1],
          [4, 4, -1, -1, 1],
          [4, 5, 5, 3, 1],
          [5, 5, 3, 3, 3]
        ],
        [
          [1, 0, 2, 0, 0],
          [2, 0, 0, 0, 0],
          [0, 2, 0, 0, 0],
          [0, 0, 1, 2, 1],
          [0, 0, 0, 0, 1]
        ]
      ]
    ],
    [
      [
        [7, 6, 3, 0, 0],
        [5, 6, 0, 3, 3],
        [0, 5, 2],
        [4, 3, 3, 0, 3]
      ],
      [],
      [
        [
          [2, 2, -1, 1, 1],
          [2, 2, 5, 5, 1],
          [-1, 5, 5, 3, 1],
          [4, 4, 3, 3, 3],
          [-1, 4, 0, 0, 0]
        ],
        [
          [1, 0, 0, 0, 0],
          [2, 0, 0, 1, 0],
          [0, 0, 0, 2, 1],
          [0, 0, 0, 0, 1],
          [0, 2, 2, 0, 0]
        ]
      ]
    ],
    [
      [
        [7, 6, 3, 0, 3],
        [2, 4, 2, 1, 0]
      ],
      [
        [28, 2, 4],
        [78, 9, 3, 4]
      ],
      [
        [
          [1, 1, 1, 4, 0],
          [1, 5, 4, 4, 0],
          [-1, 5, 5, 3, 0],
          [2, 2, 5, 3, 3],
          [2, 2, -1, 3, -1]
        ],
        [
          [0, 0, 1, 0, 2],
          [0, 0, 2, 0, 0],
          [0, 0, 0, 0, 0],
          [1, 0, 1, 0, 2],
          [2, 0, 0, 1, 0]
        ]
      ]
    ],
    [
      [
        [2, 7, 0, 0, 2],
        [5, 9, 3, 0, 1]
      ],
      [
        [56, 7, 2],
        [27, 2, 3]
      ],
      [
        [
          [1, -1, 3, 4, 4],
          [1, 3, 3, 3, 4],
          [1, 1, -1, 2, 2],
          [-1, 5, 5, 2, 2],
          [5, 5, 0, 0, 0]
        ],
        [
          [1, 0, 2, 0, 0],
          [0, 0, 0, 1, 2],
          [0, 0, 0, 2, 1],
          [0, 0, 0, 0, 0],
          [1, 0, 0, 0, 2]
        ]
      ]
    ],
    [
      [[2, 8, 1, 2, 2]],
      [
        [371, 22, "E1"],
        [233, 18, 0, 3],
        [109, 11, 3, 5]
      ],
      [
        [
          [4, 0, 0, 0, -1],
          [4, 4, 3, 3, 3],
          [1, 5, -1, 3, -1],
          [1, 5, 5, 2, 2],
          [1, 1, 5, 2, 2]
        ],
        [
          [2, 2, 0, 0, 0],
          [0, 0, 1, 0, 0],
          [1, 0, 0, 2, 0],
          [0, 0, 0, 1, 0],
          [0, 0, 1, 2, 0]
        ]
      ]
    ],
    [
      [
        [2, 6, 0, 0, 0],
        [3, 0, 0, 0, 3]
      ],
      [
        [169, 15, 5, 3],
        [171, 16, 0, 1]
      ],
      [
        [
          [-1, 4, 4, 3, 0],
          [5, 4, 3, 3, 0],
          [5, 5, -1, 3, 0],
          [-1, 5, 1, 2, 2],
          [1, 1, 1, 2, 2]
        ],
        [
          [0, 0, 2, 1, 0],
          [1, 0, 2, 0, 0],
          [0, 0, 0, 0, 2],
          [0, 0, 0, 2, 1],
          [1, 0, 0, 0, 0]
        ]
      ]
    ],
    [
      [
        [2, 2, 2, 1, 0],
        [2, 1, 0, 0, 0]
      ],
      [
        [153, 14, 3, 4],
        [342, 21, 4, 1]
      ],
      [
        [
          [2, 2, 1, 5, -1],
          [2, 2, 1, 5, 5],
          [0, 3, 1, 1, 5],
          [0, 3, 3, 4, -1],
          [0, 3, 4, 4, -1]
        ],
        [
          [2, 1, 1, 0, 0],
          [0, 0, 0, 0, 0],
          [2, 0, 0, 0, 1],
          [0, 0, 2, 0, 0],
          [0, 1, 2, 0, 0]
        ]
      ]
    ],
    [
      [[2, 1, 2, 0, 0]],
      [
        [169, 15, 5, 3],
        [251, 18, 4, 0],
        [283, 19, 4, 2]
      ],
      [
        [
          [-1, 5, 5, 2, 2],
          [5, 5, 4, 2, 2],
          [0, 1, 4, 4, 3],
          [0, 1, -1, 3, 3],
          [0, 1, 1, -1, 3]
        ],
        [
          [0, 0, 1, 2, 1],
          [0, 0, 2, 0, 0],
          [0, 1, 0, 0, 1],
          [0, 0, 0, 2, 0],
          [2, 0, 0, 0, 0]
        ]
      ]
    ]
  ]
};

export default dataDiscs;
