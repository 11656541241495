import { useEffect, useLayoutEffect, useState } from "react";
import { Parser } from "html-to-react";
import Draggable from "react-draggable";
import { useSpring, animated } from "@react-spring/web";

import Board from "./images/Board.png";
import Button1 from "./images/Button1.png";
import TP_I from "./images/TP_I.png";
import TP_L from "./images/TP_L.png";
import TP_O from "./images/TP_O.png";
import TP_T from "./images/TP_T.png";
import TP_V from "./images/TP_V.png";
import TP_Z from "./images/TP_Z.png";

import ImgIndice from "./ImgIndice";

import idPage from "./idPage";
import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function InputPuzzleSolution(props) {
  var divRef = null;

  const [dimensions, setDimensions] = useState({
    width: 1,
    height: 1
  });

  const handleImageLoad = (event) => {
    divRef = event.target;
    setDimensions({
      width: Math.round(event.target.clientWidth),
      height: Math.round(event.target.clientHeight)
    });
    setBoardLoaded(true);
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (divRef) {
        setDimensions({
          width: Math.round(divRef.clientWidth),
          height: Math.round(divRef.clientHeight)
        });
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getStartX = () => {
    var value = 79 * (dimensions.width / 400);
    return value;
    //    return ((534 - caseSizeX) * dimensions.width) / 1668;
  };

  const getStartY = () => {
    var value = 79 * (dimensions.height / 400);
    return value;
    //    return ((530 - caseSizeY) * dimensions.height) / 1668;
  };

  const getCaseSizeX = () => {
    var value = 49 * (dimensions.width / 400);
    return value;
    //    return (caseSizeX * dimensions.width) / 1668;
  };

  const getCaseSizeY = () => {
    var value = 49 * (dimensions.width / 400);
    return value;
    //    return (caseSizeY * dimensions.height) / 1668;
  };

  const [spring1, api1] = useSpring(() => ({
    from: {
      scale: 1.2,
      opacity: 0.5,
      transform: "rotate(0deg)",
      left: 0,
      top: 0
    }
  }));
  const [spring2, api2] = useSpring(() => ({
    from: {
      scale: 1.2,
      opacity: 0.5,
      transform: "rotate(0deg)",
      left: 0,
      top: 0
    }
  }));
  const [spring3, api3] = useSpring(() => ({
    from: {
      scale: 1.2,
      opacity: 0.5,
      transform: "rotate(0deg)",
      left: 0,
      top: 0
    }
  }));
  const [spring4, api4] = useSpring(() => ({
    from: {
      scale: 1.2,
      opacity: 0.5,
      transform: "rotate(0deg)",
      left: 0,
      top: 0
    }
  }));
  const [spring5, api5] = useSpring(() => ({
    from: {
      scale: 1.2,
      opacity: 0.5,
      transform: "rotate(0deg)",
      left: 0,
      top: 0
    }
  }));
  const [spring6, api6] = useSpring(() => ({
    from: {
      scale: 1.2,
      opacity: 0.5,
      transform: "rotate(0deg)",
      left: 0,
      top: 0
    }
  }));
  const springs = [spring1, spring2, spring3, spring4, spring5, spring6];
  const apis = [api1, api2, api3, api4, api5, api6];
  const opacityDuringMove = 0.5;
  const zoomDurinMove = 1.1;
  const opacityGrid = 0.5;

  const pieces = [TP_I, TP_L, TP_O, TP_T, TP_V, TP_Z];

  const [firstMove, setFirstMove] = useState(true);
  const [boardLoaded, setBoardLoaded] = useState(false);
  const [victory, setVictory] = useState(false);
  const [update, setUpdate] = useState(true);
  const [updatePositionsPieces, setUpdatePositionsPieces] = useState(true);
  const [oldCoordX, setOldCoordX] = useState(0);
  const [oldCoordY, setOldCoordY] = useState(0);
  const [pieceMoved, setPieceMoved] = useState(false);
  const [shadows, setShadows] = useState(["", "", "", "", "", ""]);
  const [zIndex, setZIndex] = useState([10, 20, 30, 40, 50, 60]);
  const [boardReady, setBoardReady] = useState(0);
  let boardIDPiece = [
    [-1, -1, -1, -1, -1],
    [-1, -1, -1, -1, -1],
    [-1, -1, -1, -1, -1],
    [-1, -1, -1, -1, -1],
    [-1, -1, -1, -1, -1]
  ];
  const [piecesPos, setPiecesPos] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0]
  ]);
  const [currentDraging, setCurrentDraging] = useState(-1);
  const piecesSize = [
    [3, 1],
    [2, 3],
    [2, 2],
    [2, 3],
    [2, 2],
    [2, 3]
  ];
  const [boardColor, setBoardColor] = useState([
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0]
  ]);
  const [piecesRotation, setPiecesRotation] = useState([0, 2, 0, 3, 3, 1]);
  const [piecesSpot, setPiecesSpot] = useState([
    [
      [0, 0],
      [1, 0],
      [2, 0]
    ],
    [
      [1, 2],
      [0, 2],
      [0, 1],
      [0, 0]
    ],
    [
      [0, 0],
      [1, 0],
      [0, 1],
      [1, 1]
    ],
    [
      [0, 1],
      [1, 1],
      [1, 0],
      [2, 1]
    ],
    [
      [0, 1],
      [0, 0],
      [1, 1]
    ],
    [
      [2, 0],
      [1, 0],
      [1, 1],
      [0, 1]
    ]
  ]);
  const posIndices = [
    [0, 0, 2],
    [0, 0, 0, 1],
    [0, 2, 0, 1],
    [0, 0, 2, 1],
    [0, 2, 0],
    [1, 0, 0, 0]
  ];

  const getMinXTab = (tab) => {
    let x = 10;
    tab.map((coord) => {
      if (coord[0] < x) x = coord[0];
      return coord;
    });
    return x;
  };

  const getMaxXTab = (tab) => {
    let x = 0;
    tab.map((coord) => {
      if (coord[0] > x) x = coord[0];
      return coord;
    });
    return x;
  };

  const getMinYTab = (tab) => {
    let y = 10;
    tab.map((coord) => {
      if (coord[1] < y) y = coord[1];
      return coord;
    });
    return y;
  };

  const getMaxYTab = (tab) => {
    let y = 0;
    tab.map((coord) => {
      if (coord[1] > y) y = coord[1];
      return coord;
    });
    return y;
  };

  const getDecX = (i) => {
    if (piecesRotation[i] % 2 !== 0) {
      switch (i) {
        case 0:
          return getCaseSizeX();
        case 1:
          return -getCaseSizeX() * 0.5;
        case 2:
          return 0;
        case 3:
          return -getCaseSizeX() * 0.5;
        case 4:
          return 0;
        case 5:
          return -getCaseSizeX() * 0.5;
      }
    } else return 0;
  };

  const getDecY = (i) => {
    if (piecesRotation[i] % 2 !== 0) {
      switch (i) {
        case 0:
          return -getCaseSizeY();
        case 1:
          return getCaseSizeY() * 0.5;
        case 2:
          return 0;
        case 3:
          return getCaseSizeY() * 0.5;
        case 4:
          return 0;
        case 5:
          return getCaseSizeY() * 0.5;
      }
    } else return 0;
  };

  const refreshPositionsPieces = () => {
    if (!boardLoaded) return;
    if (firstMove) {
      setFirstMove(false);
      const newPiecesPos = [
        [getStartX() + 3 * getCaseSizeX(), getStartY() + 10 * getCaseSizeY()],
        [getStartX() - 1 * getCaseSizeX(), getStartY() + 8 * getCaseSizeY()],
        [getStartX() + 4 * getCaseSizeX(), getStartY() + 8 * getCaseSizeY()],
        [getStartX() + 1 * getCaseSizeX(), getStartY() + 7 * getCaseSizeY()],
        [getStartX() - 0 * getCaseSizeX(), getStartY() + 8 * getCaseSizeY()],
        [getStartX() + 1 * getCaseSizeX(), getStartY() + 9 * getCaseSizeY()]
      ];
      setPiecesPos(newPiecesPos);
      setUpdatePositionsPieces(!updatePositionsPieces);
      return;
    }
    apis.map((tab, index) => {
      var scaleValue = 1;
      var opacityValue = 1;
      var transformValue = "rotate(" + piecesRotation[index] * 90 + "deg)";
      var leftValue = piecesPos[index][0] - getDecX(index);
      var topValue = piecesPos[index][1] - getDecY(index);
      if (currentDraging === index) {
        scaleValue = zoomDurinMove;
        opacityValue = opacityDuringMove;
        tab.set({
          left: leftValue,
          top: topValue
        });
      }
      tab.start({
        to: {
          scale: scaleValue,
          opacity: opacityValue,
          transform: transformValue,
          left: leftValue,
          top: topValue
        }
      });
      return 0;
    });
  };

  const checkSol = () => {
    if (boardReady === 1) {
      showGreenPieces();
    }
  };

  const showGreenPieces = () => {
    boardIDPiece = [
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1]
    ];
    let boardIndices = [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ];
    for (let idPiece = 0; idPiece < 6; idPiece++) {
      const coordX = Math.round(
        (piecesPos[idPiece][0] - getStartX()) / getCaseSizeX()
      );
      const coordY = Math.round(
        (piecesPos[idPiece][1] - getStartY()) / getCaseSizeY()
      );
      piecesSpot[idPiece].map((coord, index) => {
        const thisCoord = [coordX + coord[0], coordY + coord[1]];
        if (
          thisCoord[0] < 0 ||
          thisCoord[0] > 4 ||
          thisCoord[1] < 0 ||
          thisCoord[1] > 4
        ) {
          return 0;
        }
        boardIDPiece[thisCoord[1]][thisCoord[0]] = idPiece;
        boardIndices[thisCoord[1]][thisCoord[0]] = posIndices[idPiece][index];
        return 0;
      });
    }
    let incorrectPieces = [];
    for (let coordX = 0; coordX < 5; coordX++) {
      for (let coordY = 0; coordY < 5; coordY++) {
        if (
          boardIDPiece[coordY][coordX] !==
          dataDiscs["dailyPuzzles"][
            props.dailyGame % dataDiscs["dailyPuzzles"].length
          ][2][0][coordY][coordX]
        ) {
          incorrectPieces.push(boardIDPiece[coordY][coordX]);
        }
        if (
          boardIndices[coordY][coordX] !==
          dataDiscs["dailyPuzzles"][
            props.dailyGame % dataDiscs["dailyPuzzles"].length
          ][2][1][coordY][coordX]
        ) {
          incorrectPieces.push(boardIDPiece[coordY][coordX]);
        }
      }
    }
    let victoire = true;
    const grid = boardColor.map((tab, y) => {
      return tab.map((value, x) => {
        if (boardIDPiece[y][x] === -1) {
          return 0;
        } else if (incorrectPieces.includes(boardIDPiece[y][x])) {
          victoire = false;
          return 2;
        } else return 3;
      });
    });
    setBoardColor(grid);
    setVictory(victoire);
  };

  const dragEnd = (i, decX, decY) => {
    const shadowsTab = shadows.map((value, index) => {
      if (index === i) return "";
      else return value;
    });
    setShadows(shadowsTab);
    setCurrentDraging(-1);
    const grid = boardColor.map((tab) => {
      return tab.map((value) => {
        if (value === 1) return 0;
        else return value;
      });
    });
    setBoardColor(grid);
    const newZIndex = zIndex.map((z, index) => {
      if (index === i) return 60;
      else return z;
    });
    setZIndex(newZIndex);
    const newPiecesPos = piecesPos.map((pos, idPiece) => {
      if (i === idPiece) {
        return [
          Math.round((pos[0] - getStartX()) / getCaseSizeX()) * getCaseSizeX() +
            getStartX(),
          Math.round((pos[1] - getStartY()) / getCaseSizeY()) * getCaseSizeY() +
            getStartY()
        ];
      } else {
        return pos;
      }
    });
    setPiecesPos(newPiecesPos);
    if (!pieceMoved) {
      piecesRotation[i] = piecesRotation[i] + 1;
      rotatePiece(i, decX, decY);
    }
    setUpdate(!update);
    setUpdatePositionsPieces(!updatePositionsPieces);
  };

  useEffect(() => {
    refreshBoard();
  }, [update]);

  useEffect(() => {
    refreshPositionsPieces();
  }, [updatePositionsPieces]);

  useEffect(() => {
    refreshPositionsPieces();
  }, [boardLoaded]);

  const refreshBoard = () => {
    boardIDPiece = [
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1],
      [-1, -1, -1, -1, -1]
    ];
    let boardSuperpositions = [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ];
    let boardIndices = [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ];
    let boardCorrect = true;
    for (let idPiece = 0; idPiece < 6; idPiece++) {
      const coordX = Math.round(
        (piecesPos[idPiece][0] - getStartX()) / getCaseSizeX()
      );
      const coordY = Math.round(
        (piecesPos[idPiece][1] - getStartY()) / getCaseSizeY()
      );
      piecesSpot[idPiece].map((coord, index) => {
        const thisCoord = [coordX + coord[0], coordY + coord[1]];
        if (
          thisCoord[0] < 0 ||
          thisCoord[0] > 4 ||
          thisCoord[1] < 0 ||
          thisCoord[1] > 4
        ) {
          boardCorrect = false;
          return 0;
        }
        if (boardIDPiece[thisCoord[1]][thisCoord[0]] !== -1) {
          boardCorrect = false;
          boardSuperpositions[thisCoord[1]][thisCoord[0]]++;
        }
        boardIDPiece[thisCoord[1]][thisCoord[0]] = idPiece;
        boardIndices[thisCoord[1]][thisCoord[0]] = posIndices[idPiece][index];
        return 0;
      });
    }
    const grid = boardColor.map((tab, y) => {
      return tab.map((value, x) => {
        if (boardSuperpositions[y][x] > 0) return 2;
        else return 0;
      });
    });
    setBoardColor(grid);
    if (boardCorrect) {
      setBoardReady(1);
    } else setBoardReady(0);
  };

  const rotatePiece = (i, decX, decY) => {
    let newDecX = -1;
    let newDecY = -1;
    if (i === 2) {
      decX = 1;
      decY = 1;
    }
    const newPiecesSpotTemp = piecesSpot.map((tab, index) => {
      if (index === i) {
        return tab.map((coord) => {
          if (coord[0] === decX && coord[1] === decY) {
            if (i === 2) {
              newDecX = getMaxYTab(tab) - coord[1] + 1;
              newDecY = coord[0];
            } else {
              newDecX = getMaxYTab(tab) - coord[1];
              newDecY = coord[0];
            }
          }
          return [getMaxYTab(tab) - coord[1], coord[0]];
        });
      } else {
        return tab;
      }
    });
    const newPiecesSpot = newPiecesSpotTemp.map((tab, index) => {
      if (index === i) {
        let minX = getMinXTab(tab);
        let minY = getMinYTab(tab);
        return tab.map((coord) => {
          return [coord[0] - minX, coord[1] - minY];
        });
      } else {
        return tab;
      }
    });
    setPiecesSpot(newPiecesSpot);
    const newPiecesPos = piecesPos.map((tab, index) => {
      if (index === i) {
        return [
          tab[0] - (newDecX - decX) * getCaseSizeX(),
          tab[1] - (newDecY - decY) * getCaseSizeY()
        ];
      } else {
        return tab;
      }
    });
    const newPiecesPosB = newPiecesPos.map((pos, idPiece) => {
      if (i === idPiece) {
        return [
          Math.round((pos[0] - getStartX()) / getCaseSizeX()) * getCaseSizeX() +
            getStartX(),
          Math.round((pos[1] - getStartY()) / getCaseSizeY()) * getCaseSizeY() +
            getStartY()
        ];
      } else {
        return pos;
      }
    });
    setPiecesPos(newPiecesPosB);
  };

  const dragStart = (e, ui, i) => {
    const shadowsTab = shadows.map((value, index) => {
      if (index === i) return "";
      //"drop-shadow(0px 0px 10px #222)";
      else return value;
    });
    setShadows(shadowsTab);
    setCurrentDraging(i);
    setOldCoordX(ui.x);
    setOldCoordY(ui.y);
    setPieceMoved(false);
    const thisZIndex = zIndex[i];
    const newZIndex = zIndex.map((z) => {
      if (z > thisZIndex) return z - 10;
      if (z === thisZIndex) return 80;
      return z;
    });
    setZIndex(newZIndex);
    setBoardReady(0);
    setUpdatePositionsPieces(!updatePositionsPieces);
  };

  const drag = (e, ui, i, decX, decY) => {
    const newPiecesPos = piecesPos.map((pos, idPiece) => {
      if (i === idPiece) {
        return [ui.x - decX, ui.y - decY];
      } else {
        return pos;
      }
    });
    setPiecesPos(newPiecesPos);
    if (Math.abs(oldCoordX - ui.x) > 20 || Math.abs(oldCoordY - ui.y) > 20)
      setPieceMoved(true);
    // On actualise les spots de couleur
    // On commence par le survol
    const grid = boardColor.map((tab) => {
      return tab.map((value) => {
        if (value === 1) return 0;
        else return value;
      });
    });
    const coordX = Math.round((ui.x - decX - getStartX()) / getCaseSizeX());
    const coordY = Math.round((ui.y - decY - getStartY()) / getCaseSizeY());
    piecesSpot[i].map((coord) => {
      const thisCoordX = coordX + coord[0];
      const thisCoordY = coordY + coord[1];
      if (
        thisCoordX >= 0 &&
        thisCoordX < 5 &&
        thisCoordY >= 0 &&
        thisCoordY < 5
      ) {
        grid[thisCoordY][thisCoordX] = 1;
      }
      return 0;
    });
    setBoardColor(grid);
    setUpdatePositionsPieces(!updatePositionsPieces);
  };

  const getColor = (param) => {
    switch (param) {
      case 0:
        return "#ffffff";
      case 1:
        return "#777777";
      case 2:
        return "#ff0000";
      case 3:
        return "#00ff00";
      default:
        return "#000000";
    }
  };

  const getOpacity = (param) => {
    switch (param) {
      case 0:
        return 0;
      default:
        return opacityGrid;
    }
  };

  const getSentence = (dataAff) => {
    let tabPiece = [
      '<span className="pieceFont"><font size="+3">i</font></span>',
      '<span className="pieceFont"><font size="+3">l</font></span>',
      '<span className="pieceFont"><font size="+3">q</font></span>',
      '<span className="pieceFont"><font size="+3">t</font></span>',
      '<span className="pieceFont"><font size="+3">v</font></span>',
      '<span className="pieceFont"><font size="+3">s</font></span>'
    ];
    let phrase =
      /*dataAff[0] + " : " + */ traduction[props.language]["AFF" + dataAff[1]];
    phrase = phrase.replace("XXX", tabPiece[dataAff[2]]);
    phrase = phrase.replace("YYY", tabPiece[dataAff[3]]);
    phrase = phrase.replace(
      "ZZZ",
      '<span className="pieceFont"><font size="+3">' +
        dataAff[2] +
        "</font></span>"
    );
    return Parser().parse(phrase);
  };

  return (
    <div className="App">
      <div
        style={{
          position: "relative",
          height: dimensions.height
        }}
      >
        <img
          ref={divRef}
          src={Board}
          id="Board"
          alt="Board"
          draggable="false"
          style={{
            position: "absolute",
            left: "0px",
            width: "100%",
            maxWidth: "600px"
          }}
          onLoad={handleImageLoad}
        />
        {boardColor.map((tab, y) =>
          tab.map((color, x) => (
            <div
              key={x + ";" + y}
              style={{
                backgroundColor: getColor(color),
                opacity: getOpacity(color),
                position: "absolute",
                width: getCaseSizeX() + "px",
                height: getCaseSizeX() + "px",
                left: getStartX() + x * getCaseSizeX() + "px",
                top: getStartY() + y * getCaseSizeY() + "px",
                zIndex: 70
              }}
            ></div>
          ))
        )}
        {pieces.map((piece, i) => (
          <div key={i}>
            <animated.img
              src={pieces[i]}
              id={i}
              alt={i}
              draggable="false"
              width={piecesSize[i][0] * getCaseSizeX()}
              height={piecesSize[i][1] * getCaseSizeY()}
              style={{
                webkitFilter: shadows[i],
                filter: shadows[i],
                position: "absolute",
                zIndex: zIndex[i],
                ...springs[i]
              }}
            />
            {piecesSpot[i].map((coord) => (
              <Draggable
                key={i + "-" + coord[0] + "-" + coord[1]}
                position={{
                  x: piecesPos[i][0] + coord[0] * getCaseSizeX(),
                  y: piecesPos[i][1] + coord[1] * getCaseSizeY()
                }}
                onStart={(e, ui) => dragStart(e, ui, i)}
                onDrag={(e, ui) =>
                  drag(
                    e,
                    ui,
                    i,
                    coord[0] * getCaseSizeX(),
                    coord[1] * getCaseSizeY()
                  )
                }
                onStop={() => {
                  dragEnd(i, coord[0], coord[1]);
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: getCaseSizeX() + "px",
                    height: getCaseSizeY() + "px",
                    zIndex: zIndex[i] + 100
                  }}
                ></div>
              </Draggable>
            ))}
          </div>
        ))}
      </div>
      <h2>
        {victory ? null : (
          <div>
            <br />
            <br />
          </div>
        )}
        {traduction[props.language]["PUZZLE"] + " #" + props.dailyGame}
      </h2>
      {victory ? (
        <h2> </h2>
      ) : (
        <div>
          <button
            onClick={() => {
              checkSol();
            }}
            style={{
              position: "relative",
              opacity: boardReady === 1 ? "1" : "0.5"
            }}
          >
            <img
              class="img_button"
              src={Button1}
              alt={traduction[props.language]["VERIFYSOLUTION"]}
            />
            <div class="t_centered">
              {" "}
              {traduction[props.language]["VERIFYSOLUTION"]}
            </div>
          </button>
          <br />
        </div>
      )}
      <br />
      <img
        src={
          "https://a.ludonaute.fr/puzzles/Start" +
          (props.dailyGame % dataDiscs["dailyPuzzles"].length) +
          ".png"
        }
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <h2>
        {dataDiscs["dailyPuzzles"][
          props.dailyGame % dataDiscs["dailyPuzzles"].length
        ][0].map((affirmation) => (
          <div>
            {affirmation[0] >= 2 ? (
              <div>
                <br />
                <ImgIndice
                  question={affirmation[0]}
                  zone={affirmation[1]}
                  r0={"S" + affirmation[2]}
                  r1={"S" + affirmation[3]}
                  r2={"S" + affirmation[4]}
                />
              </div>
            ) : (
              <div>
                <br />
                <ImgIndice
                  question={affirmation[0]}
                  zone={affirmation[1]}
                  r0={"N"}
                  r1={"N" + affirmation[2]}
                  r2={"N"}
                />
              </div>
            )}
          </div>
        ))}
        {dataDiscs["dailyPuzzles"][
          props.dailyGame % dataDiscs["dailyPuzzles"].length
        ][1].map((affirmation) => (
          <div>
            <div>
              <br />
              <img
                src={
                  "https://a.ludonaute.fr/affirmations/Aff" +
                  affirmation[0] +
                  ".png"
                }
                id="Aff1"
                alt="Aff1"
                draggable="false"
                style={{
                  width: "90%",
                  maxWidth: "600px"
                }}
              />
              <br />
              {getSentence(affirmation)}
              <br />
            </div>
          </div>
        ))}
      </h2>
      <button
        onClick={() => {
          props.setNewPage(idPage["P_PUZZLE"]);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["BUTTONBACK"]}
        />
        <div class="t_centered">{traduction[props.language]["BUTTONBACK"]}</div>
      </button>
    </div>
  );
}
