var idPage = {
  P_LOADING: 0,
  P_HOME: 1,
  P_INPUTDISC: 2,
  P_GAMEMODE: 3,
  P_CHOOSEEXPERT: 4,
  P_DISCPAGE: 5,
  P_INDICESPAGE: 6,
  P_INDICESPAGEEXPERT: 7,
  P_INPUTSOLUTION: 8,
  P_BACKTOHOME: 9,
  P_ASKSHOWSOLUTION: 10,
  P_SHOWSOLUTION: 11,
  P_PUZZLE: 12,
  P_INPUTPUZZLESOLUTION: 13
};

export default idPage;
