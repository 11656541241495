import "./styles.css";

export default function imgIndice(props) {
  return (
    <div
      style={{
        position: "relative"
      }}
    >
      <img
        src={"https://a.ludonaute.fr/affirmations/Box.png"}
        id="Aff1"
        alt="Aff1"
        draggable="false"
        style={{
          width: "90%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      />
      <img
        src={
          "https://a.ludonaute.fr/affirmations/Question" +
          props.question +
          ".png"
        }
        id="Aff1"
        alt="Aff1"
        draggable="false"
        style={{
          position: "absolute",
          top: "25%",
          left: "25%",
          transform: "translate(-50%, -50%)"
        }}
      />
      <img
        src={"https://a.ludonaute.fr/affirmations/Z" + props.zone + ".png"}
        id="Aff1"
        alt="Aff1"
        draggable="false"
        style={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      />
      <img
        src={"https://a.ludonaute.fr/affirmations/" + props.r0 + ".png"}
        id="Aff1"
        alt="Aff1"
        draggable="false"
        style={{
          position: "absolute",
          top: "70%",
          left: "25%",
          transform: "translate(-50%, -50%)"
        }}
      />
      <img
        src={"https://a.ludonaute.fr/affirmations/" + props.r1 + ".png"}
        id="Aff1"
        alt="Aff1"
        draggable="false"
        style={{
          position: "absolute",
          top: "70%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      />
      <img
        src={"https://a.ludonaute.fr/affirmations/" + props.r2 + ".png"}
        id="Aff1"
        alt="Aff1"
        draggable="false"
        style={{
          position: "absolute",
          top: "70%",
          left: "75%",
          transform: "translate(-50%, -50%)"
        }}
      />
    </div>
  );
}
