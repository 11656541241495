import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Board from "./images/Board.png";
import Button1 from "./images/Button1.png";
import Ind1 from "./images/SpotTC_1.png";
import Ind2 from "./images/SpotTC_2.png";
import Back from "./images/Back.png";

import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

const tabInd = [Ind1, Ind2];
export default function StartIndices(props) {
  const navigate = useNavigate();

  const caseSizeX = 202;
  const caseSizeY = 201;
  const [radioChecked, setRadioChecked] = useState(1);
  var divRef = null;

  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const handleImageLoad = (event) => {
    divRef = event.target;
    setDimensions({
      width: Math.round(event.target.clientWidth),
      height: Math.round(event.target.clientHeight)
    });
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (divRef) {
        setDimensions({
          width: Math.round(divRef.clientWidth),
          height: Math.round(divRef.clientHeight)
        });
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleClick = (id) => {
    setRadioChecked(id);
  };

  const getStartX = () => {
    return ((534 - caseSizeX) * dimensions.width) / 1668;
  };

  const getStartY = () => {
    return ((525 - caseSizeY) * dimensions.height) / 1668;
  };

  const getCaseSizeX = () => {
    return (caseSizeX * dimensions.width) / 1668;
  };

  const getCaseSizeY = () => {
    return (caseSizeY * dimensions.height) / 1668;
  };

  return (
    <div className="App">
      <div style={{ height: dimensions.height }}>
        <div style={{ position: "absolute" }}>
          <img
            ref={divRef}
            src={Board}
            id="Board"
            alt="Board"
            draggable="false"
            style={{
              width: "100%",
              maxWidth: "600px"
            }}
            onLoad={handleImageLoad}
          />
          {dataDiscs["startingAffirmations"][props.discUsed].map(
            (tab, index) => (
              <div key={index}>
                {radioChecked + 2 > index ? (
                  <img
                    src={tabInd[tab[0] - 1]}
                    style={{
                      position: "absolute",
                      width: getCaseSizeX() + "px",
                      height: getCaseSizeY() + "px",
                      left: getStartX() + getCaseSizeX() * tab[1] + "px",
                      top: getStartY() + getCaseSizeY() * tab[2] + "px"
                    }}
                  />
                ) : null}
              </div>
            )
          )}
        </div>
      </div>
      <button
        onClick={() => {
          navigate(-1);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginLeft: "0px",
          top: "0",
          left: "0"
        }}
      >
        <img src={Back} alt="Back" />
      </button>
      <h2>
        {traduction[props.language]["DISCNUMBER"]}{" "}
        {dataDiscs["number"][props.discUsed].value}
      </h2>
      <div class="container">
        <input
          type="radio"
          class="hidden"
          id="input1"
          name="inputs"
          checked={radioChecked === 1 ? "checked" : null}
        />
        <label
          class="entry"
          onClick={() => {
            handleClick(1);
          }}
        >
          <div class="circle"></div>
          <div class="entry-label">{traduction[props.language]["INDICE3"]}</div>
        </label>
        <input
          type="radio"
          class="hidden"
          id="input2"
          name="inputs"
          checked={radioChecked === 2 ? "checked" : null}
        />
        <label
          class="entry"
          onClick={() => {
            handleClick(2);
          }}
        >
          <div class="circle"></div>
          <div class="entry-label">{traduction[props.language]["INDICE4"]}</div>
        </label>
        <input
          type="radio"
          class="hidden"
          id="input3"
          name="inputs"
          checked={radioChecked === 3 ? "checked" : null}
        />
        <label
          class="entry"
          onClick={() => {
            handleClick(3);
          }}
        >
          <div class="circle"></div>
          <div class="entry-label">{traduction[props.language]["INDICE5"]}</div>
        </label>
        <div class="highlight"></div>
        <div class="overlay"></div>
      </div>
      <svg width="0" height="0" viewBox="0 0 40 140">
        <defs>
          <mask id="holes">
            <rect x="0" y="0" width="100" height="140" fill="white" />
            <circle r="12" cx="20" cy="20" fill="black" />
            <circle r="12" cx="20" cy="70" fill="black" />
            <circle r="12" cx="20" cy="120" fill="black" />
          </mask>
        </defs>
      </svg>
      <br />
      <button
        onClick={() => {
          navigate(-1);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["BUTTONBACK"]}
        />
        <div class="t_centered">{traduction[props.language]["BUTTONBACK"]}</div>
      </button>
      <br />
    </div>
  );
}
