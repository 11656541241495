import { Component, useState } from "react";
import "./styles.css";
import "./stylesLanguage.css";

import traduction from "./traduction";

export default function LanguageMenu(props) {
  const [isToggle, setIsToggle] = useState(false);

  const toggleLanguage = () => {
    setIsToggle(!isToggle);
  };

  const swapLanguage = (langCode) => {
    props.setNewLanguage(langCode);
    setIsToggle(false);
  };

  return (
    <div className="lang-switch">
      <a id="imageDropdown" onClick={() => toggleLanguage()}>
        {traduction[props.language]["LANG"]}
      </a>
      <div
        id="lang-list"
        className="lang-list"
        style={{ display: isToggle ? "block" : "none" }}
      >
        {props.language != 0 ? (
          <a onClick={() => swapLanguage(0)}>
            {traduction[0]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 1 ? (
          <a onClick={() => swapLanguage(1)}>
            {traduction[1]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 2 ? (
          <a onClick={() => swapLanguage(2)}>
            {traduction[2]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 3 ? (
          <a onClick={() => swapLanguage(3)}>
            {traduction[3]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 4 ? (
          <a onClick={() => swapLanguage(4)}>
            {traduction[4]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 5 ? (
          <a onClick={() => swapLanguage(5)}>
            {traduction[5]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 6 ? (
          <a onClick={() => swapLanguage(6)}>
            {traduction[6]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 7 ? (
          <a onClick={() => swapLanguage(7)}>
            {traduction[7]["LANG"]}
            <br />
          </a>
        ) : null}
        {props.language != 8 ? (
          <a onClick={() => swapLanguage(8)}>
            {traduction[8]["LANG"]}
            <br />
          </a>
        ) : null}
      </div>
    </div>
  );
}
