import { useNavigate } from "react-router-dom";

import Box from "./images/BoxLogo.png";
import Button2 from "./images/Button2.png";
import Home from "./images/Home.png";
import Back from "./images/Back.png";

import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function ChooseExpert(props) {
  const navigate = useNavigate();

  const setGame = (IDGame) => {
    navigate("?d=" + props.discUsed + "&m=" + IDGame);
    //    props.setNewExpertIDGame(IDGame);
    //    props.setNewPage(idPage["P_DISCPAGE"]);
  };

  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <button
        onClick={() => {
          navigate("");
          //          props.setNewPage(idPage["P_HOME"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginRight: "0px",
          top: "0",
          right: "0"
        }}
      >
        <img src={Home} alt="Home" />
      </button>
      <button
        onClick={() => {
          navigate(-1);
          //          props.setNewPage(idPage["P_GAMEMODE"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginLeft: "0px",
          top: "0",
          left: "0"
        }}
      >
        <img src={Back} alt="Back" />
      </button>
      <h2>
        {traduction[props.language]["DISCNUMBER"]}{" "}
        {dataDiscs["number"][props.discUsed].value}
      </h2>
      <h2>{traduction[props.language]["CHOOSEEXP"]}</h2>
      <button
        onClick={() => {
          setGame(1);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="1" />
        <div class="t_centered">1</div>
      </button>
      <button
        onClick={() => {
          setGame(2);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="2" />
        <div class="t_centered">2</div>
      </button>
      <button
        onClick={() => {
          setGame(3);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="3" />
        <div class="t_centered">3</div>
      </button>
      <br />
      <button
        onClick={() => {
          setGame(4);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="4" />
        <div class="t_centered">4</div>
      </button>
      <button
        onClick={() => {
          setGame(5);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="5" />
        <div class="t_centered">5</div>
      </button>
      <button
        onClick={() => {
          setGame(6);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="6" />
        <div class="t_centered">6</div>
      </button>
      <br />
      <button
        onClick={() => {
          setGame(7);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="7" />
        <div class="t_centered">7</div>
      </button>
      <button
        onClick={() => {
          setGame(8);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="8" />
        <div class="t_centered">8</div>
      </button>
      <button
        onClick={() => {
          setGame(9);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="9" />
        <div class="t_centered">9</div>
      </button>
      <br />
      <button
        onClick={() => {
          setGame(10);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="10" />
        <div class="t_centered">10</div>
      </button>
      <button
        onClick={() => {
          setGame(11);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="11" />
        <div class="t_centered">11</div>
      </button>
      <button
        onClick={() => {
          setGame(12);
        }}
        style={{ position: "relative", width: "30%" }}
      >
        <img class="img_button" src={Button2} alt="12" />
        <div class="t_centered">12</div>
      </button>
    </div>
  );
}
