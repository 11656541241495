import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import idPage from "./idPage";
import dataDiscs from "./dataDiscs";

import Background from "./images/Background.png";

import Init from "./Init";
import LanguageMenu from "./LanguageMenu";
import Home from "./Home";
import InputDisc from "./InputDisc";
import GameMode from "./GameMode";
import ChooseExpert from "./ChooseExpert";
import DiscPage from "./DiscPage";
import StartIndices from "./StartIndices";
import StartIndicesExpert from "./StartIndicesExpert";
import InputSolution from "./InputSolution";
import BackToHome from "./BackToHome";
import AskShowSolution from "./AskShowSolution";
import ShowSolution from "./ShowSolution";
import PuzzlePage from "./PuzzlePage";
import InputPuzzleSolution from "./InputPuzzleSolution";

import "./styles.css";

export default function Main() {
  const navigate = useNavigate();

  const detectLang = ["fr", "en", "ja", "es", "de", "zh", "cz", "ru", "nl"];
  const affectLang = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const queryParams = new URLSearchParams(window.location.search);

  const [language, setLanguage] = useState(0);
  const [thisPage, setThisPage] = useState(0);
  const [discUsed, setDiscUsed] = useState(0);
  const [gameMode, setGameMode] = useState(0);
  const [expertIDGame, setExpertIDGame] = useState(0);
  const [dailyGame, setDailyGame] = useState(-1);

  const location = useLocation();
  useEffect(() => {
    initPage();
  }, [location]);

  useEffect(() => {
    initFirstPage();
  }, []);

  const initFirstPage = () => {
    if (detectLang.some((item) => window.navigator.language === item))
      setLanguage(affectLang[detectLang.indexOf(window.navigator.language)]);
    else setLanguage(0);
    initPage();
  };

  const initPage = () => {
    if (queryParams.get("dp")) {
      var index = queryParams.get("dp") % dataDiscs["dailyPuzzles"].length;
      setDiscUsed(-1);
      setGameMode(-1);
      setDailyGame(queryParams.get("dp"));
      setThisPage(idPage["P_PUZZLE"]);
    } else {
      if (queryParams.get("dc")) {
        var index = queryParams.get("dc") % dataDiscs["dailyChallenges"].length;
        setDiscUsed(dataDiscs["dailyChallenges"][index][1]);
        setGameMode(1);
        setDailyGame(queryParams.get("dc"));
        if (queryParams.get("c") == 1) {
          setThisPage(idPage["P_INDICESPAGEEXPERT"]);
        } else setThisPage(idPage["P_DISCPAGE"]);
      } else {
        if (queryParams.get("d")) {
          setDailyGame(-1);
          var index = queryParams.get("d");
          if (index >= 0 && index <= 37) {
            setDiscUsed(index);
            if (queryParams.get("m")) {
              var mode = queryParams.get("m");
              if (mode == 0) {
                setGameMode(0);
                if (queryParams.get("c") == 1) {
                  setThisPage(idPage["P_INDICESPAGE"]);
                } else setThisPage(idPage["P_DISCPAGE"]);
              } else {
                setGameMode(1);
                setExpertIDGame(mode);
                if (queryParams.get("c") == 1) {
                  setThisPage(idPage["P_INDICESPAGEEXPERT"]);
                } else setThisPage(idPage["P_DISCPAGE"]);
              }
            } else {
              if (queryParams.get("e") == 1)
                setThisPage(idPage["P_CHOOSEEXPERT"]);
              else setThisPage(idPage["P_GAMEMODE"]);
            }
          } else {
            setThisPage(idPage["P_HOME"]);
          }
        } else {
          if (queryParams.get("h") == 1) {
            setThisPage(idPage["P_INPUTDISC"]);
          } else {
            setThisPage(idPage["P_HOME"]);
          }
        }
      }
    }
  };

  const setNewLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const setNewPage = (newPage) => {
    setThisPage(newPage);
  };

  const setNewDiscUsed = (newDiscUsed) => {
    setDiscUsed(newDiscUsed);
  };

  const setNewGameMode = (newGameMode) => {
    setGameMode(newGameMode);
  };

  const setNewExpertIDGame = (newExpertIDGame) => {
    setExpertIDGame(newExpertIDGame);
  };

  const launchDailyChallenge = () => {
    var one_day = 1000 * 60 * 60 * 24;
    var present_date = new Date();
    var first_day = new Date("08/31/2023");
    var result = Math.trunc(
      (present_date.getTime() - first_day.getTime()) / one_day
    );
    navigate("?dc=" + result);
  };

  const launchPuzzleChallenge = () => {
    var one_day = 1000 * 60 * 60 * 24;
    var present_date = new Date();
    var first_day = new Date("08/31/2023");
    var result = Math.trunc(
      (present_date.getTime() - first_day.getTime()) / one_day
    );
    navigate("?dp=" + result);
  };

  return (
    <div
      className="AppBack"
      style={{
        backgroundRepeat: "repeat",
        backgroundImage: `url(${Background})`,
      }}
    >
      <div className="App">
        {thisPage !== idPage["P_LOADING"] &&
        thisPage !== idPage["P_INPUTSOLUTION"] ? (
          <div className="header">
            <LanguageMenu language={language} setNewLanguage={setNewLanguage} />
          </div>
        ) : null}
        {thisPage === idPage["P_LOADING"] ? (
          <Init language={language} setNewPage={setNewPage} />
        ) : null}
        {thisPage === idPage["P_HOME"] ? (
          <Home
            language={language}
            setNewPage={setNewPage}
            launchDailyChallenge={launchDailyChallenge}
            launchPuzzleChallenge={launchPuzzleChallenge}
          />
        ) : null}
        {thisPage === idPage["P_INPUTDISC"] ? (
          <InputDisc
            language={language}
            setNewPage={setNewPage}
            setNewDiscUsed={setNewDiscUsed}
          />
        ) : null}
        {thisPage === idPage["P_GAMEMODE"] ? (
          <GameMode
            discUsed={discUsed}
            language={language}
            setNewPage={setNewPage}
            setNewGameMode={setNewGameMode}
          />
        ) : null}
        {thisPage === idPage["P_CHOOSEEXPERT"] ? (
          <ChooseExpert
            discUsed={discUsed}
            language={language}
            setNewPage={setNewPage}
            setNewExpertIDGame={setNewExpertIDGame}
          />
        ) : null}
        {thisPage === idPage["P_DISCPAGE"] ? (
          <DiscPage
            discUsed={discUsed}
            gameMode={gameMode}
            expertIDGame={expertIDGame}
            dailyGame={dailyGame}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
        {thisPage === idPage["P_INDICESPAGE"] ? (
          <StartIndices
            discUsed={discUsed}
            gameMode={gameMode}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
        {thisPage === idPage["P_INDICESPAGEEXPERT"] ? (
          <StartIndicesExpert
            discUsed={discUsed}
            gameMode={gameMode}
            expertIDGame={expertIDGame}
            dailyGame={dailyGame}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
        {thisPage === idPage["P_INPUTSOLUTION"] ? (
          <InputSolution
            discUsed={discUsed}
            gameMode={gameMode}
            expertIDGame={expertIDGame}
            dailyGame={dailyGame}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
        {thisPage === idPage["P_BACKTOHOME"] ? (
          <BackToHome language={language} setNewPage={setNewPage} />
        ) : null}
        {thisPage === idPage["P_ASKSHOWSOLUTION"] ? (
          <AskShowSolution language={language} setNewPage={setNewPage} />
        ) : null}
        {thisPage === idPage["P_SHOWSOLUTION"] ? (
          <ShowSolution
            discUsed={discUsed}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
        {thisPage === idPage["P_PUZZLE"] ? (
          <PuzzlePage
            discUsed={discUsed}
            gameMode={gameMode}
            expertIDGame={expertIDGame}
            dailyGame={dailyGame}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
        {thisPage === idPage["P_INPUTPUZZLESOLUTION"] ? (
          <InputPuzzleSolution
            discUsed={discUsed}
            gameMode={gameMode}
            expertIDGame={expertIDGame}
            dailyGame={dailyGame}
            language={language}
            setNewPage={setNewPage}
          />
        ) : null}
      </div>
    </div>
  );
}
