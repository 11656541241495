import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "./images/BoxLogo.png";
import Button1 from "./images/Button1.png";
import Home from "./images/Home.png";

import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function InputDisc(props) {
  const navigate = useNavigate();

  const [discString, setDiscString] = useState("");

  const handleChange = (event) => {
    setDiscString(event.target.value);
  };

  const searchDisc = () => {
    if (dataDiscs["number"].some((el) => el.value === discString)) {
      navigate(
        "?d=" + dataDiscs["number"].findIndex((el) => el.value === discString)
      );
      //      props.setNewDiscUsed(
      //        dataDiscs["number"].findIndex((el) => el.value === discString)
      //      );
      //      props.setNewPage(idPage["P_GAMEMODE"]);
    } else alert(traduction[props.language]["SEARCHDISC_INVALIDFORMAT"]);
  };

  const randomDisc = () => {
    const randomNumber = Math.floor(Math.random() * 28);
    navigate("?d=" + randomNumber);
    //    props.setNewDiscUsed(randomNumber);
    //    props.setNewPage(idPage["P_GAMEMODE"]);
  };

  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <button
        onClick={() => {
          navigate("");
          //          props.setNewPage(idPage["P_HOME"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginRight: "0px",
          top: "0",
          right: "0"
        }}
      >
        <img src={Home} alt="Home" />
      </button>
      <input
        className="form-control"
        type="text"
        placeholder="XXXX"
        onChange={handleChange}
      />
      <br />
      <button
        onClick={() => {
          searchDisc();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["SEARCHDISC"]}
        />
        <div class="t_centered">{traduction[props.language]["SEARCHDISC"]}</div>
      </button>
      <br />
      <button
        onClick={() => {
          randomDisc();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["RANDOMDISC"]}
        />
        <div class="t_centered">{traduction[props.language]["RANDOMDISC"]}</div>
      </button>
      <br />
    </div>
  );
}
