import { Parser } from "html-to-react";

import Button1 from "./images/Button1.png";
import Home from "./images/Home.png";

import idPage from "./idPage";
import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import ImgIndice from "./ImgIndice";

import "./styles.css";

export default function PuzzlePage(props) {
  const getSentence = (dataAff) => {
    let tabPiece = [
      '<span className="pieceFont"><font size="+3">i</font></span>',
      '<span className="pieceFont"><font size="+3">l</font></span>',
      '<span className="pieceFont"><font size="+3">q</font></span>',
      '<span className="pieceFont"><font size="+3">t</font></span>',
      '<span className="pieceFont"><font size="+3">v</font></span>',
      '<span className="pieceFont"><font size="+3">s</font></span>'
    ];
    let phrase =
      /*dataAff[0] + " : " + */ traduction[props.language]["AFF" + dataAff[1]];
    phrase = phrase.replace("XXX", tabPiece[dataAff[2]]);
    phrase = phrase.replace("YYY", tabPiece[dataAff[3]]);
    phrase = phrase.replace(
      "ZZZ",
      '<span className="pieceFont"><font size="+3">' +
        dataAff[2] +
        "</font></span>"
    );
    return Parser().parse(phrase);
  };

  const proposeSolution = () => {
    window.scrollTo(0, 0);
    props.setNewPage(idPage["P_INPUTPUZZLESOLUTION"]);
  };

  const showSolution = () => {
    props.setNewPage(idPage["P_ASKSHOWSOLUTION"]);
  };

  return (
    <div className="App">
      <img
        src={
          "https://a.ludonaute.fr/puzzles/Start" +
          (props.dailyGame % dataDiscs["dailyPuzzles"].length) +
          ".png"
        }
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <button
        onClick={() => {
          props.setNewPage(idPage["P_BACKTOHOME"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginRight: "0px",
          top: "0",
          right: "0"
        }}
      >
        <img src={Home} alt="Home" />
      </button>
      <h2>
        {traduction[props.language]["PUZZLE"] + " #" + props.dailyGame}
        <br />
        {dataDiscs["dailyPuzzles"][
          props.dailyGame % dataDiscs["dailyPuzzles"].length
        ][0].map((affirmation) => (
          <div>
            {affirmation[0] >= 2 ? (
              <div>
                <br />
                <ImgIndice
                  question={affirmation[0]}
                  zone={affirmation[1]}
                  r0={"S" + affirmation[2]}
                  r1={"S" + affirmation[3]}
                  r2={"S" + affirmation[4]}
                />
              </div>
            ) : (
              <div>
                <br />
                <ImgIndice
                  question={affirmation[0]}
                  zone={affirmation[1]}
                  r0={"N"}
                  r1={"N" + affirmation[2]}
                  r2={"N"}
                />
              </div>
            )}
          </div>
        ))}
        {dataDiscs["dailyPuzzles"][
          props.dailyGame % dataDiscs["dailyPuzzles"].length
        ][1].map((affirmation) => (
          <div>
            <div>
              <br />
              <img
                src={
                  "https://a.ludonaute.fr/affirmations/Aff" +
                  affirmation[0] +
                  ".png"
                }
                id="Aff1"
                alt="Aff1"
                draggable="false"
                style={{
                  width: "90%",
                  maxWidth: "600px"
                }}
              />
              <br />
              {getSentence(affirmation)}
              <br />
            </div>
          </div>
        ))}
      </h2>
      <button
        onClick={() => {
          proposeSolution();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["PROPOSESOLUTION"]}
        />
        <div class="t_centered">
          {" "}
          {traduction[props.language]["PROPOSESOLUTION"]}
        </div>
      </button>
      <br />
      <button
        onClick={() => {
          showSolution();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["SHOWSOLUTION"]}
        />
        <div class="t_centered">
          {traduction[props.language]["SHOWSOLUTION"]}
        </div>
      </button>
    </div>
  );
}
