import { useState } from "react";

import idPage from "./idPage";
import traduction from "./traduction";

import "./styles.css";

export default function Init(props) {
  return (
    <div className="App">
      <h2>{traduction[props.language]["LOADING"]}</h2>
    </div>
  );
}
