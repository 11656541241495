import Button1 from "./images/Button1.png";
import Back from "./images/Back.png";

import idPage from "./idPage";
import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function ShowSolution(props) {
  const queryParams = new URLSearchParams(window.location.search);

  return (
    <div className="App">
      {queryParams.get("dp") ? (
        <img
          src={
            "https://a.ludonaute.fr/puzzles/Sol" +
            (queryParams.get("dp") % dataDiscs["dailyPuzzles"].length) +
            ".png"
          }
          id="Box"
          alt="Box"
          draggable="false"
          style={{
            width: "100%",
            maxWidth: "600px"
          }}
        />
      ) : (
        <img
          src={
            "https://a.ludonaute.fr/solutions/" +
            dataDiscs["number"][props.discUsed].value +
            ".png"
          }
          id="Box"
          alt="Box"
          draggable="false"
          style={{
            width: "100%",
            maxWidth: "600px"
          }}
        />
      )}
      <button
        onClick={() => {
          if (queryParams.get("dp")) props.setNewPage(idPage["P_PUZZLE"]);
          else props.setNewPage(idPage["P_DISCPAGE"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginLeft: "0px",
          top: "0",
          left: "0"
        }}
      >
        <img src={Back} alt="Back" />
      </button>
      <button
        onClick={() => {
          if (queryParams.get("dp")) props.setNewPage(idPage["P_PUZZLE"]);
          else props.setNewPage(idPage["P_DISCPAGE"]);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["BUTTONBACK"]}
        />
        <div class="t_centered">{traduction[props.language]["BUTTONBACK"]}</div>
      </button>
    </div>
  );
}
