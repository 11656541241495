import { useNavigate } from "react-router-dom";

import Box from "./images/BoxDisc.png";
import Button1 from "./images/Button1.png";
import Home from "./images/Home.png";

import idPage from "./idPage";
import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function DiscPage(props) {
  const navigate = useNavigate();

  const showStartIndices = () => {
    if (props.gameMode === 0) {
      navigate("?d=" + props.discUsed + "&m=0&c=1");
      //      props.setNewPage(idPage["P_INDICESPAGE"]);
    } else {
      if (props.dailyGame === -1) {
        navigate("?d=" + props.discUsed + "&m=" + props.expertIDGame + "&c=1");
        //      props.setNewPage(idPage["P_INDICESPAGEEXPERT"]);
      } else {
        navigate("?dc=" + props.dailyGame + "&c=1");
      }
    }
  };

  const proposeSolution = () => {
    props.setNewPage(idPage["P_INPUTSOLUTION"]);
  };

  const showSolution = () => {
    props.setNewPage(idPage["P_ASKSHOWSOLUTION"]);
  };

  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px",
        }}
      />
      <button
        onClick={() => {
          props.setNewPage(idPage["P_BACKTOHOME"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginRight: "0px",
          top: "0",
          right: "0",
        }}
      >
        <img src={Home} alt="Home" />
      </button>
      <h2>
        {traduction[props.language]["DISCNUMBER"]}{" "}
        {dataDiscs["number"][props.discUsed].value}
        <br />
        {props.gameMode === 0
          ? traduction[props.language]["GAMEMODESTD"]
          : null}
        {props.gameMode !== 0 && props.dailyGame === -1
          ? traduction[props.language]["GAMEMODEEXP"] +
            " #" +
            props.expertIDGame
          : null}
        {props.gameMode !== 0 && props.dailyGame !== -1
          ? traduction[props.language]["DAILY"] + " #" + props.dailyGame
          : null}
      </h2>
      <button
        onClick={() => {
          showStartIndices();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["STARTINGINFO"]}
        />
        <div
          class="t_centered"
          style={{
            fontSize: props.language === 7 ? "18px" : "24px",
          }}
        >
          {traduction[props.language]["STARTINGINFO"]}
        </div>
      </button>
      <br />
      <button
        onClick={() => {
          proposeSolution();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["PROPOSESOLUTION"]}
        />
        <div class="t_centered">
          {" "}
          {traduction[props.language]["PROPOSESOLUTION"]}
        </div>
      </button>
      <br />
      <button
        onClick={() => {
          showSolution();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["SHOWSOLUTION"]}
        />
        <div class="t_centered">
          {traduction[props.language]["SHOWSOLUTION"]}
        </div>
      </button>
    </div>
  );
}
