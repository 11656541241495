import { useNavigate } from "react-router-dom";

import Box from "./images/BoxDisc.png";
import Button2 from "./images/Button2.png";
import Back from "./images/Back.png";

import idPage from "./idPage";
import traduction from "./traduction";

import "./styles.css";

export default function BackToHome(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <button
        onClick={() => {
          if (queryParams.get("dp")) props.setNewPage(idPage["P_PUZZLE"]);
          else props.setNewPage(idPage["P_DISCPAGE"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginLeft: "0px",
          top: "0",
          left: "0"
        }}
      >
        <img src={Back} alt="Back" />
      </button>
      <h2>{traduction[props.language]["QUITGAME"]}</h2>
      <button
        onClick={() => {
          navigate("");
          //          props.setNewPage(idPage["P_HOME"]);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button2}
          alt={traduction[props.language]["YES"]}
        />
        <div class="t_centered">{traduction[props.language]["YES"]}</div>
      </button>
      <button
        onClick={() => {
          if (queryParams.get("dp")) props.setNewPage(idPage["P_PUZZLE"]);
          else props.setNewPage(idPage["P_DISCPAGE"]);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button2}
          alt={traduction[props.language]["NO"]}
        />
        <div class="t_centered">{traduction[props.language]["NO"]}</div>
      </button>
      <br />
    </div>
  );
}
