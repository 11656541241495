import { Parser } from "html-to-react";
import { useNavigate } from "react-router-dom";

import Box from "./images/BoxDisc.png";
import Button1 from "./images/Button1.png";
import Back from "./images/Back.png";

import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function StartIndicesExpert(props) {
  const navigate = useNavigate();

  const getSentence = (dataAff) => {
    let tabPiece = [
      '<span className="pieceFont"><font size="+3">i</font></span>',
      '<span className="pieceFont"><font size="+3">l</font></span>',
      '<span className="pieceFont"><font size="+3">q</font></span>',
      '<span className="pieceFont"><font size="+3">t</font></span>',
      '<span className="pieceFont"><font size="+3">v</font></span>',
      '<span className="pieceFont"><font size="+3">s</font></span>'
    ];
    let phrase =
      /*dataAff[0] + " : " + */ traduction[props.language]["AFF" + dataAff[1]];
    phrase = phrase.replace("XXX", tabPiece[dataAff[2]]);
    phrase = phrase.replace("YYY", tabPiece[dataAff[3]]);
    phrase = phrase.replace(
      "ZZZ",
      '<span className="pieceFont"><font size="+3">' +
        dataAff[2] +
        "</font></span>"
    );
    return Parser().parse(phrase);
  };
  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <button
        onClick={() => {
          navigate(-1);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginLeft: "0px",
          top: "0",
          left: "0"
        }}
      >
        <img src={Back} alt="Back" />
      </button>
      <h2>
        {traduction[props.language]["DISCNUMBER"]}{" "}
        {dataDiscs["number"][props.discUsed].value}
        <br />
        {props.dailyGame === -1
          ? traduction[props.language]["GAMEMODEEXP"] +
            " #" +
            props.expertIDGame
          : null}
        {props.dailyGame !== -1
          ? traduction[props.language]["DAILY"] + " #" + props.dailyGame
          : null}
        <br />
        {props.dailyGame === -1
          ? dataDiscs["startingAffirmationsExpert"][props.discUsed][
              props.expertIDGame - 1
            ].map((affirmation) => (
              <div>
                {Array.isArray(affirmation) ? (
                  <div>
                    <br />
                    <img
                      src={
                        "https://a.ludonaute.fr/affirmations/Aff" +
                        affirmation[0] +
                        ".png"
                      }
                      id="Aff1"
                      alt="Aff1"
                      draggable="false"
                      style={{
                        width: "90%",
                        maxWidth: "600px"
                      }}
                    />
                    <br />
                    {getSentence(affirmation)}
                    <br />
                  </div>
                ) : null}
              </div>
            ))
          : dataDiscs["dailyChallenges"][
              props.dailyGame % dataDiscs["dailyChallenges"].length
            ][0].map((affirmation) => (
              <div>
                {Array.isArray(affirmation) ? (
                  <div>
                    <br />
                    <img
                      src={
                        "https://a.ludonaute.fr/affirmations/Aff" +
                        affirmation[0] +
                        ".png"
                      }
                      id="Aff1"
                      alt="Aff1"
                      draggable="false"
                      style={{
                        width: "90%",
                        maxWidth: "600px"
                      }}
                    />
                    <br />
                    {getSentence(affirmation)}
                    <br />
                  </div>
                ) : null}
              </div>
            ))}
      </h2>
      <button
        onClick={() => {
          navigate(-1);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["BUTTONBACK"]}
        />
        <div class="t_centered">{traduction[props.language]["BUTTONBACK"]}</div>
      </button>
    </div>
  );
}
