import { useNavigate } from "react-router-dom";

import Box from "./images/BoxLogo.png";
import Button1 from "./images/Button1.png";
import Home from "./images/Home.png";

import idPage from "./idPage";
import traduction from "./traduction";
import dataDiscs from "./dataDiscs";

import "./styles.css";

export default function GameMode(props) {
  const navigate = useNavigate();

  const standardMode = () => {
    navigate("?d=" + props.discUsed + "&m=0");
    //    props.setNewGameMode(0);
    //    props.setNewPage(idPage["P_DISCPAGE"]);
  };

  const expertMode = () => {
    navigate("?d=" + props.discUsed + "&e=1");
    //    props.setNewGameMode(1);
    //    props.setNewPage(idPage["P_CHOOSEEXPERT"]);
  };

  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px",
        }}
      />
      <button
        onClick={() => {
          navigate("");
          //          props.setNewPage(idPage["P_HOME"]);
        }}
        style={{
          position: "absolute",
          marginTop: "0px",
          marginRight: "0px",
          top: "0",
          right: "0",
        }}
      >
        <img src={Home} alt="Home" />
      </button>
      <h2>
        {traduction[props.language]["DISCNUMBER"]}{" "}
        {dataDiscs["number"][props.discUsed].value}
      </h2>
      <button
        onClick={() => {
          standardMode();
        }}
        style={{
          position: "relative",
        }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["GAMEMODESTD"]}
        />
        <div
          class="t_centered"
          style={{
            fontSize: props.language === 7 ? "20px" : "24px",
          }}
        >
          {traduction[props.language]["GAMEMODESTD"]}
        </div>
      </button>
      <br />
      <button
        onClick={() => {
          expertMode();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["GAMEMODEEXP"]}
        />
        <div
          class="t_centered"
          style={{
            fontSize: props.language === 7 ? "18px" : "24px",
          }}
        >
          {traduction[props.language]["GAMEMODEEXP"]}
        </div>
      </button>
    </div>
  );
}
