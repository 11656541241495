import { useNavigate } from "react-router-dom";

import Box from "./images/BoxLogo.png";
import Button1 from "./images/Button1.png";

import Logo1 from "./images/Logo1.png";
import Logo2 from "./images/Logo2.png";
import Logo3 from "./images/Logo3.png";
import Logo4 from "./images/Logo4.png";

import traduction from "./traduction";

import "./styles.css";

export default function Home(props) {
  const navigate = useNavigate();

  return (
    <div className="App">
      <img
        src={Box}
        id="Box"
        alt="Box"
        draggable="false"
        style={{
          width: "100%",
          maxWidth: "600px"
        }}
      />
      <button
        onClick={() => {
          navigate("?h=1");
          //          props.setNewPage(idPage["P_INPUTDISC"]);
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["LAUNCHGAME"]}
        />
        <div class="t_centered">{traduction[props.language]["LAUNCHGAME"]}</div>
      </button>
      <br />
      <button
        onClick={() => {
          props.launchDailyChallenge();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["DAILY"]}
        />
        <div class="t_centered"> {traduction[props.language]["DAILY"]}</div>
      </button>
      <br />
      <button
        onClick={() => {
          props.launchPuzzleChallenge();
        }}
        style={{ position: "relative" }}
      >
        <img
          class="img_button"
          src={Button1}
          alt={traduction[props.language]["PUZZLE"]}
        />
        <div class="t_centered"> {traduction[props.language]["PUZZLE"]}</div>
      </button>
      <br />
      <br />
      <a href="https://www.facebook.com/ludonaute/" target="_blank">
        <img src={Logo1} width="40" />
      </a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://twitter.com/ludonaute" target="_blank">
        <img src={Logo2} width="40" />
      </a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.youtube.com/ludonautes" target="_blank">
        <img src={Logo3} width="40" />
      </a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.instagram.com/ludonaute.games" target="_blank">
        <img src={Logo4} width="40" />
      </a>
      <br />
      Copyright © 2023 - Ludonaute
    </div>
  );
}
