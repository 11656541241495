var traduction = {
  0: {
    LANG: "Français",
    LANGCODE: "FR",
    LAUNCHGAME: "Lancer une partie",
    DAILY: "Défi du jour",
    PUZZLE: "Puzzle du jour",
    SEARCHDISC: "Chercher cette carte",
    SEARCHDISC_INVALIDFORMAT: "Numéro invalide",
    RANDOMDISC: "Carte au hasard",
    DISCNUMBER: "Carte numéro",
    GAMEMODESTD: "Mode Normal",
    GAMEMODEEXP: "Mode Expert",
    CHOOSEEXP: "Choisissez une partie experte",
    STARTINGINFO: "Voir les indices de départ",
    PROPOSESOLUTION: "Proposer une solution",
    SHOWSOLUTION: "Voir la solution",
    BUTTONBACK: "Retour",
    INDICE3: "3 Indices",
    INDICE4: "4 Indices",
    INDICE5: "5 Indices",
    VERIFYSOLUTION: "Vérifier",
    HOME: "Accueil",
    LOADING: "Chargement...",
    VICTORY: "Vous avez gagné !!! Vous êtes :",
    SOLOMODE: "Mode Solo",
    SOLOSCOREEXPERT: "Archéologue chevronnée",
    SOLOSCOREEASY: "Archéologue confirmée",
    QUITGAME: "Quitter la partie en cours ?",
    YES: "OUI",
    NO: "NON",
    ASKSHOWSOLUTION: "Voir la solution ?",
    AFF1: "XXX est orientée comme ceci.",
    AFF2: "XXX touche un bord.",
    AFF3: "XXX ne touche aucun bord.",
    AFF4: "XXX touche le bord de gauche ou de droite.",
    AFF5: "XXX ne touche ni le bord de gauche ni le bord de droite.",
    AFF6: "XXX touche le bord du haut ou du bas.",
    AFF7: "XXX ne touche ni le bord du haut ni le bord du bas.",
    AFF8: "XXX touche un coin.",
    AFF9: "XXX et YYY se touchent par au moins un côté.",
    AFF10: "XXX et YYY ne se touchent pas par un côté.",
    AFF11: "XXX et YYY se touchent par un coin uniquement.",
    AFF12: "XXX et YYY ont au moins une ligne en commun.",
    AFF13: "XXX et YYY n'ont aucune ligne en commun.",
    AFF14: "XXX et YYY ont au moins une colonne en commun.",
    AFF15: "XXX et YYY n'ont aucune colonne en commun.",
    AFF16: "XXX a au moins une case plus à droite que YYY.",
    AFF17: "XXX a au moins une case plus à gauche que YYY.",
    AFF18: "XXX a au moins une case plus haute que YYY.",
    AFF19: "XXX a au moins une case plus basse que YYY.",
    AFF20: "XXX est totalement plus à droite que YYY.",
    AFF21: "XXX est totalement plus en bas que YYY.",
    AFF22: "Une zone vide est à la coordonnée ZZZ.",
  },
  1: {
    LANG: "English",
    LANGCODE: "EN",
    LAUNCHGAME: "Launch a game",
    DAILY: "Daily Challenge",
    PUZZLE: "Daily Puzzle",
    SEARCHDISC: "Search this card",
    SEARCHDISC_INVALIDFORMAT: "Invalid number",
    RANDOMDISC: "Random card",
    DISCNUMBER: "Card number",
    GAMEMODESTD: "Normal Mode",
    GAMEMODEEXP: "Expert Mode",
    CHOOSEEXP: "Choose an expert game",
    STARTINGINFO: "Show starting clues",
    PROPOSESOLUTION: "Test a solution",
    SHOWSOLUTION: "Show the solution",
    BUTTONBACK: "Back",
    INDICE3: "3 Clues",
    INDICE4: "4 Clues",
    INDICE5: "5 Clues",
    VERIFYSOLUTION: "Check",
    HOME: "Home",
    LOADING: "Loading...",
    VICTORY: "You win!!!",
    SOLOMODE: "Solo Mode",
    SOLOSCOREEXPERT: "Master archeo",
    SOLOSCOREEASY: "Seasoned archeo",
    QUITGAME: "Quit the current game?",
    YES: "YES",
    NO: "NO",
    ASKSHOWSOLUTION: "See the solution?",
    AFF1: "The orientation of XXX is like this.",
    AFF2: "XXX is touching a side.",
    AFF3: "XXX is not touching a side.",
    AFF4: "XXX is touching the left or the right side.",
    AFF5: "XXX is not touching the left or the right side.",
    AFF6: "XXX is touching the top side or the bottom side.",
    AFF7: "XXX is not touching the top and the bottom side.",
    AFF8: "XXX is touching a corner.",
    AFF9: "XXX and YYY are touching each other by at least an edge.",
    AFF10: "XXX and YYY are not touching each other by an edge.",
    AFF11: "XXX and YYY are touching by a corner only.",
    AFF12: "XXX and YYY have at least one row in common.",
    AFF13: "XXX and YYY don't have a row in common.",
    AFF14: "XXX and YYY have at least a column in common.",
    AFF15: "XXX and YYY don't have a column in common.",
    AFF16: "XXX have at least one part to the right of YYY.",
    AFF17: "XXX have at least one part to the left of YYY.",
    AFF18: "XXX have at least one part to the top of YYY.",
    AFF19: "XXX have at least one part to the bottom of YYY.",
    AFF20: "XXX is entirely to the right of YYY.",
    AFF21: "XXX is entirely to the bottom of YYY.",
    AFF22: "An empty zone is at ZZZ.",
  },
  2: {
    LANG: "Japanese",
    LANGCODE: "日本語",
    LAUNCHGAME: "ゲームを開始する",
    DAILY: "デイリーチャレンジ",
    PUZZLE: "デイリーパズル",
    SEARCHDISC: "このカードを探す",
    SEARCHDISC_INVALIDFORMAT: "無効な番号",
    RANDOMDISC: "ランダムなカード",
    DISCNUMBER: "カード番号",
    GAMEMODESTD: "通常モード",
    GAMEMODEEXP: "エキスパートモード",
    CHOOSEEXP: "エキスパートゲームを選択",
    STARTINGINFO: "開始時の手がかりを表示する",
    PROPOSESOLUTION: "答え合わせをする",
    SHOWSOLUTION: "答えを表示する",
    BUTTONBACK: "戻る",
    INDICE3: "手がかり3つ",
    INDICE4: "手がかり4つ",
    INDICE5: "手がかり5つ",
    VERIFYSOLUTION: "確認",
    HOME: "ホーム",
    LOADING: "ロード中",
    VICTORY: "あなたの勝利です！",
    SOLOMODE: "ソロモード",
    SOLOSCOREEXPERT: "偉大な考古学者",
    SOLOSCOREEASY: "熟練考古学者",
    QUITGAME: "現在のゲームを終了しますか？",
    YES: "はい",
    NO: "いいえ",
    ASKSHOWSOLUTION: "答えを見ますか？",
    AFF1: "XXXの向きはこうです。",
    AFF2: "XXXは壁面に接しています。",
    AFF3: "XXXは壁面には接していません。",
    AFF4: "XXXは左右の壁面に接しています。",
    AFF5: "XXXは左右の壁面には接していません。",
    AFF6: "XXXは上下の壁面に接しています。",
    AFF7: "XXXは上下の壁面には接していません。",
    AFF8: "XXXは角に接しています。",
    AFF9: "XXXとYYYは少なくとも1辺で接しています。",
    AFF10: "XXXとYYYは辺では接していません。",
    AFF11: "XXXとYYYは角のみで接しています。",
    AFF12: "XXXとYYYは少なくとも1つの行を共有しています。",
    AFF13: "XXXとYYYは行を共有していません。",
    AFF14: "XXXとYYYは少なくとも1つの列を共有しています。",
    AFF15: "XXXとYYYは列を共有していません。",
    AFF16: "XXXのうち少なくとも1マスはYYYより右にあります。",
    AFF17: "XXXのうち少なくとも1マスはYYYより左にあります。",
    AFF18: "XXXのうち少なくとも1マスはYYYより上にあります。",
    AFF19: "XXXのうち少なくとも1マスはYYYより下にあります。",
    AFF20: "XXXは完全にYYYの右にあります。",
    AFF21: "XXXは完全にYYYの下にあります。",
    AFF22: "ZZZは空のマスです。",
  },
  3: {
    LANG: "Español",
    LANGCODE: "ES",
    LAUNCHGAME: "Empezar una partida",
    DAILY: "Reto diario",
    PUZZLE: "Puzle diario",
    SEARCHDISC: "Buscar esta carta",
    SEARCHDISC_INVALIDFORMAT: "Número inválido",
    RANDOMDISC: "Carta al azar",
    DISCNUMBER: "Número de carta",
    GAMEMODESTD: "Modo normal",
    GAMEMODEEXP: "Modo experto",
    CHOOSEEXP: "Escoge partida experta",
    STARTINGINFO: "Mostrar Pistas iniciales",
    PROPOSESOLUTION: "Probar una solución",
    SHOWSOLUTION: "Mostrar la solución",
    BUTTONBACK: "Volver",
    INDICE3: "3 Pistas",
    INDICE4: "4 Pistas",
    INDICE5: "5 Pistas",
    VERIFYSOLUTION: "Comprobar",
    HOME: "Inicio",
    LOADING: "Cargando...",
    VICTORY: "¡Has ganado!",
    SOLOMODE: "Modo solitario",
    SOLOSCOREEXPERT: "Genio de la arqueología",
    SOLOSCOREEASY: "Especialista de la arqueología",
    QUITGAME: "¿Abandonar la partida en curso?",
    YES: "SÍ",
    NO: "NO",
    ASKSHOWSOLUTION: "¿Ver la solución?",
    AFF1: "La orientación de XXX es así.",
    AFF2: "XXX toca un lado.",
    AFF3: "XXX no toca un lado.",
    AFF4: "XXX toca el lado izquierdo o derecho.",
    AFF5: "XXX no toca el lazo izquierdo o derecho.",
    AFF6: "XXX toca el lado superior o inferior.",
    AFF7: "XXX no toca el lado superior o inferior.",
    AFF8: "XXX toca una esquina.",
    AFF9: "XXX y YYY se tocan por al menos 1 lado.",
    AFF10: "XXX y YYY no se tocan por ningún lado.",
    AFF11: "XXX y YYY se tocan solo por 1 esquina.",
    AFF12: "XXX y YYY tienen al menos 1 fila en común.",
    AFF13: "XXX y YYY no tienen ninguna fila en común.",
    AFF14: "XXX y YYY tienen al menos 1 columna en común.",
    AFF15: "XXX y YYY no tienen ninguna columna en común.",
    AFF16: "XXX tiene al menos 1 parte a la derecha de YYY.",
    AFF17: "XXX tiene al menos 1 parte a la izquierda de YYY.",
    AFF18: "XXX tiene al menos 1 parte por arriba de YYY.",
    AFF19: "XXX tiene al menos 1 parte por debajo de YYY.",
    AFF20: "XXX está completamente a la derecha de YYY.",
    AFF21: "XXX está completamente a la izquierda de YYY.",
    AFF22: "Hay una zona vacía en ZZZ.",
  },
  4: {
    LANG: "Deutsch",
    LANGCODE: "DE",
    LAUNCHGAME: "Eine Partie beginnen",
    DAILY: "Tägliche Herausforderung",
    PUZZLE: "",
    SEARCHDISC: "Karte suchen",
    SEARCHDISC_INVALIDFORMAT: "Ungültige Nummer",
    RANDOMDISC: "Zufallskarte",
    DISCNUMBER: "Kartennummer",
    GAMEMODESTD: "Standard",
    GAMEMODEEXP: "Experte",
    CHOOSEEXP: "Wähle eine Experten-Partie",
    STARTINGINFO: "Starthinweise erhalten",
    PROPOSESOLUTION: "Lösung prüfen",
    SHOWSOLUTION: "Lösung anzeigen",
    BUTTONBACK: "Zurück",
    INDICE3: "3 Hinweise",
    INDICE4: "4 Hinweise",
    INDICE5: "5 Hinweise",
    VERIFYSOLUTION: "Prüfen",
    HOME: "Startseite",
    LOADING: "Lädt …",
    VICTORY: "Du gewinnst!!!",
    SOLOMODE: "Solospiel",
    SOLOSCOREEXPERT: "Meisterarchäologe",
    SOLOSCOREEASY: "Erfahrener Archäologe",
    QUITGAME: "Aktuelle Partie beenden?",
    YES: "JA",
    NO: "NEIN",
    ASKSHOWSOLUTION: "Lösung wirklich anzeigen?",
    AFF1: "Dies ist die Orientierung von XXX.",
    AFF2: "XXX liegt am Rand.",
    AFF3: "XXX liegt nicht am Rand.",
    AFF4: "XXX berührt den linken oder rechten Rand.",
    AFF5: "XXX berührt weder den linken noch den rechten Rand.",
    AFF6: "XXX berührt den oberen oder den unteren Rand.",
    AFF7: "XXX berührt weder den oberen noch den unteren Rand.",
    AFF8: "XXX liegt in einer Ecke.",
    AFF9: "XXX und YYY berühren sich an mind. 1 Kante.",
    AFF10: "XXX und YYY berühren sich nicht an einer Kante.",
    AFF11: "XXX und YYY berühren sich nur an 1 Ecke.",
    AFF12: "XXX und YYY teilen sich mind. 1 Zeile.",
    AFF13: "XXX und YYY teilen sich keine Zeile.",
    AFF14: "XXX und YYY teilen sich mind. 1 Spalte.",
    AFF15: "XXX und YYY teilen sich keine Spalte.",
    AFF16: "Mind. 1 Kästchen von XXX liegt rechts von YYY.",
    AFF17: "Mind. 1 Kästchen von XXX liegt links von YYY.",
    AFF18: "Mind. 1 Kästchen von XXX liegt über YYY.",
    AFF19: "Mind. 1 Kästchen von XXX liegt unter YYY.",
    AFF20: "XXX befindet sich komplett rechts von YYY.",
    AFF21: "XXX befindet sich komplett unter YYY.",
    AFF22: "ZZZ ist ein leeres Gebiet.",
  },
  5: {
    LANG: "简体中文",
    LANGCODE: "SCN",
    LAUNCHGAME: "开始游戏",
    DAILY: "每日挑战",
    PUZZLE: "每日谜题",
    SEARCHDISC: "卡牌搜索",
    SEARCHDISC_INVALIDFORMAT: "无效编号",
    RANDOMDISC: "随机卡牌",
    DISCNUMBER: "卡牌编号",
    GAMEMODESTD: "普通模式",
    GAMEMODEEXP: "专家模式",
    CHOOSEEXP: "选择",
    STARTINGINFO: "起始线索",
    PROPOSESOLUTION: "尝试解谜",
    SHOWSOLUTION: "查看谜底",
    BUTTONBACK: "返回",
    INDICE3: "3条线索",
    INDICE4: "4条线索",
    INDICE5: "5条线索",
    VERIFYSOLUTION: "检查",
    HOME: "首页",
    LOADING: "加载中",
    VICTORY: "拼对了！",
    SOLOMODE: "单人模式",
    SOLOSCOREEXPERT: "老练考古家",
    SOLOSCOREEASY: "大师考古家",
    QUITGAME: "退出本次游戏？",
    YES: "是",
    NO: "否",
    ASKSHOWSOLUTION: "揭晓谜底？",
    AFF1: "XXX的朝向如上。",
    AFF2: "XXX搭在一边。",
    AFF3: "XXX不搭边。",
    AFF4: "XXX搭在左侧或右侧。",
    AFF5: "XXX不搭在左侧或右侧。",
    AFF6: "XXX搭在上部或底部。",
    AFF7: "XXX不搭在上部或底部。",
    AFF8: "XXX搭在拐角。",
    AFF9: "XXX和YYY至少有一边相接。",
    AFF10: "XXX和YYY不相接。",
    AFF11: "XXX和YYY仅一角相接。",
    AFF12: "XXX和YYY至少有一行相同。",
    AFF13: "XXX和YYY不在同一行上。",
    AFF14: "XXX和YYY至少有一列相同。",
    AFF15: "XXX和YYY不在同一列上。",
    AFF16: "部分XXX在YYY的右侧。",
    AFF17: "部分XXX在YYY的左侧。",
    AFF18: "部分XXX在YYY的上部。",
    AFF19: "部分XXX在YYY的底部。",
    AFF20: "XXX在YYY的右侧。",
    AFF21: "XXX在YYY的底部。",
    AFF22: "ZZZ有空格。",
  },
  6: {
    LANG: "Česky",
    LANGCODE: "CZ",
    LAUNCHGAME: "Spustit hru",
    DAILY: "Denní výzva",
    PUZZLE: "Denní kvíz",
    SEARCHDISC: "Vyhledej tuto kartu",
    SEARCHDISC_INVALIDFORMAT: "Chybné číslo",
    RANDOMDISC: "Náhodnou kartu",
    DISCNUMBER: "Číslo karty",
    GAMEMODESTD: "Běžný mód",
    GAMEMODEEXP: "Expertní mód",
    CHOOSEEXP: "Zvol expertní hru",
    STARTINGINFO: "Ukaž počáteční nápovědy",
    PROPOSESOLUTION: "Zkouška řešení",
    SHOWSOLUTION: "Ukaž řešení",
    BUTTONBACK: "Zpět",
    INDICE3: "3 nápovědy",
    INDICE4: "4 nápovědy",
    INDICE5: "5 nápověd",
    VERIFYSOLUTION: "Porovnat",
    HOME: "Domů",
    LOADING: "Načítám....",
    VICTORY: "Vyhrál jsi!!!",
    SOLOMODE: "Sólo mód",
    SOLOSCOREEXPERT: "Mistr archeolog",
    SOLOSCOREEASY: "Zkušený archeolog",
    QUITGAME: "Odejít ze hry?",
    YES: "ANO",
    NO: "NE",
    ASKSHOWSOLUTION: "Zobrazit řešení?",
    AFF1: "Natočení budovy XXX je takhle.",
    AFF2: "XXX se dotýká okraje.",
    AFF3: "XXX se nedotýká okraje.",
    AFF4: "XXX se dotýká pravé nebo levé strany.",
    AFF5: "XXX se nedotýká pravé nebo levé strany.",
    AFF6: "XXX se dotýká horní nebo dolní strany.",
    AFF7: "XXX se nedotýká horní nebo dolní strany.",
    AFF8: "XXX se dotýká rohu.",
    AFF9: "XXX a YYY se vzájemně dotýkají alespoň jednou stranou.",
    AFF10: "XXX a YYY se vzájemně nedotýkají stranou .",
    AFF11: "XXX a YYY se dotýkají pouze rohem.",
    AFF12: "XXX a YYY mají alespoň jednu část ve stejném řádku.",
    AFF13: "XXX a YYY se nenacházejí ve stejném řádku.",
    AFF14: "XXX a YYY mají alespoň jednu část ve stejném sloupci.",
    AFF15: "XXX a YYY se nenacházejí ve stejném sloupci.",
    AFF16: "XXX má vpravo alespoň jednu část YYY.",
    AFF17: "XXX má vlevo alespoň jednu část YYY.",
    AFF18: "XXX má na vrcholu alespoň jednu část YYY.",
    AFF19: "XXX ma na spodu alespoň jednu část YYY.",
    AFF20: "XXX leží kompletně napravo od YYY.",
    AFF21: "XXX je zcela pod YYY.",
    AFF22: "Prázdná zóna je na ZZZ.",
  },
  7: {
    LANG: "Русский",
    LANGCODE: "РУ",
    LAUNCHGAME: "Начать игру",
    DAILY: "Испытание дня",
    PUZZLE: "Экспедиция дня",
    SEARCHDISC: "Найдите этот диск",
    SEARCHDISC_INVALIDFORMAT: "Неправильный номер",
    RANDOMDISC: "Случайный диск",
    DISCNUMBER: "Номер диска",
    GAMEMODESTD: "Обычный уровень сложности",
    GAMEMODEEXP: "Экспертный уровень сложности",
    CHOOSEEXP: "Выберите экспертную игру",
    STARTINGINFO: "Показать стартовые подсказки",
    PROPOSESOLUTION: "Проверить догадку",
    SHOWSOLUTION: "Показать решение",
    BUTTONBACK: "Назад",
    INDICE3: "3 подсказки",
    INDICE4: "4 подсказки",
    INDICE5: "5 подсказок",
    VERIFYSOLUTION: "Проверить",
    HOME: "Домой",
    LOADING: "Загрузка…",
    VICTORY: "Вы победили!",
    SOLOMODE: "Одиночная игра",
    SOLOSCOREEXPERT: "Магистр археологии",
    SOLOSCOREEASY: "Опытный археолог",
    QUITGAME: "Выйти из текущей игры?",
    YES: "ДА",
    NO: "НЕТ",
    ASKSHOWSOLUTION: "Показать решение?",
    AFF1: "Ориентация XXX вот такая",
    AFF2: "XXX касается границы карты",
    AFF3: "XXX не касается границы карты",
    AFF4: "XXX касается правой или левой границы карты",
    AFF5: "XXX не касается ни правой, ни левой границы карты",
    AFF6: "XXX касается верхней или нижней границы карты",
    AFF7: "XXX не касается ни верхней, ни нижней границы карты",
    AFF8: "XXX касается угла карты",
    AFF9: "XXX и YYY касаются друг друга минимум 1 стороной",
    AFF10: "XXX и YYY не касаются друг друга сторонами",
    AFF11: "XXX и YYY касаются друг друга только углом",
    AFF12: "у XXX и YYY есть минимум 1 общий ряд",
    AFF13: "у XXX и YYY нет общего ряда",
    AFF14: "у XXX и YYY есть минимум 1 общая колонка",
    AFF15: "у XXX и YYY нет общей колонки",
    AFF16: "Как минимум 1 клетка XXX правее YYY",
    AFF17: "Как минимум 1 клетка XXX левее YYY",
    AFF18: "Как минимум 1 клетка XXX выше YYY",
    AFF19: "Как минимум 1 клетка XXX ниже YYY",
    AFF20: "XXX целиком правее YYY",
    AFF21: "XXX целиком ниже YYY",
    AFF22: "ZZZ — пустой участок",
  },
  8: {
    LANG: "Nederlands",
    LANGCODE: "NL",
    LAUNCHGAME: "Start een Spel",
    DAILY: "Dagelijkse Uitdaging",
    PUZZLE: "Dagelijkse Puzzel",
    SEARCHDISC: "Zoek deze Kaart",
    SEARCHDISC_INVALIDFORMAT: "Ongeldig Cijfer",
    RANDOMDISC: "Willekeurige Kaart",
    DISCNUMBER: "Cijfer van de Kaart",
    GAMEMODESTD: "Normaal Spel",
    GAMEMODEEXP: "Expertvariant",
    CHOOSEEXP: "Kies een Expertspel",
    STARTINGINFO: "Toon Aanwijzingen",
    PROPOSESOLUTION: "Controleer Oplossing",
    SHOWSOLUTION: "Toon Oplossing",
    BUTTONBACK: "Terug",
    INDICE3: "3 Aanwijzingen",
    INDICE4: "4 Aanwijzingen",
    INDICE5: "5 Aanwijzingen",
    VERIFYSOLUTION: "Controleren",
    HOME: "Startpagina",
    LOADING: "Laden...",
    VICTORY: "Je hebt gewonnen!!!",
    SOLOMODE: "Solovariant",
    SOLOSCOREEXPERT: "Legendarische Archeoloog",
    SOLOSCOREEASY: "Ervaren Archeoloog",
    QUITGAME: "Huidig Spel Beëindigen?",
    YES: "JA",
    NO: "NEE",
    ASKSHOWSOLUTION: "Oplossing Tonen?",
    AFF1: "Dit is de oriëntatie van XXX.",
    AFF2: "XXX raakt een rand.",
    AFF3: "XXX raakt geen rand.",
    AFF4: "XXX raakt de linker- of rechterrand.",
    AFF5: "XXX raakt noch de linker-, noch de rechterrand.",
    AFF6: "XXX raakt de boven- of onderrand.",
    AFF7: "XXX raakt noch de boven-, noch de onderrand.",
    AFF8: "XXX raakt een hoek.",
    AFF9: "XXX en YYY raken elkaar aan minstens 1 zijde.",
    AFF10: "De zijden van XXX en YYY raken elkaar niet.",
    AFF11: "XXX en YYY raken elkaar enkel aan een hoek.",
    AFF12: "XXX en YYY hebben minstens 1 rij gemeen.",
    AFF13: "XXX en YYY hebben geen rij gemeen.",
    AFF14: "XXX en YYY hebben minstens 1 kolom gemeen.",
    AFF15: "XXX en YYY hebben geen kolom gemeen.",
    AFF16: "XXX heeft minstens 1 vakje dat rechts van YYY ligt.",
    AFF17: "XXX heeft minstens 1 vakje dat links van YYY ligt.",
    AFF18: "XXX heeft minstens 1 vakje dat hoger dan YYY ligt.",
    AFF19: "XXX heeft minstens 1 vakje dat lager dan YYY ligt.",
    AFF20: "XXX ligt volledig rechts van YYY.",
    AFF21: "XXX ligt volledig onder YYY.",
    AFF22: "ZZZ is een lege zone."
    },
};

export default traduction;
